import styled from 'styled-components';
import {H6} from '../../common/styles';

export const TaskDescriptionPreview = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1rem;
  max-height: 2rem;
  color: ${props => props.theme.token.colorTextTertiary};
`;

export const StepLabel = styled(H6)`
  margin: 0;
`;

export const StepName = styled(H6)`
  font-family: 'LibreFranklin Regular';
  font-weight: 400;
  margin: 0;
`;

import useSWR from "swr";
import FormlessService from "../services/formless.service";
import { Form } from "../types/formless.types";

interface useFormsReturn {
  isLoadingOrgForms: boolean;
  isLoadingDefaultForms: boolean;
  orgForms: Form[];
  defaultForms: Form[];
  formsById: Map<string, Form>;
  mutateOrgForms: () => Promise<Form[] | undefined>;
  mutateDefaultForms: () => Promise<Form[] | undefined>;
  errorOrgForms?: Error;
  errorDefaultForms?: Error;
}

export default function useForms(activeOnly: boolean, filterLanguages: boolean): useFormsReturn {
  const activeParam = activeOnly === true ? "&active=true" : "";
  const filterLanguagesParam = filterLanguages === true ? '&filter_languages=true' : '&filter_languages=false';
  const {
    data: orgForms,
    mutate: mutateOrgForms,
    error: errorOrgForms,
  } = useSWR(
    process.env.REACT_APP_FORMS_ENDPOINT! + "?subset=org" + activeParam + filterLanguagesParam,
    FormlessService.getForms
  );
  const {
    data: defaultForms,
    mutate: mutateDefaultForms,
    error: errorDefaultForms,
  } = useSWR(
    process.env.REACT_APP_FORMS_ENDPOINT! + "?subset=default" + activeParam + filterLanguagesParam,
    FormlessService.getForms
  );
  const isLoadingOrgForms = !orgForms && !errorOrgForms;
  const isLoadingDefaultForms = !defaultForms && !errorDefaultForms;
  const formsById = new Map();
  [orgForms, defaultForms].forEach((forms) => {
    if (forms) {
      forms.forEach((form) => {
        formsById.set(form._id, form);
      });
    }
  });
  return {
    isLoadingOrgForms,
    isLoadingDefaultForms,
    orgForms: orgForms ?? [],
    defaultForms: defaultForms ?? [],
    formsById,
    mutateOrgForms,
    mutateDefaultForms,
    errorOrgForms,
    errorDefaultForms,
  };
}

import React, {ChangeEvent, useEffect, useState} from 'react';
import {PlainTextButton} from '../../common/styles';
import {AddFilterFormContainer, Header, SubHeader} from './styles';
import {QuestionFilter, FilterOperator} from '../../types/search.types';
import {Form, Question, Answer, QuestionType} from '../../types/formless.types';
import {Input, Select, Row} from 'antd';
import {CloseCircleOutline} from 'react-ionicons';

export const operatorsByQuestionType = {
  // First option is selected by default.
  // Some options are filtered out from front end to reduce
  // mental load of user, but are available in backend to be added
  // later in one or the other way.
  OPEN: [
    FilterOperator.TEXT_CONTAINS,
    FilterOperator.NOT_EMPTY,
    // FilterOperator.EQUALS,
    // FilterOperator.NOT_EQUALS,
    // FilterOperator.TEXT_NOT_CONTAINS,
    // FilterOperator.EMPTY,
  ],
  BOOL: [
    FilterOperator.EQUALS,
    FilterOperator.NOT_EQUALS,
    // FilterOperator.EMPTY,
    // FilterOperator.NOT_EMPTY,
  ],
  NUMBER: [
    FilterOperator.LESS_THAN_OR_EQUAL_TO,
    FilterOperator.GREATER_THAN_OR_EQUAL_TO,
    // FilterOperator.EQUALS,
    // FilterOperator.NOT_EQUALS,
    // FilterOperator.GREATER_THAN,
    // FilterOperator.LESS_THAN,
    // FilterOperator.EMPTY,
    // FilterOperator.NOT_EMPTY,
  ],
  INTEGER: [
    FilterOperator.LESS_THAN_OR_EQUAL_TO,
    FilterOperator.GREATER_THAN_OR_EQUAL_TO,
    // FilterOperator.EQUALS,
    // FilterOperator.NOT_EQUALS,
    // FilterOperator.GREATER_THAN,
    // FilterOperator.LESS_THAN,
    // FilterOperator.EMPTY,
    // FilterOperator.NOT_EMPTY,
  ],
  SELECT_SINGLE: [
    FilterOperator.EQUALS,
    FilterOperator.NOT_EQUALS,
    FilterOperator.ONE_OF,
    FilterOperator.EMPTY,
    // FilterOperator.NOT_EMPTY,
  ],
  SELECT_MULTIPLE: [
    FilterOperator.CONTAINS_ANY,
    FilterOperator.CONTAINS_ALL,
    FilterOperator.EMPTY,
    // FilterOperator.EQUALS,
    // FilterOperator.NOT_EQUALS,
    // FilterOperator.CONTAINS,
    // FilterOperator.NOT_CONTAINS,
    // FilterOperator.NOT_EMPTY,
  ],
  SCALE: [
    FilterOperator.LESS_THAN_OR_EQUAL_TO,
    FilterOperator.GREATER_THAN_OR_EQUAL_TO,
    // FilterOperator.EQUALS,
    // FilterOperator.NOT_EQUALS,
    // FilterOperator.GREATER_THAN,
    // FilterOperator.LESS_THAN,
    // FilterOperator.ONE_OF,
    // FilterOperator.EMPTY,
    // FilterOperator.NOT_EMPTY,
  ],
  DATE: [
    // not yet implemented.
    FilterOperator.LESS_THAN_OR_EQUAL_TO,
    FilterOperator.GREATER_THAN_OR_EQUAL_TO,
    // FilterOperator.EQUALS,
    // FilterOperator.NOT_EQUALS,
    // FilterOperator.GREATER_THAN,
    // FilterOperator.LESS_THAN,
    // FilterOperator.EMPTY,
    // FilterOperator.NOT_EMPTY,
  ],
  OBJECT: [FilterOperator.EQUALS],
};

export const operatorLabels = {
  [FilterOperator.EQUALS]: 'equals',
  [FilterOperator.NOT_EQUALS]: 'does not equal',
  [FilterOperator.CONTAINS]: 'contains',
  [FilterOperator.NOT_CONTAINS]: 'does not contain',
  [FilterOperator.CONTAINS_ANY]: 'contains any of',
  [FilterOperator.CONTAINS_ALL]: 'contains all of',
  [FilterOperator.ONE_OF]: 'equals one of',
  [FilterOperator.TEXT_CONTAINS]: 'contains',
  [FilterOperator.TEXT_NOT_CONTAINS]: 'does not contain',
  [FilterOperator.GREATER_THAN]: 'is greater than',
  [FilterOperator.LESS_THAN]: 'is less than',
  [FilterOperator.GREATER_THAN_OR_EQUAL_TO]: 'is greater than or equal to',
  [FilterOperator.LESS_THAN_OR_EQUAL_TO]: 'is less than or equal to',
  [FilterOperator.EMPTY]: 'is empty',
  [FilterOperator.NOT_EMPTY]: 'is not empty',
  [FilterOperator.DISTANCE_LTE]: 'is within',
};

type valueInputType =
  | 'text'
  | 'number'
  | 'date'
  | 'select'
  | 'multiple_select'
  | 'none';

const getValueInputType = (
  operator: FilterOperator,
  questionType: QuestionType,
): valueInputType => {
  if (
    operator === FilterOperator.EMPTY ||
    operator === FilterOperator.NOT_EMPTY
  ) {
    return 'none';
  }
  if (
    operator === FilterOperator.ONE_OF ||
    operator === FilterOperator.CONTAINS_ANY ||
    operator === FilterOperator.CONTAINS_ALL
  ) {
    return 'multiple_select';
  }
  if (
    questionType === QuestionType.NUMBER ||
    questionType === QuestionType.INTEGER ||
    questionType === QuestionType.SCALE
  ) {
    return 'number';
  }
  if (questionType === QuestionType.DATE) {
    return 'date';
  }
  if (
    questionType === QuestionType.BOOL &&
    (operator === FilterOperator.EQUALS ||
      operator === FilterOperator.NOT_EQUALS)
  ) {
    return 'select';
  }
  if (
    questionType === QuestionType.SELECT_SINGLE &&
    (operator === FilterOperator.EQUALS ||
      operator === FilterOperator.NOT_EQUALS)
  ) {
    return 'select';
  }
  if (
    questionType === QuestionType.SELECT_MULTIPLE &&
    (operator === FilterOperator.CONTAINS ||
      operator === FilterOperator.NOT_CONTAINS)
  ) {
    return 'select';
  }
  return 'text';
};

const getValueOptions = (
  question: Question,
): {label: string; value: string | boolean}[] => {
  if (
    question.type === QuestionType.SELECT_MULTIPLE ||
    question.type === QuestionType.SELECT_SINGLE
  ) {
    return (
      question.options?.map(option => ({label: option, value: option})) ?? []
    );
  }
  if (question.type === QuestionType.BOOL) {
    return [
      {label: 'True', value: true},
      {label: 'False', value: false},
    ];
  }
  return [];
};

interface FilterFormProps {
  form: Form;
  onSubmit: (filter: QuestionFilter) => void;
  onCancel: () => void;
  initialValues?: QuestionFilter;
  isDeletable?: boolean;
  onDelete?: () => void;
}

const getQuestionByName = (form: Form, name: string): Question | undefined => {
  let question: Question | undefined;
  form.topics.forEach(topic => {
    topic.questions.forEach(q => {
      if (q.name === name) {
        question = q;
      }
    });
  });
  return question;
};

const FilterForm = ({
  form,
  onSubmit,
  onCancel,
  initialValues,
  isDeletable,
  onDelete,
}: FilterFormProps) => {
  const [question, setQuestion] = useState<Question | undefined>(
    initialValues
      ? getQuestionByName(form, initialValues?.questionName)
      : undefined,
  );
  const [operator, setOperator] = useState<FilterOperator | null>(
    initialValues?.operator ?? null,
  );
  const [value, setValue] = useState<Answer | null>(
    initialValues?.value ?? null,
  );
  const [allQuestions, setAllQuestions] = useState<Question[]>([]);
  const [questionOptions, setQuestionOptions] = useState<
    {label: string; value: string}[]
  >([]);
  const [operatorOptions, setOperatorOptions] = useState<
    {label: string; value: FilterOperator}[]
  >([]);
  const [inputType, setInputType] = useState<valueInputType>('text');
  const [valueOptions, setValueOptions] = useState<
    {label: string; value: string | boolean}[]
  >([]);

  useEffect(() => {
    const questions: Question[] = [];
    form.topics.forEach(topic =>
      topic.questions.forEach(question => questions.push(question)),
    );
    setAllQuestions(questions);
  }, [form]);

  useEffect(() => {
    if (allQuestions.length > 0) {
      setQuestionOptions(
        allQuestions.map(question => ({
          label: question.header ?? question.prompt,
          value: question.name,
        })),
      );
    }
  }, [allQuestions]);

  useEffect(() => {
    if (question) {
      const options = operatorsByQuestionType[question.type].map(operator => ({
        label: operatorLabels[operator],
        value: operator,
      }));

      setOperatorOptions(options);

      // Check if there are options and preselect the first one
      if (options.length > 0) {
        setOperator(operatorsByQuestionType[question.type][0]);
      }
    }
  }, [question]);

  const handleQuestionChange = (questionName: string) => {
    setQuestion(allQuestions.find(q => q.name == questionName));
    setOperator(null);
    setValue(null);
  };

  const handleSubmit = () => {
    if (!submitDisabled) {
      onSubmit({
        questionName: question.name,
        operator: operator,
        value: value,
      });
      setQuestion(undefined);
    }
  };

  const handleOperatorChange = (operator: FilterOperator) => {
    setOperator(operator);
  };

  useEffect(() => {
    if (question && operator) {
      setInputType(getValueInputType(operator, question.type));
    }
  }, [operator, question]);

  useEffect(() => {
    if (question) {
      setValueOptions(getValueOptions(question));
    }
  }, [question]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (inputType === 'text') {
      setValue(event.target.value);
    } else if (inputType === 'number') {
      setValue(Number(event.target.value));
    } else if (inputType === 'date') {
      setValue(event.target.value);
    } else {
      console.log('handleInputChange called on non-input type');
    }
  };

  const handleSelectChange = (value: string | boolean) => {
    setValue(value);
  };

  const handleMultipleSelectChange = (value: string[]) => {
    setValue(value);
  };
  const submitDisabled =
    !question ||
    !operator ||
    (value === null &&
      operator !== FilterOperator.EMPTY &&
      operator !== FilterOperator.NOT_EMPTY);

  return (
    <AddFilterFormContainer>
      <Row justify='space-between' align='middle'>
        <Header>Configure filter</Header>
        {isDeletable && onDelete && <CloseCircleOutline onClick={onDelete} />}
      </Row>
      <SubHeader>Filter on</SubHeader>
      <Select
        placeholder='Select question'
        options={questionOptions}
        onChange={handleQuestionChange}
        value={question?.name}
      />
      {question && (
        <>
          <SubHeader>Comparison</SubHeader>
          <Select
            placeholder='Select operator'
            options={operatorOptions}
            onChange={handleOperatorChange}
            value={operator}
          />
        </>
      )}
      {question && operator && inputType != 'none' && (
        <>
          <SubHeader>Value</SubHeader>
          {inputType === 'text' ? (
            <Input
              placeholder='Enter value'
              onChange={handleInputChange}
              value={(value as string) ?? undefined}
            />
          ) : inputType === 'number' ? (
            <Input
              type='number'
              placeholder='Enter value'
              onChange={handleInputChange}
              value={(value as number) ?? undefined}
            />
          ) : inputType === 'date' ? (
            <Input
              type='date'
              placeholder='Enter value'
              onChange={handleInputChange}
              // value={value ?? undefined}
            />
          ) : inputType === 'select' ? (
            <Select
              placeholder='Select value'
              options={valueOptions}
              onChange={handleSelectChange}
              value={(value as string) ?? undefined}
            />
          ) : inputType === 'multiple_select' ? (
            <Select
              mode='multiple'
              allowClear
              placeholder='Select value(s)'
              defaultValue={[]}
              onChange={handleMultipleSelectChange}
              options={valueOptions}
              value={(value as string[]) ?? undefined}
            />
          ) : null}
        </>
      )}
      <Row justify='space-between' style={{marginTop: 8}}>
        <PlainTextButton danger onClick={onCancel}>
          Cancel
        </PlainTextButton>
        <PlainTextButton onClick={handleSubmit} disabled={submitDisabled}>
          Save filter
        </PlainTextButton>
      </Row>
    </AddFilterFormContainer>
  );
};

export default FilterForm;

import React from 'react';
import {Space} from 'antd';
import {TranscribedSegment} from '../../types/runs.types';
import {formatSecondsToTime} from '../../data/utils';
import {B} from '../../common/styles';
import {ChatBubbleMe, ChatBubbleThem, TimeIndication} from './styles';

const TranscriptionViewer = ({segments}: {segments: TranscribedSegment[]}) => {
  return (
    <Space direction='vertical'>
      {segments.map((segment, index) => {
        return segment.speaker === 'Interviewer' ? (
          <ChatBubbleMe key={index}>
            <TimeIndication>
              {formatSecondsToTime(segment.start)} -{' '}
              {formatSecondsToTime(segment.end)}
            </TimeIndication>
            <B>{segment.speaker}</B>
            {segment.text}
          </ChatBubbleMe>
        ) : (
          <ChatBubbleThem key={index}>
            <TimeIndication>
              {formatSecondsToTime(segment.start)} -{' '}
              {formatSecondsToTime(segment.end)}
            </TimeIndication>
            <B>{segment.speaker}</B>
            {segment.text}
          </ChatBubbleThem>
        );
      })}
    </Space>
  );
};

export default TranscriptionViewer;

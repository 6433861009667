import styled from 'styled-components';

export const LoadingWrapper = styled.div<{justifyContent?: string}>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'start'};
  align-items: center;
  padding: 10px;
`;

export const LoadingMessage = styled.p`
  margin-left: 10px;
  color: ${props => props.theme.token.colorPrimary};
`;

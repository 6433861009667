import {Drawer} from 'antd';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
  background-color: ${props => props.theme.token.colorBgContainer};
  .ant-drawer-body {
    padding: 0 24px 24px;
    background: ${props => props.theme.token.colorBgContainer};
  }
  .ant-drawer-title {
    font-family: 'LibreFranklin Bold';
  }
  .ant-drawer-header {
    background: ${props => props.theme.token.colorBgContainer};
  }
`;

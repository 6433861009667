import {Row} from 'antd';
import styled from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 6px;
  border: none;
  background-color: ${props => props.theme.token.colorInfo + '1A'};
  max-width: 100%;
  padding: 2px 10px;
  &:hover {
    background-color: ${props => props.theme.token.colorInfo + '33'};
    cursor: pointer;
  }
`;

export const StyledRow = styled(Row)`
  span {
    height: 20px;
  }
`;

export const StyledTitle = styled.b`
  font-size: 0.8rem;
  margin: 0;
  color: ${props => props.theme.token.colorInfo};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledSubtitle = styled.p`
  font-size: 0.7rem;
  margin: 0;
  color: ${props => props.theme.token.colorInfo};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

import styled from 'styled-components';
import {Row} from 'antd';

export const Background = styled('div')`
  background: ${props => props.theme.token.colorBgElevated};
  width: 100%;
  height: 100vh;
`;

export const StyledHeaderRow = styled(Row)`
  height: 79px;
  background: ${props => props.theme.token.colorBgContainer};
`;

import styled from 'styled-components';
import {Card} from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 3rem;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.token.colorBgLayout};
  overflow: auto;
`;

export const StyledCard = styled(Card)`
  min-width: 300px;
  max-width: 600px;
  width: 100%;
  background-color: ${({theme}) => theme.token.colorBgLayout};
  border-radius: ${({theme}) => theme.token.borderRadius};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
`;

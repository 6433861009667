import {useState} from 'react';

interface OnboardingStepProps {
  onFinish?: () => void;
}

const useOnboardingStep = ({onFinish}: OnboardingStepProps) => {
  const getOnboardingStep = () => {
    return parseInt(localStorage.getItem('onboardingStep') ?? '0');
  };

  const [onboardingStep, setOnboardingStep] = useState<number>(
    getOnboardingStep(),
  );

  const updateStep = (step: number) => {
    localStorage.setItem('onboardingStep', step.toString());
    setOnboardingStep(step);
    if (step > 7 && onFinish) {
      onFinish();
    }
  };

  const onboardingFinished = onboardingStep > 7;

  return {onboardingStep, setOnboardingStep: updateStep, onboardingFinished};
};

export default useOnboardingStep;

import API from './api';
import {User} from '../types/users.types';
import {Organization} from '../types/orgs.types';

async function getUserMe(url: string): Promise<User> {
  return await API.get(url).then(res => res.data);
}

async function getMyOrganization(url: string): Promise<Organization> {
  return await API.get(url).then(res => res.data);
}

async function patchUserMe(data: object): Promise<User | null> {
  return await API.patch(process.env.REACT_APP_USERS_ME_ENDPOINT!, data)
    .then(res => res.data)
    .catch(err => {
      console.log(err);
      return null;
    });
}

async function changePassword(password: string): Promise<User | null> {
  return await patchUserMe({password: password});
}

async function setIntegrations(integrations: object): Promise<User | null> {
  return await patchUserMe({integrations: integrations});
}

const UserService = {
  getUserMe,
  getMyOrganization,
  changePassword,
  setIntegrations,
};

export default UserService;

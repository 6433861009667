import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 3rem;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.token.colorBgLayout};
`;

import React from 'react';
import {Button, theme} from 'antd';
import {B} from '../../../common/styles';
import useObject from '../../../data/use-object';
import {QuestionContainer, StyledAnswerRow} from '../styles';
import {QuestionViewInterface} from '../index';
import {IonIcon} from '@ionic/react';
import {StyledInfoText} from './styles';
import {informationCircle} from 'ionicons/icons';
import ObjectSearchSelect from '../../ObjectSearchSelect';

const ObjectQuestionView = ({
  question,
  questionResponse,
  onChange,
}: QuestionViewInterface) => {
  if (!question.objectType) {
    return null;
  }

  const updateAnswer = (value: string | null) => {
    questionResponse.answer = value;
    onChange?.();
  };

  const {data} = useObject({
    objectType: question.objectType,
    objectId: questionResponse.answer as string,
  });

  const {token} = theme.useToken();
  return (
    <QuestionContainer>
      <B>{question.prompt}</B>
      <StyledAnswerRow justify='start'>
        <ObjectSearchSelect
          objectType={question.objectType}
          selectedId={questionResponse.answer as string | null}
          onChange={updateAnswer}
        />
        <Button
          onClick={() => updateAnswer(null)}
          type='text'
          disabled={questionResponse.answer === null}
          danger
          style={{marginLeft: 8}}
        >
          Clear
        </Button>
      </StyledAnswerRow>
      {data && data.infoAlert && (
        <StyledAnswerRow justify='start' align='middle'>
          <IonIcon color={token.colorTextTertiary} icon={informationCircle} />
          <StyledInfoText>{data.infoAlert}</StyledInfoText>
        </StyledAnswerRow>
      )}
    </QuestionContainer>
  );
};

export default ObjectQuestionView;

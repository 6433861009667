import useSWR from 'swr';
import {Integration} from '../types/integrations.types';
import IntegrationService from '../services/integrations.service';

export default function useIntegrations() {
  const {data: integrations, error} = useSWR<Integration[], Error>(
    process.env.REACT_APP_INTEGRATIONS_ENDPOINT!,
    IntegrationService.getIntegrations,
  );
  const isLoading = !integrations && !error;

  return {
    isLoading,
    integrations,
    error,
  };
}

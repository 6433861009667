import {useState, useEffect} from 'react';
import useSWR from 'swr';
import {BlockNoteEditor, Block} from '@blocknote/core';
import {useBlockNote} from '@blocknote/react';

import {useDebounce} from '../hooks/use-debounced';
import {Note, NoteEdit} from '../types/notes.types';
import NoteService from '../services/note.service';
import useUser from './use-user';

export default function useNote(id: string | null, debounceTime = 2000) {
  const {user} = useUser();
  const [noteId, setNoteId] = useState<string | null>(id);
  const onServer = noteId !== null;
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const {data, mutate, error} = useSWR<Note>(
    onServer ? process.env.REACT_APP_NOTES_ENDPOINT! + noteId : null,
    NoteService.getNote,
  );
  const isLoading = !data && !error && onServer;
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setNoteId(id);
    }
  }, [id]);

  const editor = useBlockNote({
    editable: data && data.locked ? false : true,
    initialContent:
      data?.blocks && data?.blocks.length > 0 ? data?.blocks : undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onEditorContentChange: (editor: BlockNoteEditor) => {
      setHasChanges(true);
    },
    defaultStyles: false,
    editorDOMAttributes: {
      class: 'blockEditor',
    },
  });

  function setEditorContent(blocks: Block[]) {
    editor.replaceBlocks(editor.topLevelBlocks, blocks);
    setHasChanges(false);
  }

  useEffect(() => {
    if (data?._id && editor && data) {
      setEditorContent(data.blocks);
    }
    if (data?.locked) {
      editor.isEditable = false;
    } else {
      editor.isEditable = true;
    }
  }, [data?._id]);

  async function _save(): Promise<void> {
    if (data && onServer) {
      const noteEdit = {
        datetime: new Date(),
        blocks: editor.topLevelBlocks,
      } as NoteEdit;
      await NoteService.patchNote(
        process.env.REACT_APP_NOTES_ENDPOINT! + noteId,
        noteEdit,
      );
      mutate(data);
    }
  }

  async function _saveNew(): Promise<void> {
    if (!onServer && user) {
      const date = new Date();
      const noteData = {
        _id: 'tmp',
        userId: user?.id,
        orgId: user?.orgId,
        created: date,
        lastUpdated: date,
        transcripts: [],
        blocks: editor.topLevelBlocks,
        submitted: false,
        locked: false,
        linkedObjects: [],
      };
      const createdNote = await NoteService.createNote(noteData as Note);
      if (createdNote) {
        setNoteId(createdNote._id);
        mutate(createdNote);
      }
    }
  }

  const save = onServer ? _save : _saveNew;
  const autoSave = useDebounce(save, debounceTime);

  useEffect(() => {
    if (hasChanges) {
      if (isDeleted) {
        setHasChanges(false);
        setIsDeleted(false);
      } else {
        setHasChanges(false);
        autoSave();
      }
    }
  }, [autoSave, hasChanges]);

  const deleteNote = async () => {
    if (onServer) {
      const result = await NoteService.deleteNote(noteId);
      if (result) {
        setNoteId(null);
        setIsDeleted(true);
        setEditorContent([]);
      }
    }
  };

  return {
    isLoading,
    note: data,
    mutate,
    error,
    save,
    autoSave,
    editor,
    deleteNote,
    noteId,
    setNoteId,
    setEditorContent,
  };
}

import React from 'react';
import {Row, Col, Space} from 'antd';
import {P3, B, RoundedButton} from '../../common/styles';
import {PlainTextButton} from '../../common/styles';

interface SettingDisplayProps {
  label: string;
  value?: string;
  buttonLabel?: string | undefined;
  onClick?: () => void | undefined;
  danger?: boolean;
  primary?: boolean;
}

const SettingDisplay = ({
  label,
  value,
  buttonLabel,
  onClick,
  danger,
  primary,
}: SettingDisplayProps) => {
  const button =
    buttonLabel &&
    onClick &&
    (primary ? (
      <RoundedButton danger={danger} onClick={onClick}>
        {buttonLabel}
      </RoundedButton>
    ) : (
      <PlainTextButton danger={danger} onClick={onClick}>
        {buttonLabel}
      </PlainTextButton>
    ));
  return (
    <Row justify='space-between' align='middle' style={{height: 52}}>
      <Col>
        <B>{label}</B>
      </Col>
      <Col>
        <Space>
          {value && <P3>{value}</P3>}
          {button && button}
        </Space>
      </Col>
    </Row>
  );
};

export default SettingDisplay;

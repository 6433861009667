import {useEffect, useState} from 'react';
import useSWR from 'swr';
import StreamsService from '../services/streams.service';
import RunsService from '../services/runs.service';
import {StreamRunRead} from '../types/runs.types';

interface UseStreamRunProps {
  streamId: string;
  streamRunId?: string;
  params: Record<string, string | File | undefined>;
}

const useStreamRun = ({streamId, streamRunId, params}: UseStreamRunProps) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [pollingIntervalId, setPollingIntervalId] =
    useState<NodeJS.Timeout | null>(null);

  const {data, error, mutate, isLoading} = useSWR(
    streamRunId ? process.env.REACT_APP_RUNS_ENDPOINT! + streamRunId : null,
    RunsService.getRun,
  );

  const setError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setIsProcessing(false);
    setTimeout(() => {
      setErrorMessage(undefined);
    }, 15000);
  };

  const subscribe = () => {
    if (pollingIntervalId) {
      return;
    }
    const id = setInterval(() => {
      mutate();
    }, 2000);

    setPollingIntervalId(id);
  };

  const start = async () => {
    if (!params.file) {
      setError('An audio file is required to run this Stream.');
      return false;
    }
    if (!params.language) {
      setError('Select the language spoken in the recording.');
      return false;
    }
    setIsProcessing(true);
    try {
      const streamRunId: string | null =
        await StreamsService.createStreamRunFromAudio(
          streamId,
          params.file as File,
          {
            language: params.language as string,
          },
        );
      if (!streamRunId) {
        setError('Failed to start this Stream. Please try again.');
        return false;
      }
      return streamRunId;
    } catch (error) {
      setError('Failed to start this Stream. Please try again.');
      return false;
    }
  };

  const retry = async () => {
    setIsProcessing(true);
    if (!streamRunId) {
      setError('Something went wrong. Please try again.');
      return false;
    }
    try {
      const success = await RunsService.retryStreamRun(streamRunId);
      if (!success) {
        setError('Failed to retry this Stream. Please try again.');
        return false;
      }
      await mutate();
      return true;
    } catch (error) {
      setError('Failed to retry this Stream. Please try again.');
      return false;
    }
  };

  const unsubscribe = () => {
    if (pollingIntervalId) {
      clearInterval(pollingIntervalId);
      setPollingIntervalId(null);
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (
      data &&
      'streamRun' in data &&
      (data.streamRun.status === 'RUNNING' ||
        data.streamRun.status === 'PENDING') &&
      !pollingIntervalId
    ) {
      subscribe();
    }
    if (
      data &&
      'streamRun' in data &&
      data.streamRun.status !== 'RUNNING' &&
      pollingIntervalId
    ) {
      unsubscribe();
    }
  }, [data, pollingIntervalId]);

  return {
    streamRunRead: data as StreamRunRead | undefined,
    start,
    retry,
    unsubscribe,
    error,
    isLoading,
    isProcessing,
    errorMessage,
  };
};

export default useStreamRun;

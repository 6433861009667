import React from 'react';
import {Form, Row, Col} from 'antd';
import {Organization} from '../../../types/orgs.types';
import {
  FormTitle,
  Instructions,
  OrgCard,
  OrgDescription,
  EmailDomainText,
  OrgName,
  ButtonRow,
} from '../styles';
import {PlainTextButton} from '../../../common/styles';
import FancyButton from '../../../common/FancyButton';

interface JoinOrgProps {
  firstName: string;
  org: Organization;
  onCompletion: (org: Organization) => void;
  onBack: () => void;
}

const JoinOrg = ({firstName, org, onCompletion, onBack}: JoinOrgProps) => {
  return (
    <Form>
      <FormTitle>Join your colleagues</FormTitle>
      <Instructions>
        Nice to meet you, {firstName}! Looks like your colleagues are already on
        voam. Do you want to join {org.name}?
      </Instructions>
      <OrgCard>
        <OrgName>{org.name}</OrgName>
        {org.description && <OrgDescription>{org.description}</OrgDescription>}
        <EmailDomainText>{org.emailDomain}</EmailDomainText>
      </OrgCard>
      <ButtonRow justify='space-between' align='middle' gutter={[12, 12]}>
        <Col xs={{span: 24, order: 2}} sm={{span: 11, order: 1}}>
          <Row justify='start'>
            <PlainTextButton danger onClick={onBack}>
              Use different email address
            </PlainTextButton>
          </Row>
        </Col>
        <Col xs={{span: 24, order: 1}} sm={{span: 11, order: 2}}>
          <Row justify='end'>
            <FancyButton onClick={() => onCompletion(org)}>Join</FancyButton>
          </Row>
        </Col>
      </ButtonRow>
    </Form>
  );
};

export default JoinOrg;

import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Row, Col, Input, Form} from 'antd';
import {withTranslation} from 'react-i18next';
import {
  StyledCardHeading,
  StyledMessage,
  StyledForm,
  StyledFormItem,
  ErrorMessage,
} from '../styles';
import AuthService from '../../../services/auth.service';
import FancyButton from '../../../common/FancyButton';
import LoadingIndicator from '../../../components/LoadingIndicator';
import useUser from '../../../data/use-user';
import {PlainTextButton} from '../../../common/styles';

interface LoginFormProps {
  onForgotPassword: () => void;
}

const LoginForm = ({onForgotPassword}: LoginFormProps) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const {mutate} = useUser();

  const onFinish = (values: unknown) => {
    setMessage('');
    setLoading(true);
    const formData = values as {username: string; password: string};
    AuthService.logIn(formData.username, formData.password).then(
      success => {
        if (success) {
          mutate().then(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            navigate((location.state as any)?.redirect ?? '/');
          });
        } else {
          setLoading(false);
          setMessage('Please check your credentials and try again.');
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      },
    );
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
    setLoading(false);
    setMessage('Login failed. Please check your credentials and try again.');
  };

  return (
    <>
      <Row justify='center' align='middle'>
        <StyledForm
          name='login'
          autoComplete='off'
          layout='vertical'
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Col xs={{span: 24, offset: 0}} sm={{span: 18, offset: 3}}>
            <StyledCardHeading>Welcome back!</StyledCardHeading>
            <StyledMessage>Please log in to continue.</StyledMessage>
            <StyledFormItem
              label='Email'
              name='username'
              rules={[{required: true, message: 'Please enter your email.'}]}
            >
              <Input />
            </StyledFormItem>
            <StyledFormItem
              label='Password'
              name='password'
              rules={[{required: true, message: 'Please enter your password.'}]}
            >
              <Input.Password />
            </StyledFormItem>
            <Row justify='end'>
              <StyledFormItem>
                <FancyButton disabled={loading} onClick={() => form.submit()}>
                  Log in
                </FancyButton>
              </StyledFormItem>
            </Row>
            <StyledFormItem>
              {loading && <LoadingIndicator message='Logging in...' />}
              {!loading && <ErrorMessage>{message}</ErrorMessage>}
            </StyledFormItem>
            <Row justify='space-around'>
              <PlainTextButton onClick={onForgotPassword}>
                Forgot password?
              </PlainTextButton>
            </Row>
          </Col>
        </StyledForm>
      </Row>
    </>
  );
};

export default withTranslation()(LoginForm);

import API from './api';
import {
  Organization,
  OrganizationCreate,
  OrganizationUpdate,
} from '../types/orgs.types';

async function createOrganization(
  newOrg: OrganizationCreate,
): Promise<Organization | null> {
  return await API.post(process.env.REACT_APP_ORGANIZATIONS_ENDPOINT!, newOrg)
    .then(res => res.data)
    .catch(() => {
      return null;
    });
}

async function patchOrganization(
  orgId: string,
  orgUpdate: OrganizationUpdate,
): Promise<Organization | null> {
  return await API.patch(
    `${process.env.REACT_APP_ORGANIZATIONS_ENDPOINT!}${orgId}`,
    orgUpdate,
  )
    .then(res => res.data)
    .catch(() => {
      return null;
    });
}

const OrgService = {
  createOrganization,
  patchOrganization,
};

export default OrgService;

import {Block, InlineContent} from '@blocknote/core';
import {
  Form,
  FormResponse,
  FormHeaderMap,
  Answer,
  QuestionType,
  Question,
} from '../types/formless.types';
import {LinkedObject} from '../types/integrations.types';
import {Voam} from '../types/voams.types';

export function newFormResponse(form: Form, userId: string): FormResponse {
  const date = new Date();
  return {
    _id: 'tmp',
    userId: userId,
    formId: form._id,
    created: date,
    lastUpdated: date,
    submitted: false,
    locked: false,
    transcripts: [],
    linkedObjects: [],
    topics: form.topics.map(topic => {
      return {
        name: topic.name,
        questions: topic.questions.map(question => {
          return {
            name: question.name,
            answer: null,
          };
        }),
      };
    }),
  };
}

export function getFormHeaders(form?: Form): FormHeaderMap {
  const headers: FormHeaderMap = {
    topics: {},
    questions: {},
  };
  if (!form) return headers;
  form.topics.forEach(topic => {
    headers.topics[topic.name] = topic.header ?? topic.prompt;
    topic.questions.forEach(question => {
      headers.questions[question.name] =
        !question.header || question.header === ''
          ? question.prompt
          : question.header;
    });
  });
  return headers;
}

export function getObjectTypeMap(form: Form): {[key: string]: string} {
  const map: {[key: string]: string} = {};
  form.topics.forEach(topic => {
    topic.questions.forEach(question => {
      if (question.type === QuestionType.OBJECT && question.objectType) {
        map[question.name] = question.objectType;
      }
    });
  });
  return map;
}

export function getLocationQuestions(form: Form): Question[] {
  const questions: Question[] = [];
  form.topics.forEach(topic => {
    topic.questions.forEach(question => {
      if (
        question.type === QuestionType.OBJECT &&
        question.objectType === 'location-nl-city'
      ) {
        questions.push(question);
      }
    });
  });
  return questions;
}

export function answerToString(answer: Answer): string {
  if (answer instanceof Date) {
    return answer.toLocaleDateString();
  }
  if (answer instanceof Array) {
    return answer.join(', ');
  }
  if (answer === true) {
    return 'Yes';
  }
  if (answer === false) {
    return 'No';
  }
  return answer as string;
}

export function isLinkedTo(voam: Voam, link: LinkedObject): boolean {
  return voam.linkedObjects
    ? voam.linkedObjects.some(linkedObject => {
        return (
          linkedObject.integrationId === link.integrationId &&
          linkedObject.id === link.id &&
          linkedObject.type === link.type
        );
      })
    : false;
}

export function getLinkedObject(voam: Voam): LinkedObject | undefined {
  return voam && voam.linkedObjects && voam.linkedObjects.length > 0
    ? voam.linkedObjects[0]
    : undefined;
}

export const toRelativeDate = (date: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const dateOnly = new Date();
  dateOnly.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
  dateOnly.setHours(0, 0, 0, 0);
  const diff = dateOnly.getTime() - today.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (days < -365) {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  } else if (days < -60) {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  } else if (days < -3) {
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });
  } else if (days < -1) {
    return `${-days} days ago`;
  } else if (days === -1) {
    return 'Yesterday';
  } else if (days === 0) {
    return 'Today';
  } else if (days === 1) {
    return 'Tomorrow';
  } else if (days < 4) {
    return `In ${days} days`;
  } else {
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    });
  }
};

function getContentText(content: InlineContent): string {
  return content.type === 'text'
    ? content.text
    : content.content.map(getContentText).join(' ');
}

export const getBlockText = (block: Block) => {
  return block.content?.map(c => getContentText(c)).join(' ');
};

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function formatSecondsToTime(seconds: number): string {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const hrsString = hrs < 10 ? `0${hrs}` : `${hrs}`;
  const minsString = mins < 10 ? `0${mins}` : `${mins}`;
  const secsString = secs < 10 ? `0${secs}` : `${secs}`;

  return `${hrsString}:${minsString}:${secsString}`;
}

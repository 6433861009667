import React, {useState} from 'react';
import ViewerContainer from '../../common/ViewerContainer';
import Recorder from '../Recorder';
import {
  BlockNoteView,
  DefaultSideMenu,
  DragHandleMenu,
  RemoveBlockButton,
  SideMenuPositioner,
  SlashMenuPositioner,
} from '@blocknote/react';
import {Block, BlockNoteEditor} from '@blocknote/core';
import '@blocknote/core/style.css';
import useNote from '../../data/use-note';
import useSubmit from '../../hooks/use-submit';
import NoteService from '../../services/note.service';
import VoamHeaderToolBar from '../VoamHeaderToolBar';
import {Alert} from 'antd';

export interface NoteViewerInterface {
  id: string | null;
  onDelete?: () => void;
}

const NoteViewer = ({id, onDelete}: NoteViewerInterface) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const {
    noteId,
    note,
    setNoteId,
    setEditorContent,
    save,
    mutate,
    isLoading,
    editor,
    deleteNote,
  } = useNote(id);

  const {
    submit,
    isSubmitting,
    errorMessage: submitErrorMessage,
  } = useSubmit({
    voam: note,
    save,
    setIsProcessing,
    mutate,
  });

  const processAudio = async (audio: File) => {
    setIsProcessing(true);
    const updatedNote = await NoteService.postAudio(noteId ?? 'tmp', audio);
    if (updatedNote) {
      setNoteId(updatedNote._id);
      mutate(updatedNote);
      setEditorContent(updatedNote.blocks);
    }
    setIsProcessing(false);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    } else {
      deleteNote();
    }
  };

  const CustomDragHandleMenu = (props: {
    editor: BlockNoteEditor;
    block: Block;
  }) => {
    return (
      <DragHandleMenu>
        <RemoveBlockButton {...props}>Delete</RemoveBlockButton>
      </DragHandleMenu>
    );
  };

  return (
    <>
      <ViewerContainer>
        <VoamHeaderToolBar
          voam={note}
          isSubmitting={isSubmitting}
          isLoading={isLoading}
          isProcessing={isProcessing}
          onDelete={handleDelete}
          onSubmit={submit}
          onChange={mutate}
        />
        {submitErrorMessage && (
          <Alert message={submitErrorMessage} type='error' closable />
        )}
        {editor && (
          <BlockNoteView editor={editor}>
            <SlashMenuPositioner editor={editor} />
            <SideMenuPositioner
              editor={editor}
              sideMenu={props => (
                <DefaultSideMenu
                  {...props}
                  dragHandleMenu={CustomDragHandleMenu}
                />
              )}
            />
          </BlockNoteView>
        )}
        <Recorder
          disabled={isLoading}
          processing={isProcessing}
          onAudioFile={processAudio}
          hidden={note?.locked}
        />
      </ViewerContainer>
    </>
  );
};

export default NoteViewer;

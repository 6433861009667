import React from 'react';
import {htmlToText} from 'html-to-text';
import {
  ColoredCardTitle,
  ColoredCardPreviewContainer,
  ColoredCard,
  P3,
  SecondaryAccentText,
} from '../../common/styles';
import {Activity} from '../../types/integrations.types';
import {StyledTime} from './styles';
import {theme} from 'antd';

interface MeetingItemProps {
  activity: Activity;
  onClick?: () => void;
}

const ActivityItem = ({activity, onClick}: MeetingItemProps) => {
  const {token} = theme.useToken();
  return (
    <ColoredCard
      color={token.colorInfo}
      onClick={onClick}
      $notClickable={onClick === undefined}
    >
      <StyledTime>
        {activity.startTime?.toLocaleTimeString(undefined, {
          hour: '2-digit',
          minute: '2-digit',
        })}{' '}
        -{' '}
        {activity.endTime?.toLocaleTimeString(undefined, {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </StyledTime>
      <ColoredCardTitle color={token.colorInfo}>
        {activity.title}
      </ColoredCardTitle>
      {activity.note ? (
        <ColoredCardPreviewContainer numLines={3}>
          <SecondaryAccentText>Existing note </SecondaryAccentText>
          {htmlToText(activity.note).trim()}
        </ColoredCardPreviewContainer>
      ) : (
        <P3>Empty note</P3>
      )}
    </ColoredCard>
  );
};

export default ActivityItem;

import React from 'react';
import useUser from '../../data/use-user';
import {P, H4, B} from '../../common/styles';
import {Col, Divider, Row} from 'antd';
import {GuideContainer} from '../GettingStarted/styles';

const WelcomeBack = () => {
  const {user} = useUser();
  return (
    <GuideContainer>
      <H4>Great to see you, {user?.firstName}.</H4>
      <Divider />
      <P>We&apos;re excited to share the latest updates to voam with you.</P>
      <ul>
        <li>
          <B>CRM Integrations:</B> You can now connect your voam account to
          HubSpot or Pipedrive to send meeting notes directly to your CRM. Get
          set up on the Settings page.
        </li>
        <li>
          <B>Standard templates:</B> We&apos;ve added some standard templates
          for common tasks that our customers perform often. Remember that you
          can also request custom templates free of charge, just reach out to
          us!
        </li>
      </ul>
      <P>We&apos;d love to hear what think!</P>
      <Divider />
      <Row justify='space-between'>
        <Col>
          <Row>
            <B>Coffee?</B>
          </Row>
          <Row>
            <a href='https://outlook.office365.com/owa/calendar/voammeeting@voam.io/bookings/s/LCeyb0R-ZESP5DUG2EdYVg2'>
              Book a meeting
            </a>
          </Row>
        </Col>
        <Col>
          <Row>
            <B>Support</B>
          </Row>
          <Row>
            <a href='mailto:support@voam.io'>support@voam.io</a>
          </Row>
        </Col>
        <Col>
          <Row>
            <B>Feedback</B>
          </Row>
          <Row>
            <a href='mailto:feedback@voam.io'>feedback@voam.io</a>
          </Row>
        </Col>
      </Row>
    </GuideContainer>
  );
};

export default WelcomeBack;

import styled from 'styled-components';
import {Card, Row} from 'antd';

export const StyledLoginCard = styled(Card)`
  padding: 1rem 0;
  max-width: 700px;
  border-radius: 16px;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

  @media only screen and (max-width: 714px) {
    margin: 2rem 0.5rem;
  }
`;

export const Background = styled('div')`
  background: #fafaf9;
  width: 100%;
  height: 100vh;
`;

export const StyledHeaderRow = styled(Row)`
  height: 79px;
  background: #ffffff;
  border-bottom: 1px solid #f2f2f3;
`;

import useSWR from "swr";
import { Note } from "../types/notes.types";
import NoteService from "../services/note.service";

export default function useNotes() {
    const { data, mutate, error } = useSWR(process.env.REACT_APP_NOTES_ENDPOINT!, NoteService.getNotes);
    const isLoading = !data && !error;
    const loggedOut = error && error.message === "Request failed with status code 401";

    if (data) {
        data.sort((a: Note, b: Note) => {
            return a.lastUpdated.valueOf() > b.lastUpdated.valueOf() ? -1 : 1;
        });
    }

    return {
        isLoading,
        loggedOut,
        notes: data,
        mutate,
        error
    };
}

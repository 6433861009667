import React from 'react';
import {FormHeader} from '../../common/styles';

interface FormHeaderProps {
  name: string;
  hasMenu: boolean;
  editable?: boolean;
  onChange?: (name: string) => void;
}

const FormResponseHeader = ({
  name,
  hasMenu,
  editable,
  onChange,
}: FormHeaderProps) => {
  const editConfig = {
    triggerType: ['text', 'icon'] as ('text' | 'icon')[],
    onChange: onChange,
  };

  return (
    <FormHeader
      style={{marginTop: hasMenu ? '45px' : '0px'}}
      editable={editable ? editConfig : undefined}
    >
      {name}
    </FormHeader>
  );
};

export default FormResponseHeader;

import styled from 'styled-components';
import {Row, Button} from 'antd';

export const HeaderRow = styled(Row)`
  position: sticky;
  top: 0;
  background-color: white;
  padding: 5px 0 10px;
  z-index: 1;
`;

export const ToolBarButton = styled(Button)`
  font-size: 0.8rem;
  border: none;
  box-shadow: none;
  padding: 4px 10px;
  :not(:disabled):hover {
    background-color: ${props => props.theme.token.colorFillHover};
    border: none;
    box-shadow: none;
  }
  :disabled {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`;

export const FixedWidthLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100px;
`;

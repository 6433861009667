import API from './api';
import {NewUser, User} from '../types/users.types';
import {EmailCheckResult} from '../types/auth.types';

const logIn = async (username: string, password: string): Promise<boolean> => {
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  try {
    return await API.post(process.env.REACT_APP_LOGIN_ENDPOINT!, params).then(
      response => {
        if (response.status === 200) {
          return true;
        } else {
          return false;
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _error => {
        return false;
      },
    );
  } catch (error) {
    return false;
  }
};

const logOut = async (): Promise<boolean> => {
  try {
    return await API.post(process.env.REACT_APP_LOGOUT_ENDPOINT!).then(
      response => {
        if (response.status === 200) {
          return true;
        } else {
          return false;
        }
      },
    );
  } catch (error) {
    return false;
  }
};

const checkAuth = async (): Promise<boolean> => {
  try {
    return await API.get(process.env.REACT_APP_CHECK_AUTH_ENDPOINT!).then(
      response => {
        if (response.status === 200) {
          return true;
        } else {
          return false;
        }
      },
    );
  } catch (error) {
    return false;
  }
};

async function checkEmail(email: string): Promise<EmailCheckResult> {
  const params = new URLSearchParams();
  params.append('email', email);
  return await API.post(process.env.REACT_APP_CHECK_EMAIL_ENDPOINT!, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(res => res.data)
    .catch(() => {
      return {
        success: false,
        email: email,
        message: 'Something went wrong. Please try again later.',
      };
    });
}

async function register(newUser: NewUser): Promise<User | null> {
  return await API.post(process.env.REACT_APP_REGISTER_ENDPOINT!, newUser)
    .then(res => res.data)
    .catch(() => {
      return null;
    });
}

async function requestVerifyEmail(email: string): Promise<boolean> {
  return await API.post(process.env.REACT_APP_REQUEST_VERIFY_ENDPOINT!, {
    email,
  })
    .then(res => res.status === 202)
    .catch(() => {
      return false;
    });
}

const forgotPassword = async (email: string): Promise<boolean> => {
  try {
    return await API.post(
      process.env.REACT_APP_FORGOT_PASSWORD_ENDPOINT!,
      {email: email},
      {headers: {'content-type': 'application/json'}},
    ).then(response => {
      if (response.status === 202) {
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    return false;
  }
};

const AuthService = {
  logIn,
  logOut,
  checkAuth,
  checkEmail,
  register,
  requestVerifyEmail,
  forgotPassword,
};

export default AuthService;

import {useState, useEffect} from 'react';
import useSWR from 'swr';
import SearchService from '../services/search.service';
import useObject from './use-object';
import {DataObject} from '../types/objects.types';

interface UseObjectSearchProps {
  objectType: string;
  selectedObjectId: string | null;
}

export default function useObjectSearch({
  objectType,
  selectedObjectId,
}: UseObjectSearchProps) {
  const {data: selectedObject} = useObject({
    objectType,
    objectId: selectedObjectId,
  });
  const [searchTerm, setSearchTerm] = useState(
    selectedObject ? selectedObject.name : '',
  );

  useEffect(() => {
    if (selectedObject) {
      setSearchTerm(selectedObject.name);
    } else {
      setSearchTerm('');
    }
  }, [selectedObject]);

  const {
    data: searchResults,
    isLoading: isSearching,
    error: errorSearch,
  } = useSWR<DataObject[], Error>(
    objectType && searchTerm && searchTerm.length > 2
      ? {
          url: `${process.env
            .REACT_APP_SEARCH_ENDPOINT!}objects/${objectType}/`,
          searchQuery: {keyword: searchTerm, skip: 0, limit: 10},
        }
      : null,
    ({url, searchQuery}) => SearchService.searchObjects(url, searchQuery),
    {dedupingInterval: 100000},
  );

  return {
    selectedObject,
    searchResults: searchResults || [],
    isSearching,
    errorSearch,
    searchTerm,
    setSearchTerm,
  };
}

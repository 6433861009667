import React from 'react';
import OpenQuestionView from './OpenQuestionView';
import NumberQuestionView from './NumberQuestionView';
import BoolQuestionView from './BoolQuestionView';
import SelectSingleQuestionView from './SelectSingleQuestionView';
import SelectMultipleQuestionView from './SelectMultipleQuestionView';
import ObjectQuestionView from './ObjectQuestionView';
import {
  Question,
  QuestionResponse,
  QuestionType,
} from '../../types/formless.types';

export interface QuestionViewInterface {
  question: Question;
  questionResponse: QuestionResponse;
  onChange?: () => void;
}

const QuestionView = ({
  question,
  questionResponse,
  onChange,
}: QuestionViewInterface) => {
  if (question.type === QuestionType.OPEN) {
    return (
      <OpenQuestionView
        question={question}
        questionResponse={questionResponse}
        onChange={onChange}
      />
    );
  }
  if (
    question.type === QuestionType.NUMBER ||
    question.type === QuestionType.INTEGER
  ) {
    return (
      <NumberQuestionView
        question={question}
        questionResponse={questionResponse}
        onChange={onChange}
      />
    );
  }
  if (question.type === QuestionType.BOOL) {
    return (
      <BoolQuestionView
        question={question}
        questionResponse={questionResponse}
        onChange={onChange}
      />
    );
  }
  if (
    question.type === QuestionType.SELECT_SINGLE ||
    question.type === QuestionType.SCALE
  ) {
    return (
      <SelectSingleQuestionView
        question={question}
        questionResponse={questionResponse}
        onChange={onChange}
      />
    );
  }
  if (question.type === QuestionType.SELECT_MULTIPLE) {
    return (
      <SelectMultipleQuestionView
        question={question}
        questionResponse={questionResponse}
        onChange={onChange}
      />
    );
  }
  if (question.type === QuestionType.OBJECT) {
    return (
      <ObjectQuestionView
        question={question}
        questionResponse={questionResponse}
        onChange={onChange}
      />
    );
  }
  return <></>;
};

export default QuestionView;

import styled from "styled-components";

export const StyledImg = styled.img`
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 3rem;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
`;
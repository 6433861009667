import React from 'react';
import {Alert, Button, Divider, Row, Steps, theme} from 'antd';
import useUser from '../../data/use-user';
import {GuideContainer, StyledAlert} from './styles';
import {SvgIcon} from '../../common/Image';
import {P, P3, B, PlainTextButton} from '../../common/styles';
import {IonIcon} from '@ionic/react';
import {shareSocial} from 'ionicons/icons';
import ConnectCRMForm from '../ConnectCRMForm';
import {PaperPlaneOutline} from 'react-ionicons';

interface GettingStartedProps {
  step: number;
  setStep: (step: number) => void;
}

const GettingStarted = ({step, setStep}: GettingStartedProps) => {
  const {user} = useUser();
  const {token} = theme.useToken();

  const navigationRow = (
    <Row
      justify={step === 0 ? 'end' : 'space-between'}
      align='middle'
      style={{margin: '10px 0'}}
    >
      {step > 0 && (
        <PlainTextButton onClick={() => setStep(step - 1)}>
          Previous step
        </PlainTextButton>
      )}
      {step === 7 && (
        <PlainTextButton onClick={() => setStep(0)}>Start over</PlainTextButton>
      )}
      {step < 8 && (
        <Button type='primary' onClick={() => setStep(step + 1)}>
          Mark as completed
        </Button>
      )}
    </Row>
  );

  const items = [
    {
      key: 'welcome',
      title: <B>Welcome to voam!</B>,
      description:
        step !== 0 ? null : (
          <div>
            <P>
              Great to see you here, {user?.firstName}! We&apos;re excited to
              have you on board.
            </P>
            <P>
              This guide will help you get started with voam. By the end, we
              hope you&apos;ll be able to see the opportunities that voam can
              offer you and your team.
            </P>
            <Alert
              message='You can always access this guide from the Help menu in the top right corner.'
              type='info'
              showIcon
              style={{
                margin: '10px 0',
                borderRadius: '12px',
                border: 'none',
                color: token.colorInfo,
              }}
            />
            <P>Mark this step as completed to get started.</P>
            {navigationRow}
          </div>
        ),
    },
    {
      key: 'download',
      title: <B>Download the app</B>,
      description:
        step !== 1 ? null : (
          <div>
            <P>
              One of the benefits of doing your administration by voice, is that
              you can do it anywhere and anytime, without having to sit down at
              a computer. That&apos;s why recording voams works best on your
              phone. If you haven&apos;t done so, download the voam app now.
            </P>
            <Row justify='start' align='middle' style={{margin: '5px 0 10px'}}>
              <SvgIcon
                src='badges/app-store-badge.svg'
                width='130px'
                height='40px'
              />
              <SvgIcon
                src='badges/google-play-badge.svg'
                width='130px'
                height='40px'
              />
              <div style={{flex: 1}} />
            </Row>
            <P>
              Use the app to perform the next steps of this guide. This way, you
              can keep this screen open.
            </P>
            <P3>
              Once you&apos;ve logged into the app, mark this item as completed
              and move on to the next step!
            </P3>
            {navigationRow}
          </div>
        ),
    },
    {
      key: 'createFreeForm',
      title: <B>Create your first voam</B>,
      description:
        step !== 2 ? null : (
          <div>
            <P>
              Let&apos;s create your first voam! Go to the Create screen in the
              app and tap Structured Note. This type of voam determines the
              structure of your note based on what you have to say. They are
              great for taking longer notes about arbitrary topics.
            </P>
            <StyledAlert
              message="With voam, you can always speak as if you're talking to a person,
            no need to dictate word-by-word or to use specific commands."
              type='info'
              showIcon
              style={{
                margin: '10px 0',
                borderRadius: '12px',
                border: 'none',
                color: token.colorInfo,
              }}
            />
            <P>Go ahead and tap the record button to give it a try!</P>
            <P3>
              Need some inpiration? Talk about three types of food you enjoy,
              what you did last weekend, and/or any holiday plans you might
              have.
            </P3>
            {navigationRow}
          </div>
        ),
    },
    {
      key: 'share',
      title: <B>Export your voam</B>,
      description:
        step !== 3 ? null : (
          <div>
            <P>
              Amazing! Now that you have created your first voam, you might want
              to share it or save it somewhere else. While voam can be
              integrated with other tools, you can always copy or email a voam
              to yourself. For now, try sharing your voam with yourself via
              email using the share button in the app.
            </P>
            <IonIcon
              icon={shareSocial}
              size='large'
              style={{margin: '10px 0'}}
            />
            <P3>
              In case you are using a markdown-based tool like Notion or Coda,
              try the Copy as Markdown option and paste it directly into your
              Notion page.
            </P3>
            {navigationRow}
          </div>
        ),
    },
    {
      key: 'connectCRM',
      title: <B>Connect your CRM</B>,
      description:
        step !== 4 ? null : (
          <div>
            {!user?.integrations.crm && (
              <>
                <P>
                  If you use a CRM to store meeting notes and other customer
                  interactions, we recommend you connect your CRM now. This way,
                  you can let voam take care of updating the CRM for you.
                </P>
                <div style={{margin: '10px 20px'}}>
                  <ConnectCRMForm />
                </div>
                <P3>You can skip this step by marking it as completed.</P3>
              </>
            )}
            {user?.integrations.crm && (
              <P>
                You have connected your CRM. Great! Go ahead and mark this step
                as completed.
              </P>
            )}
            {navigationRow}
          </div>
        ),
    },
    {
      key: 'createTemplated',
      title: <B>Create a templated voam</B>,
      description:
        step !== 5 ? null : (
          <div>
            <P>
              Voams can have templates. Templates are designed to support a
              specific task that you have to do more often. We offer some
              standard templates for common tasks, such as writing meeting notes
              or logging a (customer) call.
            </P>
            <ul>
              <li>
                The predefined structure of these templates helps you to create
                quality documentation.
              </li>
              <li>The prompts on your screen remind you what to talk about.</li>
              <li>
                Since they are capable of extracting specific data points from
                your voice, they also enable powerful automations and
                integrations with other systems.
              </li>
            </ul>
            <P>For now, try logging a fictive call.</P>
            <P3>
              You can use this script: ☎️{' '}
              <i>
                &quot;I tried to call Joe from Voam Technologies, but he
                didn&apos;t answer, so I left a voicemail. I will try again
                tomorrow.&quot;
              </i>
            </P3>
            {navigationRow}
          </div>
        ),
    },
    {
      key: 'submit',
      title: <B>Submit your voam</B>,
      description:
        step !== 6 ? null : (
          <div>
            <Row>
              <P style={{flex: 1, width: '80%'}}>
                You may have noticed the submit button on that last voam. By
                submitting a voam, you do a few things:
              </P>
              <PaperPlaneOutline
                color={token.colorPrimary}
                width='40px'
                height='40px'
                style={{margin: '0px 20px'}}
              />
            </Row>
            <ul>
              <li>
                You indicate that you&apos;ve checked the contents and the voam
                is ready.
              </li>
              <li>
                You send the extracted data to the database, meaning it shows up
                in Search and can be viewed by your colleagues.
              </li>
              <li>
                You send the data to any linked meetings or other items in your
                CRM.
              </li>
              <li>
                You trigger all automations that you have configured for the
                template. By default, the result will be sent to you by email.
              </li>
            </ul>
            <P>Go ahead and submit your voam now!</P>
            <StyledAlert
              type='info'
              showIcon
              message='Structured Notes can only be submitted if they are linked to your CRM.'
            />
            {navigationRow}
          </div>
        ),
    },
    {
      key: 'done',
      title: <B>Well done!</B>,
      description:
        step !== 7 ? null : (
          <div>
            <P>
              You&apos;ve completed this guide! We hope you&apos;ve enjoyed it
              and that you&apos;re excited to start using voam. A few last
              remarks:
            </P>
            <ul>
              <li>
                We create custom templates for our customers, just reach out to
                us via <a href='mailto:support@voam.io'>support@voam.io</a>.
              </li>
              <li>
                If you want your voams to be sent to other tools or databases
                you&apos;re using, let us know, so we can prioritize the
                integrations that matter to you.
              </li>
              <li>
                If you have other suggestions or feedback, we would love to hear
                it! Please share via{' '}
                <a href='mailto:feedback@voam.io'>feedback@voam.io</a>.
              </li>
            </ul>
            <P>That&apos;s it, happy voaming!</P>
            <P3>
              Mark this guide as completed to not see it again. You can always
              summon it using the help button in the top right.
            </P3>
            {navigationRow}
          </div>
        ),
    },
  ];
  return (
    <GuideContainer>
      <h4 style={{margin: 0}}>Getting started</h4>
      <Divider orientationMargin={4} />
      <Steps current={step} direction='vertical' items={items} size='small' />
    </GuideContainer>
  );
};

export default GettingStarted;

import {useState} from 'react';
import {FormResponseWithPreview, QuestionFilter} from '../types/search.types';
import SearchService from '../services/search.service';

interface useFormResponseSearchInterface {
  formId: string;
  keywords: string[];
  filters: QuestionFilter[];
  limit: number;
}

interface useFormResponseSearchReturn {
  responses: FormResponseWithPreview[];
  isLoading: boolean;
  error: string | null;
  search: () => void;
  next: () => void;
}

export default function useFormResponseSearch({
  formId,
  keywords,
  filters,
  limit,
}: useFormResponseSearchInterface): useFormResponseSearchReturn {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responses, setResponses] = useState<FormResponseWithPreview[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [skip, setSkip] = useState<number>(0);
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);

  const fetchResponses = async () => {
    setIsLoading(true);
    setSkip(0);
    setReachedEnd(false);
    setResponses([]);
    const search_result = await SearchService.searchFormResponses({
      formId,
      keywords,
      filters,
      limit,
      skip: 0,
    });
    if (search_result.error) {
      setError(search_result.error);
    } else {
      setSkip(search_result.data.length);
      setResponses(search_result.data);
      setError(null);
      if (search_result.data.length < limit) {
        setReachedEnd(true);
      }
    }
    setIsLoading(false);
  };

  const fetchNextResponses = async () => {
    if (reachedEnd) {
      return;
    }
    setIsLoading(true);
    const search_result = await SearchService.searchFormResponses({
      formId,
      keywords,
      filters,
      limit,
      skip,
    });
    if (search_result.error) {
      setError(search_result.error);
    } else {
      setSkip(skip + search_result.data.length);
      setResponses(responses.concat(search_result.data));
      setError(null);
      if (search_result.data.length < limit) {
        setReachedEnd(true);
      }
    }
    setIsLoading(false);
  };

  return {
    responses,
    isLoading,
    error,
    search: fetchResponses,
    next: fetchNextResponses,
  };
}

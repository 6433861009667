import React from 'react';
import {Row, Col, ConfigProvider} from 'antd';
import {P} from '../../common/styles';
import {Background, StyledHeaderRow} from './styles';
import {SvgIcon} from '../../common/Image';
import {ThemeProvider} from 'styled-components';
import {darkVoamTheme} from '../../styles/themes';
import SignupForm from '../../components/SignupForm';

const Signup = () => {
  return (
    <ThemeProvider theme={darkVoamTheme}>
      <ConfigProvider theme={darkVoamTheme}>
        <Background>
          <StyledHeaderRow justify='space-between' align='middle'>
            <Col xs={10} sm={8} lg={6}>
              <SvgIcon
                src='svg/logo_color_white.svg'
                width='120px'
                height='auto'
              />
            </Col>
            <Col xs={10} sm={8} lg={6}>
              <span>
                <P>Need help?</P>
                <a href='mailto:support@voam.io'>support@voam.io</a>
              </span>
            </Col>
          </StyledHeaderRow>
          <Row justify='center' align='middle'>
            <Col span={24}>
              <SignupForm />
            </Col>
          </Row>
        </Background>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default Signup;

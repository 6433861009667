import useSWR, {KeyedMutator} from 'swr';
import StreamsService from '../services/streams.service';
import {Stream} from '../types/streams.types';

interface useStreamReturn {
  isLoading: boolean;
  stream?: Stream;
  mutate: KeyedMutator<Stream>;
  error?: Error;
}

export default function useStream(streamId: string): useStreamReturn {
  const {
    data: stream,
    mutate,
    error,
  } = useSWR(
    process.env.REACT_APP_STREAMS_ENDPOINT! + streamId,
    StreamsService.getStream,
  );
  const isLoading = !stream && !error;
  return {
    isLoading,
    stream,
    mutate,
    error,
  };
}

import API from './api';
import {
  ChargebeePortalSession,
  BillingData,
  ChargebeeHostedPage,
} from '../types/billing.types';

async function getPortalSession(
  onError?: (detail?: string) => void,
): Promise<ChargebeePortalSession> {
  return await API.get(process.env.REACT_APP_BILLING_ENDPOINT! + 'portal')
    .then(res => res.data)
    .catch(error => {
      if (onError) {
        onError(error.response?.data.detail);
      }
    });
}

async function getBillingData(url: string): Promise<BillingData> {
  return await API.get(url).then(res => res.data);
}

async function createCheckoutExisting(
  onError?: (detail?: string) => void,
): Promise<ChargebeeHostedPage> {
  return await API.post(
    process.env.REACT_APP_BILLING_ENDPOINT! + 'subscription/checkout',
  )
    .then(res => res.data)
    .catch(error => {
      if (onError) {
        onError(error.response?.data.detail);
      }
    });
}

async function createCheckoutUpgrade(
  onError?: (detail?: string) => void,
): Promise<ChargebeeHostedPage> {
  return await API.post(
    process.env.REACT_APP_BILLING_ENDPOINT! + 'subscription/upgrade',
  )
    .then(res => res.data)
    .catch(error => {
      if (onError) {
        onError(error.response?.data.detail);
      }
    });
}

const BillingService = {
  getPortalSession,
  getBillingData,
  createCheckoutExisting,
  createCheckoutUpgrade,
};

export default BillingService;

import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Badge, Tabs, Descriptions, DescriptionsProps, TabsProps} from 'antd';
import {TaskRun, TranscribedSegment} from '../../types/runs.types';
import {FormResponse} from '../../types/formless.types';
import useVoams from '../../data/use-voams';
import {
  FormFillingTask,
  TranscriptionTask,
  CompletionTask,
} from '../../types/streams.types';
import useForm from '../../data/use-form';
import FormResponseContentView from '../FormResponseContentView';
import TranscriptionViewer from '../TranscriptionViewer';
import {ElevatedCard, P3, RoundedButton} from '../../common/styles';
import {TextOutput} from './styles';
import {getFormHeaders, getObjectTypeMap} from '../../data/utils';
import useUser from '../../data/use-user';

interface TaskRunViewerProps {
  taskRun?: TaskRun;
  task?: TranscriptionTask | CompletionTask | FormFillingTask;
}

const TaskRunViewer = ({taskRun, task}: TaskRunViewerProps) => {
  const navigate = useNavigate();
  const {user} = useUser();
  const {mutate: mutateVoams} = useVoams();
  const formId =
    task && task.taskType === 'FORM_FILLING' && task.formId
      ? task.formId
      : undefined;
  const {form} = useForm(formId);
  const response =
    formId && taskRun && taskRun.output && typeof taskRun.output === 'object'
      ? (taskRun.output as FormResponse)
      : undefined;
  const formHeaderMap = getFormHeaders(form);
  const objectTypeMap = form ? getObjectTypeMap(form) : {};

  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions;
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  } as Intl.DateTimeFormatOptions;
  const startDatetime =
    taskRun && taskRun.startDatetime
      ? new Date(taskRun.startDatetime)
      : undefined;
  const endDatetime =
    taskRun && taskRun.endDatetime ? new Date(taskRun.endDatetime) : undefined;
  const startDatetimeString = startDatetime
    ? startDatetime.toLocaleString([], dateOptions) +
      ' ' +
      startDatetime.toLocaleTimeString([], timeOptions)
    : undefined;
  const endDatetimeString = endDatetime
    ? endDatetime.toLocaleString([], dateOptions) +
      ' ' +
      endDatetime.toLocaleTimeString([], timeOptions)
    : undefined;

  const detailItems: DescriptionsProps['items'] = [
    {
      key: 'taskid',
      label: 'Task ID',
      children: task ? task._id : taskRun ? taskRun.taskId : undefined,
    },
    {
      key: 'id',
      label: 'Run ID',
      children: taskRun ? taskRun._id : undefined,
    },
    {
      key: 'type',
      label: 'Task type',
      children: task ? task.taskType : taskRun ? taskRun.taskType : undefined,
    },
    {
      key: 'status',
      label: 'Status',
      children: !taskRun ? undefined : taskRun.status === 'SUCCESS' ? (
        <Badge status='success' text='Success' />
      ) : taskRun.status === 'FAILED' ? (
        <Badge status='error' text='Failed' />
      ) : taskRun.status === 'RUNNING' ? (
        <Badge status='processing' text='Running' />
      ) : (
        <Badge status='default' text={taskRun.status.toLowerCase()} />
      ),
    },
    {
      key: 'start-time',
      label: 'Start time',
      children: startDatetimeString,
    },
    {
      key: 'end-time',
      label: 'End time',
      children: endDatetimeString,
    },
    {
      key: 'duration',
      label: 'Duration',
      children:
        taskRun && taskRun.processingTime
          ? taskRun.processingTime + 's'
          : undefined,
    },
    {
      key: 'failure-reason',
      label: 'Failure reason',
      children: taskRun && taskRun.failReason ? taskRun.failReason : undefined,
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: 'details',
      label: 'Run details',
      children: <Descriptions size='small' items={detailItems} column={2} />,
    },
    {
      key: 'output',
      label: 'Output',
      children:
        taskRun && taskRun.output ? (
          typeof taskRun.output === 'string' ? (
            <ElevatedCard $notClickable>
              <TextOutput>{taskRun && taskRun.output}</TextOutput>
            </ElevatedCard>
          ) : taskRun.taskType === 'FORM_FILLING' &&
            form &&
            response &&
            formHeaderMap &&
            objectTypeMap ? (
            <FormResponseContentView
              response={response}
              headerMap={formHeaderMap}
              objectTypeMap={objectTypeMap}
            />
          ) : taskRun.taskType === 'TRANSCRIPTION' &&
            typeof taskRun.output === 'object' ? (
            <TranscriptionViewer
              segments={taskRun.output as TranscribedSegment[]}
            />
          ) : (
            <P3>No output available</P3>
          )
        ) : (
          <P3>No output available</P3>
        ),
    },
  ];
  if (user && user.isSuperuser) {
    items.push({
      key: 'raw',
      label: 'Raw',
      children:
        taskRun && taskRun.rawOutput ? (
          typeof taskRun.rawOutput === 'object' ? (
            <ElevatedCard $notClickable>
              <TextOutput>
                {taskRun && JSON.stringify(taskRun.rawOutput, null, 2)}
              </TextOutput>
            </ElevatedCard>
          ) : typeof taskRun.rawOutput === 'string' ? (
            <ElevatedCard $notClickable>
              <TextOutput>{taskRun && taskRun.rawOutput}</TextOutput>
            </ElevatedCard>
          ) : undefined
        ) : undefined,
    });
  }

  const handleOpenInEditor = () => {
    if (taskRun && taskRun.outputId) {
      mutateVoams().then(() => {
        navigate('/voams', {state: {voamId: taskRun.outputId}});
      });
    }
  };

  return (
    <Tabs
      defaultActiveKey='details'
      items={items}
      tabBarExtraContent={
        form &&
        response && (
          <RoundedButton onClick={handleOpenInEditor} height='35px'>
            Open in editor
          </RoundedButton>
        )
      }
    />
  );
};

export default TaskRunViewer;

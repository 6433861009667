import React from 'react';
import {checkmarkCircleOutline} from 'ionicons/icons';
import {theme} from 'antd';
import {CapsuleTag, TagIonIcon} from '../styles';

export interface SubmitStatusTagProps {
  isSubmitted: boolean;
}

const SubmitStatusTag = ({isSubmitted}: SubmitStatusTagProps) => {
  const {token} = theme.useToken();
  return (
    <CapsuleTag
      bordered={false}
      icon={
        isSubmitted ? (
          <TagIonIcon icon={checkmarkCircleOutline} color='white' />
        ) : null
      }
      color={isSubmitted ? token.colorPrimary : token.colorError}
    >
      {isSubmitted ? 'submitted' : 'in progress'}
    </CapsuleTag>
  );
};

export default SubmitStatusTag;

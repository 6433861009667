import React from 'react';
import {StyledTag} from './styles';

export interface DeletableTagProps {
  label: string;
  onDelete: (key: string) => void;
}

const DeletableTag = ({label, onDelete}: DeletableTagProps) => {
  return (
    <StyledTag closable={true} onClose={() => onDelete(label)} bordered={true}>
      {label}
    </StyledTag>
  );
};

export default DeletableTag;

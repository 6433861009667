import useSWR from 'swr';
import {htmlToText} from 'html-to-text';
import IntegrationService from '../services/integrations.service';
import {Meeting} from '../types/integrations.types';

export default function useMeetings() {
  const {data, mutate, error} = useSWR(
    process.env.REACT_APP_MEETINGS_ENDPOINT!,
    IntegrationService.getMeetings,
  );
  const isLoading = !data && !error;

  if (data) {
    data.sort((a: Meeting, b: Meeting) => {
      return a.startTime.valueOf() < b.startTime.valueOf() ? -1 : 1;
    });
  }

  if (data) {
    data.forEach((meeting: Meeting) => {
      meeting.title = meeting.title
        ? htmlToText(meeting.title)
        : 'Untitled meeting';
      meeting.description = meeting.description
        ? htmlToText(meeting.description)
        : 'No meeting description';
      meeting.startTime = new Date(meeting.startTime);
      meeting.endTime = new Date(meeting.endTime);
    });
  }

  return {
    isLoading,
    meetings: data,
    mutate,
    error,
  };
}

import styled from 'styled-components';

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0 10px;
`;

export const Label = styled.span`
  font-size: 0.8rem;
  margin-right: 5px;
  color: ${props => props.theme.token.colorText};
`;

import API from './api';
import {StreamRun, StreamRunRead, TaskRun} from '../types/runs.types';

async function getRun(url: string): Promise<TaskRun | StreamRunRead> {
  return await API.get(url).then(res => res.data);
}

async function getRuns(url: string): Promise<Array<TaskRun | StreamRun>> {
  return await API.get(url).then(res => res.data);
}

function getStreamRunEvents(
  streamRunId: string,
  onOpen?: () => void,
  onError?: (event: MessageEvent) => void,
  onMessage?: (event: MessageEvent) => void,
  onAbort?: () => void,
): EventSource | null {
  try {
    const eventSource = new EventSource(
      process.env.REACT_APP_API_URL +
        process.env.REACT_APP_RUNS_ENDPOINT! +
        streamRunId +
        '/events',
      {
        withCredentials: true,
      },
    );

    if (onOpen) {
      eventSource.addEventListener('open', onOpen);
    }
    if (onError) {
      eventSource.addEventListener('error', onError);
    }
    if (onMessage) {
      eventSource.addEventListener('message', onMessage);
    }
    if (onAbort) {
      eventSource.addEventListener('abort', onAbort);
    }

    return eventSource;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const retryStreamRun = async (streamRunId: string): Promise<boolean> => {
  try {
    const response = await API.post(
      process.env.REACT_APP_RUNS_ENDPOINT! + streamRunId + '/retry',
    );
    if (response.status !== 201) {
      console.error(response);
      return false;
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const RunsService = {
  getRun,
  getRuns,
  getStreamRunEvents,
  retryStreamRun,
};

export default RunsService;

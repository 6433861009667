import {Button} from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)<{color?: string}>`
  background: ${p =>
    p.color ||
    'linear-gradient(45deg, rgba(9,200,149,1) 0%, rgba(9,200,189,1) 100%)'};
  color: ${p => (p.color ? '#09C8A9' : '#fff')};
  font-size: 1rem;
  width: 100%;
  border: 1px solid ${p => (p.color ? '#edf3f5' : '#09C8A9')};
  border-radius: 25px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);
  max-width: 200px;
  min-width: 120px;

  @media only screen and (max-width: 576px) {
    max-width: 100%;
    min-width: none;
  }

  @media only screen and (max-width: 414px) {
    font-size: 0.9rem;
    max-width: 100%;
    min-width: none;
  }

  &:hover &:!disabled {
    scale: 1.05;
  }

  &:disabled {
    background: ${p =>
      p.color ||
      'linear-gradient(45deg, rgba(9,200,149,0.6) 0%, rgba(9,200,189,0.6) 100%)'};
    border: none;
  }
`;

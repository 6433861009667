import React from 'react';
import LastUpdatedTag from '../../common/LastUpdatedTag';
import LinkedTag from '../../common/LinkedTag';
import SubmitStatusTag from '../../common/SubmitStatusTag';
import {getBlockText} from '../../data/utils';
import {Note} from '../../types/notes.types';
import {Space} from 'antd';
import {
  StyledCardTitle,
  StyledTextPreviewContainer,
  StyledCard,
  VoamTypeLabel,
} from './styles';

export interface NoteCardInterface {
  note: Note;
  selected?: boolean;
}

const NoteCard = ({note, selected}: NoteCardInterface) => {
  const title =
    note.blocks.length > 0 ? getBlockText(note.blocks[0]) : 'Untitled';
  const body =
    note.blocks.length > 1 ? getBlockText(note.blocks[1]) : 'Empty note.';
  const hasLinks = note.linkedObjects && note.linkedObjects.length > 0;
  return (
    <StyledCard selected={selected}>
      <VoamTypeLabel>Note</VoamTypeLabel>
      <StyledCardTitle>{title}</StyledCardTitle>
      <Space size={[0, 4]} style={{padding: '0.2rem 0rem'}} wrap>
        <LastUpdatedTag lastUpdated={note.lastUpdated} />
        {hasLinks && <LinkedTag type={note.linkedObjects[0].type} />}
        {hasLinks && <SubmitStatusTag isSubmitted={note.submitted} />}
      </Space>
      <StyledTextPreviewContainer>{body}</StyledTextPreviewContainer>
    </StyledCard>
  );
};

export default NoteCard;

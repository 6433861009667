import API from './api';
import {Stream} from '../types/streams.types';

async function getStreams(url: string): Promise<Array<Stream>> {
  return await API.get(url).then(res => res.data);
}

async function getStream(url: string): Promise<Stream> {
  return await API.get(url).then(res => res.data);
}

async function createStreamRunFromAudio(
  streamId: string,
  file: File,
  params: Record<string, string>,
): Promise<string | null> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    });

    const response = await API.post(
      process.env.REACT_APP_STREAMS_ENDPOINT! + streamId + '/runs/from-audio',
      formData,
    );
    if (response.status !== 201) {
      console.error(response);
      return null;
    }
    return response.data.streamRunId;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const StreamsService = {
  getStreams,
  getStream,
  createStreamRunFromAudio,
};

export default StreamsService;

export const lightVoamTheme = {
  token: {
    colorBgContainer: '#FFFFFF',
    colorBgElevated: '#FAFAF9',
    colorFillAlter: '#F2F2F3',
    colorFillHover: '#F2F2F3',
    colorBgTextHover: '#F2B635',
    colorBgLayout: '#ffffff',
    colorText: '#141123',
    colorTextSecondary: '#55535F',
    colorTextTertiary: '#B0ADB6',
    colorTextQuaternary: '#B0ADB6',
    colorPrimary: '#09C8A9',
    colorPrimaryHover: '#09C8A9',
    colorPrimaryBorder: '#09C8A9',
    colorPrimaryActive: '#09C8A9',
    colorBorder: '#B0ADB6',
    colorSuccess: '#09C8A9',
    colorInfo: '#F2B635',
    colorError: '#E07A5F',
    colorSplit: '#F2F2F3',
    borderRadius: 8,
    borderRadiusLG: 8,
    borderRadiusSM: 4,
    fontFamily: 'LibreFranklin Regular',
    colorTextPlaceholder: '#B0ADB6',
  },
  components: {
    Layout: {
      colorText: '#B0ADB6',
      headerBg: '#141123',
      triggerBg: '#1D1A2A',
    },
    Menu: {
      itemColor: '#B0ADB6',
      itemHoverColor: '#ffffff',
      itemSelectedBg: '#211E31',
      itemSelectedColor: '#ffffff',
      itemHoverBg: '#1D1A2A',
    },
  },
};

export const darkVoamTheme = {
  token: {
    colorBgContainer: '#141123',
    colorBgElevated: '#1D1A2A',
    colorFillAlter: '#1D1A2A',
    colorFillHover: '#211E31',
    colorBgTextHover: '#F2B635',
    colorBgLayout: '#141123',
    colorText: '#FFFFFF',
    colorTextSecondary: '#F2F2F3',
    colorTextTertiary: '#B0ADB6',
    colorTextQuaternary: '#B0ADB6',
    colorPrimary: '#09C8A9',
    colorPrimaryHover: '#09C8A9',
    colorPrimaryBorder: '#09C8A9',
    colorPrimaryActive: '#09C8A9',
    colorSuccess: '#09C8A9',
    colorInfo: '#F2B635',
    colorError: '#E07A5F',
    colorSplit: '#211E31',
    borderRadius: 8,
    borderRadiusLG: 8,
    borderRadiusSM: 4,
    fontFamily: 'LibreFranklin Regular',
    colorTextPlaceholder: '#B0ADB6',
  },
  components: {
    Layout: {
      colorText: '#B0ADB6',
      headerBg: '#141123',
      triggerBg: '#1D1A2A',
    },
    Menu: {
      itemColor: '#B0ADB6',
      itemHoverColor: '#ffffff',
      itemSelectedBg: '#211E31',
      itemSelectedColor: '#ffffff',
      itemHoverBg: '#1D1A2A',
    },
    Select: {
      optionSelectedBg: '#211E31',
    },
  },
};

import {DataObject} from '../types/objects.types';
import API from './api';

async function getObjectTypes(url: string): Promise<string[]> {
  return await API.get(url).then(res => res.data);
}

async function getObjects(url: string): Promise<DataObject[]> {
  return await API.get(url).then(res => res.data);
}

async function getObject(url: string): Promise<DataObject> {
  return await API.get(url).then(res => res.data);
}

const ObjectService = {
  getObjectTypes,
  getObjects,
  getObject,
};

export default ObjectService;

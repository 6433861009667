import useSWR from 'swr';
import ObjectService from '../services/object.service';
import {DataObject} from '../types/objects.types';

interface UseObjectProps {
  objectType?: string;
  objectId?: string | null;
}

export default function useObject({objectType, objectId}: UseObjectProps) {
  const {data, isLoading, error} = useSWR<DataObject, Error>(
    objectType && objectId
      ? process.env.REACT_APP_OBJECTS_ENDPOINT! + objectType + '/' + objectId
      : null,
    ObjectService.getObject,
  );

  return {
    data,
    isLoading,
    error,
  };
}

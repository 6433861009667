import React from 'react';
import {Col, Row} from 'antd';
import {B} from '../../../common/styles';
import {BoolButton, QuestionContainer} from '../styles';
import {QuestionViewInterface} from '../index';

const BoolQuestionView = ({
  question,
  questionResponse,
  onChange,
}: QuestionViewInterface) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onTrueClick = (e: unknown) => {
    if (questionResponse.answer === true) {
      questionResponse.answer = null;
    } else {
      questionResponse.answer = true;
    }
    onChange?.();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFalseClick = (e: unknown) => {
    if (questionResponse.answer === false) {
      questionResponse.answer = null;
    } else {
      questionResponse.answer = false;
    }
    onChange?.();
  };

  return (
    <QuestionContainer>
      <B>{question.prompt}</B>
      <Row justify='space-evenly'>
        <Col span={10}>
          <BoolButton
            type={questionResponse.answer === false ? 'primary' : 'default'}
            danger={questionResponse.answer === false}
            size='large'
            shape='round'
            onClick={onFalseClick}
            style={{width: '100%'}}
          >
            No
          </BoolButton>
        </Col>
        <Col span={10}>
          <BoolButton
            type={questionResponse.answer === true ? 'primary' : 'default'}
            size='large'
            shape='round'
            onClick={onTrueClick}
            style={{width: '100%'}}
          >
            Yes
          </BoolButton>
        </Col>
      </Row>
    </QuestionContainer>
  );
};

export default BoolQuestionView;

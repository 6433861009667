import {useEffect, useState} from 'react';

async function requestRecorder(): Promise<MediaRecorder> {
  const stream = await navigator.mediaDevices.getUserMedia({audio: true});
  const mimeTypes = ['audio/webm', 'audio/mp4'];
  const chosenMimeType = mimeTypes.find(option =>
    MediaRecorder.isTypeSupported(option),
  );
  if (!chosenMimeType) {
    throw new Error('No supported MIME type found for MediaRecorder');
  }
  const options = {
    mimeType: chosenMimeType,
  };
  return new MediaRecorder(stream, options);
}

interface useRecorderInterface {
  audioURL: string;
  audioFile: File | undefined;
  isRecording: boolean;
  startRecording: () => void;
  stopRecording: () => void;
}

const useRecorder = (): useRecorderInterface => {
  const [audioURL, setAudioURL] = useState<string>('');
  const [audioFile, setAudioFile] = useState<File>();
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = (e: BlobEvent) => {
      setAudioURL(URL.createObjectURL(e.data));
      const audioChunks: Blob[] = [];
      audioChunks.push(e.data);
      const fileName =
        e.data.type === 'audio/webm' ? 'audio.webm' : 'audio.m4a';
      setAudioFile(new File(audioChunks, fileName, {type: e.data.type}));
    };

    recorder.addEventListener('dataavailable', handleData);
    return () => recorder.removeEventListener('dataavailable', handleData);
  }, [recorder, isRecording]);

  const startRecording = (): void => {
    setAudioFile(undefined);
    setIsRecording(true);
  };

  const stopRecording = (): void => {
    setIsRecording(false);
  };

  return {
    audioURL,
    audioFile: audioFile!,
    isRecording,
    startRecording,
    stopRecording,
  };
};

export default useRecorder;

import {Card} from 'antd';
import styled from 'styled-components';
import {H5, P3} from '../../common/styles';

export const StyledCard = styled(Card)<{selected?: boolean}>`
  flex: 1;
  width: 100%;
  padding: 0;
  transition: all 0.2s ease-in-out;
  border: none;
  background-color: ${props =>
    props.selected ? props.theme.token.colorFillHover : 'transparent'};

  &:hover {
    background-color: ${props => props.theme.token.colorFillAlter};
    cursor: pointer;
  }
  .ant-card-body {
    padding: 0.8rem;
  }
`;

export const StyledCardTitle = styled(H5)`
  font-size: 1rem;
  margin: 0;
`;

export const StyledTextPreviewContainer = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1rem;
  max-height: 2rem;

  p {
    margin-bottom: 0;
    font-size: 0.8rem;
  }
`;

export const StyledSubmittedCapsule = styled('div')``;

export const VoamTypeLabel = styled(P3)`
  font-size: 11px;
  font-family: 'LibreFranklin Medium';
  font-weight: 500;
`;

import React from 'react';
import {
  FormResponse,
  Form,
  FormTopicResponse,
} from '../../types/formless.types';
import FormlessTopicView from '../FormlessTopicView';
import {theme} from 'antd';
import type {CollapseProps} from 'antd/lib/collapse';
import {StyledCollapse} from './styles';

export interface FormlessViewInterface {
  formResponse: FormResponse;
  form: Form;
  startCollapsed?: boolean;
  onChange?: () => void;
}

const FormlessView = ({
  formResponse,
  form,
  startCollapsed,
  onChange,
}: FormlessViewInterface) => {
  const {token} = theme.useToken();

  const topicResponses = new Map<string, FormTopicResponse>();
  formResponse.topics.forEach(topic => {
    topicResponses.set(topic.name, topic);
  });

  const panelStyle = {
    marginBottom: 12,
    border: 'none',
    borderRadius: token.borderRadiusLG,
  };

  const items: CollapseProps['items'] = form.topics.map(topic => {
    return {
      label: topic.prompt,
      key: topic.name,
      children: (
        <FormlessTopicView
          topic={topic}
          topicResponse={topicResponses.get(topic.name)!}
          onChange={onChange}
        />
      ),
      style: panelStyle,
    };
  });

  return (
    <>
      <StyledCollapse
        size='large'
        bordered={false}
        items={items}
        defaultActiveKey={
          startCollapsed ? [] : form.topics.map(topic => topic.name)
        }
      />
    </>
  );
};

export default FormlessView;

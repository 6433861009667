import styled from 'styled-components';
import {Space} from 'antd';

export const AudioUploadSpace = styled(Space)`
  .ant-upload-drag {
    background-color: ${props => props.theme.token.colorBgElevated};
    border: 1px dashed ${props => props.theme.token.colorTextTertiary};
    font-family: 'LibreFranklin Regular';
    font-weight: 400;
    p {
      color: ${props => props.theme.token.colorTextSecondary};
    }
    &:hover {
      border: 1px solid ${props => props.theme.token.colorPrimary};
    }
  }
  width: 100%;
`;

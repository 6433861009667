import React from 'react';
import {theme} from 'antd';
import {Activity} from '../../types/integrations.types';
import {Link} from 'react-ionicons';
import {StyledCard, StyledRow, StyledTitle, StyledSubtitle} from './styles';
import {capitalize} from '../../data/utils';

interface LinkedItemProps {
  activity?: Activity;
  chevron?: boolean;
  onClick?: () => void;
}

const LinkedItem = ({activity, onClick}: LinkedItemProps) => {
  const {token} = theme.useToken();
  const color = token.colorInfo;
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
  } as Intl.DateTimeFormatOptions;
  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions;
  const dateTimeString =
    activity &&
    activity.startTime &&
    `${activity.startTime.toLocaleDateString(
      [],
      dateOptions,
    )} at ${activity.startTime.toLocaleTimeString([], timeOptions)}`;

  return (
    <StyledCard onClick={onClick}>
      <StyledRow wrap={false} align='middle'>
        <Link
          width='20px'
          height='20px'
          color={color}
          style={{marginRight: 5}}
        />
        <StyledTitle>{activity ? activity.title : 'Linked item'}</StyledTitle>
      </StyledRow>
      <StyledRow wrap={false}>
        <StyledSubtitle>
          {activity
            ? `${capitalize(activity.activityType)} - ${dateTimeString}`
            : 'Details unavailable'}
        </StyledSubtitle>
      </StyledRow>
    </StyledCard>
  );
};
export default LinkedItem;

import React, {useEffect, useState} from 'react';
import useUser from '../../data/use-user';
import {Row, Col, Alert, Space, Statistic, theme} from 'antd';
import {P, H2, H5} from '../../common/styles';
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';
import {Container, StyledCard} from './styles';
import LoadingIndicator from '../../components/LoadingIndicator';
import {useNavigate} from 'react-router-dom';
import useWeeklyKPIs from '../../data/use-weekly-kpis';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import Toggle from '../../common/Toggle';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
);

const Admin = () => {
  const {user} = useUser();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const {token} = theme.useToken();
  const [aggregateUsage, setAggregateUsage] = useState<boolean>(true);
  const [aggregateAudioSeconds, setAggregateAudioSeconds] =
    useState<boolean>(true);
  const {
    userChartData,
    usageChartData,
    audioSecondsChartData,
    error,
    isLoading,
  } = useWeeklyKPIs({aggregateUsage, aggregateAudioSeconds});

  useEffect(() => {
    if (error) {
      setErrorMessage('Failed to load weekly KPIs.');
      setTimeout(() => setErrorMessage(''), 15000);
    }
  }, [error]);

  useEffect(() => {
    if (user && !user.isSuperuser) {
      navigate('/home');
    }
  }, [user]);

  if (!(user && user.isSuperuser)) {
    return <P>Forbidden</P>;
  }

  const previousWeeklyActiveUsers =
    userChartData?.datasets[0].data[userChartData.datasets[0].data.length - 2];
  const weeklyActiveUsers =
    userChartData?.datasets[0].data[userChartData.datasets[0].data.length - 1];
  const weeklyActiveUsersChange =
    weeklyActiveUsers -
    userChartData?.datasets[0].data[userChartData.datasets[0].data.length - 2];
  const weeklyActiveUsersChangePercentage =
    (weeklyActiveUsersChange / previousWeeklyActiveUsers) * 100;
  const previousWeeklySignups =
    userChartData?.datasets[1].data[userChartData.datasets[1].data.length - 2];
  const weeklySignups =
    userChartData?.datasets[1].data[userChartData.datasets[1].data.length - 1];
  const weeklySignupsChange =
    weeklySignups -
    userChartData?.datasets[1].data[userChartData.datasets[1].data.length - 2];
  const weeklySignupsChangePercentage =
    (weeklySignupsChange / previousWeeklySignups) * 100;

  return (
    <Container>
      <Row justify='start'>
        <H2>Admin</H2>
      </Row>
      {error && (
        <Alert
          type='error'
          message={errorMessage}
          closable
          style={{width: '100%'}}
        />
      )}
      <Row justify='start' style={{width: '100%', marginTop: 30}} gutter={20}>
        <Col span={24}>
          <StyledCard title={<H5>Users</H5>}>
            {isLoading && <LoadingIndicator message='Loading...' />}
            {userChartData && (
              <Space size='large'>
                <Statistic
                  title='Weekly Active Users'
                  value={weeklyActiveUsers}
                />
                <Statistic
                  title='Change'
                  value={weeklyActiveUsersChangePercentage}
                  precision={1}
                  valueStyle={{
                    color:
                      weeklyActiveUsersChange > 0
                        ? token.colorPrimary
                        : token.colorError,
                  }}
                  prefix={
                    weeklyActiveUsersChange > 0 ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )
                  }
                  suffix='%'
                />
                <Statistic title='Weekly Signups' value={weeklySignups} />
                <Statistic
                  title='Change'
                  value={weeklySignupsChangePercentage}
                  precision={1}
                  valueStyle={{
                    color:
                      weeklySignupsChange > 0
                        ? token.colorPrimary
                        : token.colorError,
                  }}
                  prefix={
                    weeklySignupsChange > 0 ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )
                  }
                  suffix='%'
                />
              </Space>
            )}
            {userChartData && (
              <Line
                data={userChartData}
                options={{scales: {y: {beginAtZero: true}}}}
              />
            )}
          </StyledCard>
        </Col>
        <Col span={24}>
          <StyledCard
            title={<H5>Usage</H5>}
            extra={
              <Toggle
                size='default'
                checked={aggregateUsage}
                onChange={(checked: boolean) => setAggregateUsage(checked)}
                label='Aggregate'
              />
            }
          >
            {isLoading && <LoadingIndicator message='Loading...' />}
            {usageChartData && (
              <Line
                data={usageChartData}
                options={{scales: {y: {beginAtZero: true}}}}
              />
            )}
          </StyledCard>
        </Col>
        <Col span={24}>
          <StyledCard
            title={<H5>Audio</H5>}
            extra={
              <Toggle
                size='default'
                checked={aggregateAudioSeconds}
                onChange={(checked: boolean) =>
                  setAggregateAudioSeconds(checked)
                }
                label='Aggregate'
              />
            }
          >
            {isLoading && <LoadingIndicator message='Loading...' />}
            {audioSecondsChartData && (
              <Line
                data={audioSecondsChartData}
                options={{scales: {y: {beginAtZero: true}}}}
              />
            )}
          </StyledCard>
        </Col>
      </Row>
    </Container>
  );
};

export default Admin;

import React from 'react';
import {StyledViewerContainer} from './styles';

interface ViewerContainerProps {
  children: React.ReactNode;
}

const ViewerContainer = ({children}: ViewerContainerProps) => (
  <StyledViewerContainer>{children}</StyledViewerContainer>
);

export default ViewerContainer;

import {Collapse} from 'antd';
import styled from 'styled-components';

export const StyledCollapse = styled(Collapse)`
  background-color: #ffffff;

  .ant-collapse-header {
    padding: 32px;
    border: 0px;
    border-radius: 8px;
  }

  .ant-collapse-header-text {
    font-family: 'LibreFranklin ExtraBold';
    font-weight: 800;
    font-size: 21px;
  }

  .ant-collapse-content-box {
    font-family: 'LibreFranklin Regular';
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 4px;
  }

  b {
    font-size: 16px;
  }

  textarea {
    min-height: 16px;
  }
`;

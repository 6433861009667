import React from 'react';
import {Form} from 'antd';
import {User} from '../../../types/users.types';
import {Organization} from '../../../types/orgs.types';
import {VoamSolution} from '../../../types/voams.types';
import {
  FormTitle,
  Instructions,
  ErrorMessage,
  InputLabel,
  StyledTextInput,
  ButtonRow,
} from '../styles';
import FancyButton from '../../../common/FancyButton';
import AuthService from '../../../services/auth.service';
import LoadingIndicator from '../../LoadingIndicator';

interface ChoosePasswordProps {
  email: string;
  firstName: string;
  lastName: string;
  org: Organization;
  agreedToTerms: boolean;
  solutions: VoamSolution[];
  onCompletion: (user: User) => void;
}

const ChoosePassword = ({
  email,
  firstName,
  lastName,
  org,
  agreedToTerms,
  solutions,
  onCompletion,
}: ChoosePasswordProps) => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const submitable = password.length > 0 && passwordConfirm.length > 0;

  const handleSubmit = async () => {
    if (password.length < 8) {
      setErrorMessage(
        'This password is too short. Choose a password of at least 8 characters.',
      );
      return;
    }
    if (password.length > 64) {
      setErrorMessage(
        'This password is too long. Choose a password of at most 64 characters.',
      );
      return;
    }
    if (password !== passwordConfirm) {
      setErrorMessage('The passwords do not match. Please try again.');
      return;
    }
    setErrorMessage('');
    setIsLoading(true);
    const user = await AuthService.register({
      email,
      firstName,
      lastName,
      password,
      orgId: org._id,
      solutions,
      agreedToTerms,
    });
    if (user) {
      onCompletion(user);
    } else {
      setErrorMessage('Something went wrong. Please try again.');
    }
    setIsLoading(false);
    setPassword('');
    setPasswordConfirm('');
  };

  return (
    <Form>
      <FormTitle>Choose your password</FormTitle>
      <Instructions>
        Almost there, {firstName}! Choose a password and we can create your
        account.
      </Instructions>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {isLoading && <LoadingIndicator message='Creating account...' />}
      {!isLoading && (
        <>
          <StyledTextInput
            value={email}
            type='email'
            style={{display: 'none'}}
          />
          <InputLabel>Password</InputLabel>
          <StyledTextInput.Password
            className='create-password-input'
            value={password}
            placeholder='Choose a password (8-64 characters)'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
          <InputLabel>Confirm password</InputLabel>
          <StyledTextInput.Password
            className='create-password-input'
            value={passwordConfirm}
            placeholder='Repeat your password'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPasswordConfirm(e.target.value)
            }
          />
        </>
      )}
      <ButtonRow justify='end'>
        <FancyButton disabled={!submitable || isLoading} onClick={handleSubmit}>
          Create account
        </FancyButton>
      </ButtonRow>
    </Form>
  );
};

export default ChoosePassword;

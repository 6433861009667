import useSWR from 'swr';
import UserService from '../services/users.service';
import {User} from '../types/users.types';
import {Organization} from '../types/orgs.types';

export default function useUser(loggedOutRoute?: boolean) {
  const {
    data: user,
    mutate,
    error,
  } = useSWR<User, Error>(
    loggedOutRoute ? null : process.env.REACT_APP_USERS_ME_ENDPOINT!,
    UserService.getUserMe,
  );
  const isLoading = !user && !error;
  const loggedOut =
    error && error.message === 'Request failed with status code 401';
  const {data: org} = useSWR<Organization, Error>(
    user?.orgId ? process.env.REACT_APP_ORGANIZATIONS_ENDPOINT! : null,
    UserService.getMyOrganization,
  );
  return {
    isLoading,
    loggedOut,
    user,
    org,
    mutate,
    error,
  };
}

import {Input, InputNumber, Button, Row} from 'antd';
import styled from 'styled-components';
import {P} from '../../common/styles';

const {TextArea} = Input;

export const TextAnswer = styled(TextArea)`
  background: #ffffff;
  font-size: 16px;
  padding: 0;
  min-height: 16px;
  max-height: 1600px;
  padding-bottom: 0px;
`;

export const NumberAnswer = styled(InputNumber)`
  font-size: 18px;
  border: 1px;
  border-style: solid;
  border-color: #f2f2f3;
  width: 100%;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
`;

export const BoolButton = styled(Button)`
  width: 100%;
  margin-top: 6px;
`;

export const StyledAnswerRow = styled(Row)`
  margin-top: 6px;
  button.ant-btn-default {
    color: #8c8c8c;
  }
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    color: #8c8c8c;
  }
`;

export const StyledRangeHint = styled(P)`
  font-size: 14px;
  font-family: 'LibreFranklin Medium';
  font-weight: 500;
  color: #8c8c8c;
  margin: 0px;
`;

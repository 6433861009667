import styled from "styled-components";

export const StyledVoaminationContainer = styled("div")< { width?: number , height?: number }>`
    position: relative;
    padding: 0.2rem;
    width: ${props => props.width ? props.width + "px" : "100%"};
    height: ${props => props.width ? props.width + "px" : "100%"}
`;

export const StyledPartialImg = styled("img")<{ animate: boolean, delay?: number, upFirst?: boolean }>`
    width: inherit;
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;

    animation: ${props => props.animate ? "opacityAnimation 2s infinite" : "none"};
    animation-direction: ${props => props.upFirst ? "normal" : "reverse"};
    animation-delay: ${props => props.delay}s;

    @keyframes opacityAnimation {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

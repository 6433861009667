import React, {useState} from 'react';
import {Row, Button, Alert, Select} from 'antd';
import {User} from '../../types/users.types';
import nango from '../../services/nango.service';
import LoadingIndicator from '../LoadingIndicator';
import useUser from '../../data/use-user';
import useIntegrations from '../../data/use-integrations';
import IntegrationService from '../../services/integrations.service';

interface ConnectCRMFormProps {
  onCompletion?: () => void;
  preselected?: string;
}

const ConnectCRMForm = ({onCompletion, preselected}: ConnectCRMFormProps) => {
  const {user, mutate} = useUser();
  const {integrations, isLoading: loadingIntegrations} = useIntegrations();
  const [selection, setSelection] = useState<string | undefined>(preselected);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (value: string | undefined) => setSelection(value);

  const handleConnect = () => {
    if (selection && user) {
      setIsLoading(true);
      setError('');
      nango
        .auth(selection, user.id)
        .then(res => {
          if (res) {
            IntegrationService.setConnection(selection, user.id).then(
              (user: User | null) => {
                mutate(user || undefined);
                if (onCompletion) onCompletion();
              },
            );
          } else {
            setError('Failed to connect.');
          }
          setIsLoading(false);
        })
        .catch(err => {
          setError(err?.error?.message ?? 'Failed to connect.');
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {error && <Alert type='error' message={error} closable />}
      <Row
        justify='space-between'
        align='middle'
        gutter={[12, 12]}
        style={{height: 52}}
      >
        {loadingIntegrations && <LoadingIndicator message='Loading...' />}
        {isLoading ? (
          <LoadingIndicator message='Waiting...' />
        ) : (
          <Select
            placeholder='Select your CRM'
            style={{width: 200}}
            onChange={handleChange}
            value={selection}
          >
            {integrations?.map(integration => (
              <Select.Option
                key={integration.integrationId}
                value={integration.integrationId}
              >
                {integration.label}
              </Select.Option>
            ))}
          </Select>
        )}
        <Button type='primary' onClick={handleConnect} disabled={!selection}>
          Connect
        </Button>
      </Row>
    </>
  );
};

export default ConnectCRMForm;

import styled from 'styled-components';

export const SectionHeader = styled.p`
  font-size: 18px;
  font-family: 'LibreFranklin Medium';
  font-weight: 500;
  color: ${props => props.theme.token.colorText};
  padding: 10px 0 5px 0;
  margin: 8px 0 5px;
`;

import React from 'react';
import {Button, Row, theme, Space} from 'antd';
import {LinkedObject} from '../../types/integrations.types';
import {Voam} from '../../types/voams.types';
import {StyledDrawer} from './styles';
import {
  P,
  ColoredCard,
  ErrorText,
  P3,
  P2,
  ElevatedCard,
  H6,
} from '../../common/styles';
import LoadingIndicator from '../LoadingIndicator';
import MeetingList from '../MeetingList';
import ActivityItem from '../MeetingItem';
import {InformationCircle} from 'react-ionicons';
import useActivity from '../../data/use-activity';

interface LinkDrawerProps {
  open: boolean;
  voam: Voam;
  onClose: () => void;
  onLinkChange: (linkedObject: LinkedObject | null) => void;
}

const LinkDrawer = ({open, voam, onClose, onLinkChange}: LinkDrawerProps) => {
  const {token} = theme.useToken();
  const linkedObject = voam.linkedObjects?.[0] || null;

  return (
    <StyledDrawer
      width={500}
      title='Link to a meeting'
      onClose={onClose}
      open={open}
    >
      {linkedObject && (
        <CurrentLink
          linkedObject={linkedObject}
          onUnlink={() => onLinkChange(null)}
          locked={voam.locked}
        />
      )}
      {voam.locked && (
        <Space>
          <InformationCircle color={token.colorInfo} />
          <P>
            This voam has been submitted to the CRM and is now locked for
            editing. Links can no longer be edited.
          </P>
        </Space>
      )}
      {!linkedObject && !voam.locked && !voam.submitted && (
        <P3 style={{marginTop: 12}}>
          Choose a meeting to link this voam to. When you submit, the meeting
          notes in your CRM will be replaced with the contents of the voam.
        </P3>
      )}
      {linkedObject && !voam.locked && !voam.submitted && (
        <>
          <H6>Change link</H6>
          <P3>
            Change the linked activity by creating a new one or selecting one
            from the list.
          </P3>
        </>
      )}
      {!voam.locked && <MeetingList onClick={onLinkChange} />}
    </StyledDrawer>
  );
};

const CurrentLink = ({
  linkedObject,
  onUnlink,
  locked,
}: {
  linkedObject: LinkedObject;
  onUnlink: () => void;
  locked: boolean;
}) => {
  const {activity, error, isLoading} = useActivity({
    activityId: linkedObject?.id,
    activityType: linkedObject?.type,
    integrationId: linkedObject?.integrationId,
  });
  const {token} = theme.useToken();
  return (
    <ElevatedCard $notClickable style={{marginTop: 12, marginBottom: 24}}>
      <Space direction='vertical' size='middle'>
        {linkedObject && (
          <P2>This voam is linked to the following activity.</P2>
        )}
        {isLoading && (
          <LoadingIndicator message='Fetching linked activity...' />
        )}
        {error && (
          <ColoredCard color={token.colorError} $notClickable>
            <ErrorText>
              Activity details unavailable. Does it still exist in your CRM?
            </ErrorText>
          </ColoredCard>
        )}
        {activity && <ActivityItem activity={activity} />}
        {(activity || error) && !locked && (
          <Row>
            <Button type='text' danger size='large' onClick={onUnlink}>
              Remove link
            </Button>
          </Row>
        )}
      </Space>
    </ElevatedCard>
  );
};

export default LinkDrawer;

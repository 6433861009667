import useSWR from 'swr';
import StatsService from '../services/stats.service';

interface WeeklyKPIsProps {
  aggregateUsage?: boolean;
  aggregateAudioSeconds?: boolean;
}

export default function useWeeklyKPIs({
  aggregateUsage,
  aggregateAudioSeconds,
}: WeeklyKPIsProps = {}) {
  const {data, mutate, error} = useSWR(
    process.env.REACT_APP_STATS_ENDPOINT! + 'weekly',
    StatsService.getWeeklyKPIs,
  );

  const isLoading = !data && !error;

  if (data) {
    data.startDate = new Date(data.startDate);
    data.endDate = new Date(data.endDate);
  }

  // Sort by year and weekNr.
  // const weeklyActiveUsers = data?.weeklyActiveUsers.sort(
  //   (a, b) => a.year - b.year || a.weekNr - b.weekNr,
  // );
  // const weeklySignups = data?.weeklySignups.sort(
  //   (a, b) => a.year - b.year || a.weekNr - b.weekNr,
  // );

  const labels =
    data?.weeklyActiveUsers?.map(wau => `${wau.year} wk${wau.weekNr}`) || [];

  const activeUsersDataset = {
    label: 'Active Users',
    data: data?.weeklyActiveUsers?.map(wau => wau.activeUsers) || [],
  };
  const signupsDataset = {
    label: 'Signups',
    data: data?.weeklySignups?.map(ws => ws.signups) || [],
  };
  const datasets = [activeUsersDataset, signupsDataset];
  const userChartData = {labels, datasets};

  // Find unique endpoints.
  const actions = data?.weeklyUsage.map(wu => wu.endpoint);
  const uniqueActions = new Set(actions);
  const uniqueActionsArray = Array.from(uniqueActions);

  // Create a dataset for each unique endpoint.
  let usageDatasets;
  if (!aggregateUsage) {
    usageDatasets = uniqueActionsArray.map(endpoint => {
      const filteredData =
        data?.weeklyUsage
          .filter(wu => wu.endpoint === endpoint)
          .sort((a, b) => a.year - b.year || a.weekNr - b.weekNr)
          .map(wu => wu.totalCount) || [];
      return {
        label: endpoint,
        data: filteredData,
      };
    });
  } else {
    // Group all usage together by year and weekNr.
    const groupedUsage =
      data?.weeklyUsage.reduce(
        (acc, wu) => {
          const key = `${wu.year} wk${wu.weekNr}`;
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += wu.totalCount;
          return acc;
        },
        {} as {[key: string]: number},
      ) || {};
    usageDatasets = [
      {
        label: 'Weekly Voams Created',
        data: Object.values(groupedUsage),
      },
    ];
  }

  // Repeat for audio seconds.
  let audioSecondsDatasets;
  if (!aggregateAudioSeconds) {
    audioSecondsDatasets = uniqueActionsArray.map(endpoint => {
      const filteredData =
        data?.weeklyUsage
          .filter(wu => wu.endpoint === endpoint)
          .sort((a, b) => a.year - b.year || a.weekNr - b.weekNr)
          .map(wu => wu.totalAudioSeconds) || [];
      return {
        label: endpoint,
        data: filteredData,
      };
    });
  } else {
    // Group all usage together by year and weekNr.
    const groupedUsage =
      data?.weeklyUsage.reduce(
        (acc, wu) => {
          const key = `${wu.year} wk${wu.weekNr}`;
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += wu.totalAudioSeconds;
          return acc;
        },
        {} as {[key: string]: number},
      ) || {};
    audioSecondsDatasets = [
      {
        label: 'Weekly Audio Seconds',
        data: Object.values(groupedUsage),
      },
    ];
  }

  const usageChartData = {labels, datasets: usageDatasets};
  const audioSecondsChartData = {labels, datasets: audioSecondsDatasets};

  return {
    isLoading,
    weeklyKPIs: data,
    userChartData,
    usageChartData,
    audioSecondsChartData,
    mutate,
    error,
  };
}

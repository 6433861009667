import React, {useEffect} from 'react';
import useUser from '../../data/use-user';
import {Row, Col, Alert, Popconfirm, theme, Space} from 'antd';
import {AlertOutline, InformationCircle} from 'react-ionicons';
import {B, H2, H5, P2, P3, RoundedButton, TextInput} from '../../common/styles';
import {Container, StyledCard} from './styles';
import ChangePasswordForm from '../../components/ChangePasswordForm';
import ConnectCRMForm from '../../components/ConnectCRMForm';
import SettingDisplay from '../../components/SettingDisplay';
import {useState} from 'react';
import IntegrationService from '../../services/integrations.service';
import LoadingIndicator from '../../components/LoadingIndicator';
import useBillingData from '../../data/use-billing-data';
import useChargebee from '../../hooks/use-chargebee';
import {Organization} from '../../types/orgs.types';
import DeletableTag from '../../components/DeletableTag';
import OrgService from '../../services/orgs.service';
import {useDebounce} from '../../hooks/use-debounced';

function formatString(string?: string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).replace('_', ' ')
    : undefined;
}

const VocabularySettings = ({org}: {org?: Organization}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [keywordField, setKeywordField] = useState('');
  const [keywords, setKeywords] = useState(org?.vocabulary || []);

  const removeKeyword = (keyword: string) => {
    setKeywords(keywords.filter(k => k !== keyword));
  };

  const saveVocabulary = () => {
    if (org) {
      setIsProcessing(true);
      OrgService.patchOrganization(org._id, {vocabulary: keywords}).then(() => {
        setIsProcessing(false);
      });
    }
  };

  const debouncedSave = useDebounce(saveVocabulary, 1000);

  useEffect(() => {
    debouncedSave();
  }, [keywords]);

  return (
    <Space direction='vertical' style={{width: '100%'}}>
      <P2>
        Manage the vocabulary for {org ? org.name : 'your organization'}. Add
        jargon and names to improve transcription accuracy.
      </P2>
      <TextInput
        placeholder='Add a term or phrase'
        onChange={e => setKeywordField(e.target.value)}
        value={keywordField}
        onPressEnter={() => {
          if (keywordField) {
            setKeywords([...keywords, keywordField]);
            setKeywordField('');
          }
          setKeywordField('');
        }}
      />
      {keywords.length > 0 ? (
        <Space wrap size={3}>
          {keywords.map((keyword: string) => (
            <DeletableTag
              key={keyword}
              label={keyword}
              onDelete={removeKeyword}
            />
          ))}
        </Space>
      ) : (
        <P3>Your vocabulary is empty</P3>
      )}
      {isProcessing && <P3>Saving...</P3>}
    </Space>
  );
};

const Settings = () => {
  const {user, org, error, mutate, isLoading: isLoadingUser} = useUser();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const toggleChangePassword = () => setShowChangePassword(!showChangePassword);
  const [showConnectCRM, setShowConnectCRM] = useState(false);
  const toggleConnectCRM = () => setShowConnectCRM(!showConnectCRM);
  const [success, setSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const {token} = theme.useToken();
  const {
    subscription,
    plan,
    billingAddress,
    paymentMethod,
    isLoading: isLoadingBillingData,
    error: errorBillingData,
    mutate: mutateBillingData,
  } = useBillingData();
  const {
    openPortal,
    openBillingHistory,
    checkoutCurrentPlan,
    checkoutUpgrade,
    openBillingAddress,
    openPaymentMethods,
  } = useChargebee(() => {
    mutateBillingData();
  });

  const handleIntegrationSuccess = () => {
    setSuccess('Succesfully connected your CRM!');
    setShowConnectCRM(false);
    setTimeout(() => setSuccess(''), 5000);
  };

  const handleRemoveIntegration = () => {
    if (user && user.integrations.crm) {
      IntegrationService.removeIntegration(user?.integrations.crm).then(
        (success: boolean) => {
          if (success) {
            mutate({...user, integrations: {crm: undefined}});
            setSuccess('Successfully disconnected your CRM.');
            setTimeout(() => setSuccess(''), 5000);
          } else {
            setErrorMessage('Failed to disconnect your CRM.');
            setTimeout(() => setErrorMessage(''), 5000);
          }
        },
      );
    }
  };

  const onFreePlan = plan?.itemPriceId?.startsWith('Free');

  return (
    <Container>
      <Row justify='start'>
        <H2>Settings</H2>
      </Row>
      {success && (
        <Alert
          type='success'
          message={success}
          closable
          style={{width: '100%'}}
        />
      )}
      {error && (
        <Alert
          type='error'
          message={errorMessage}
          closable
          style={{width: '100%'}}
        />
      )}
      <Row justify='start' style={{width: '100%', marginTop: 30}} gutter={20}>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <StyledCard title={<H5>Account</H5>}>
            {error && <Alert>An error has occurred.</Alert>}
            {isLoadingUser && <LoadingIndicator message='Loading...' />}
            {user && (
              <>
                <SettingDisplay label='Name' value={user.name} />
                <SettingDisplay label='Email' value={user && user.email} />
                <SettingDisplay
                  label='Password'
                  buttonLabel={
                    showChangePassword ? 'Cancel' : 'Change password'
                  }
                  onClick={toggleChangePassword}
                />
                {showChangePassword && <ChangePasswordForm />}
              </>
            )}
          </StyledCard>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <StyledCard title={<H5>Subscription & Billing</H5>}>
            {isLoadingBillingData && <LoadingIndicator message='Loading...' />}
            {errorBillingData && (
              <Alert
                type='error'
                message={`Something went wrong: ${errorBillingData}`}
              />
            )}
            <SettingDisplay
              label='Plan'
              value={plan?.itemPriceId.split('-')[0] + ' Plan'}
              onClick={onFreePlan ? checkoutUpgrade : undefined}
              primary={onFreePlan}
              buttonLabel={onFreePlan ? 'Upgrade to Standard Plan' : undefined}
            />
            <SettingDisplay label='Members' value={`${plan?.quantity}`} />
            <SettingDisplay
              label='Status'
              value={formatString(subscription?.status) || ''}
            />
            {subscription &&
            subscription.status === 'in_trial' &&
            !paymentMethod ? (
              <>
                <Alert
                  message={
                    <Space>
                      <InformationCircle color={token.colorInfo} />
                      <B>Add a payment method</B>
                    </Space>
                  }
                  description={`Your trial will end on ${new Date(
                    subscription.trialEnd,
                  ).toLocaleDateString()}. Add a payment method now to keep using all features after that. If you don't, you will be downgraded to the Free plan automatically.`}
                  type='info'
                  style={{marginBottom: 10}}
                />
                <Row justify='end' style={{marginTop: 10}}>
                  <RoundedButton onClick={checkoutCurrentPlan}>
                    Add payment method
                  </RoundedButton>
                </Row>
              </>
            ) : (
              <>
                {billingAddress && billingAddress.line1 ? (
                  <SettingDisplay
                    label='Billing address'
                    value={billingAddress.line1}
                    buttonLabel='Edit'
                    onClick={openBillingAddress}
                  />
                ) : (
                  <SettingDisplay
                    label='Billing address'
                    buttonLabel='Add'
                    onClick={openBillingAddress}
                  />
                )}
                {paymentMethod ? (
                  <SettingDisplay
                    label='Payment method'
                    value={formatString(paymentMethod.type)}
                    buttonLabel='Manage'
                    onClick={openPaymentMethods}
                  />
                ) : (
                  <SettingDisplay
                    label='Payment method'
                    buttonLabel='Add'
                    onClick={openPaymentMethods}
                  />
                )}
              </>
            )}
            <SettingDisplay
              label='History'
              buttonLabel='View invoices'
              onClick={openBillingHistory}
            />
            <SettingDisplay
              label=''
              buttonLabel='Manage subscription, billing & payments'
              onClick={openPortal}
            />
          </StyledCard>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <StyledCard title={<H5>Preferences</H5>}>
            <SettingDisplay label='Color theme' value='Coming soon' />
          </StyledCard>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <StyledCard title={<H5>Vocabulary</H5>}>
            <VocabularySettings org={org} />
          </StyledCard>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <StyledCard title={<H5>Integrations</H5>}>
            {user &&
              (user.integrations.crm ? (
                <Popconfirm
                  title={<B>Are you sure you want to disconnect your CRM?</B>}
                  description='Certain functionalities of voam will be unavailable without this integration.'
                  onConfirm={handleRemoveIntegration}
                  okText='Remove'
                  cancelText='Cancel'
                  okButtonProps={{danger: true}}
                  icon={<AlertOutline color={token.colorError} />}
                >
                  <SettingDisplay
                    label='CRM'
                    value={user.integrations.crm}
                    buttonLabel='Remove'
                    danger
                  />
                </Popconfirm>
              ) : (
                <SettingDisplay
                  label='CRM'
                  buttonLabel={showConnectCRM ? 'Cancel' : 'Connect'}
                  onClick={toggleConnectCRM}
                />
              ))}
            {user && showConnectCRM && (
              <ConnectCRMForm onCompletion={handleIntegrationSuccess} />
            )}
          </StyledCard>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;

import React, {useEffect, useState} from 'react';
import {message} from 'antd';
import BillingService from '../services/billing.service';
import {B, P2, P3} from '../common/styles';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Chargebee: any;
  }
}

export default function useChargebee(onChange?: () => void) {
  const [messageApi, contextHolder] = message.useMessage();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [cbInstance, setCbInstance] = useState<any>(null);

  function sendErrorMessage(detail?: string) {
    messageApi.error(
      <>
        <B>Something went wrong</B>
        <P2>Could not open the Billing portal.</P2>
        {detail && <P3>Reason: {detail}</P3>}
      </>,
      10,
    );
  }

  useEffect(() => {
    if (cbInstance) return;
    try {
      const instance = window.Chargebee.init({
        site: process.env.REACT_APP_CHARGEBEE_SITE!,
      });

      instance.setPortalSession(() => {
        return BillingService.getPortalSession(sendErrorMessage).then(
          session => session,
        );
      });

      setCbInstance(instance);
    } catch (error) {
      sendErrorMessage();
    }
  }, [cbInstance]);

  const openPortal = () => {
    try {
      cbInstance?.createChargebeePortal().open();
    } catch (error) {
      sendErrorMessage(
        'Error opening portal. Please try again or contact support.',
      );
    }
  };

  const openPaymentMethods = () => {
    try {
      const cbPortal = cbInstance.createChargebeePortal();
      cbPortal.openSection(
        {
          sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
        },
        {
          onPaymentSourceAdded: onChange,
          onPaymentSourceUpdated: onChange,
          onPaymentSourceDeleted: onChange,
        },
      );
    } catch (error) {
      sendErrorMessage();
    }
  };

  const openBillingHistory = () => {
    try {
      const cbPortal = cbInstance.createChargebeePortal();
      cbPortal.openSection({
        sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
      });
    } catch (error) {
      sendErrorMessage();
    }
  };

  const openBillingAddress = () => {
    try {
      const cbPortal = cbInstance.createChargebeePortal();
      cbPortal.openSection(
        {sectionType: window.Chargebee.getPortalSections().ADDRESS},
        {onAddressUpdated: onChange},
      );
    } catch (error) {
      sendErrorMessage();
    }
  };

  const checkoutCurrentPlan = () => {
    cbInstance.openCheckout(
      {
        hostedPage: () =>
          BillingService.createCheckoutExisting(sendErrorMessage),
        embed: true,
      },
      {success: onChange},
    );
  };

  const checkoutUpgrade = () => {
    cbInstance.openCheckout(
      {
        hostedPage: () =>
          BillingService.createCheckoutUpgrade(sendErrorMessage),
        embed: true,
      },
      {success: onChange},
    );
  };

  return {
    openPortal,
    openPaymentMethods,
    openBillingHistory,
    openBillingAddress,
    checkoutCurrentPlan,
    checkoutUpgrade,
    contextHolder,
  };
}

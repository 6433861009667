import {Tag} from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Tag)`
  border-radius: 25px;
  font-size: 1rem;
  padding: 3px 10px 3px 10px;
  border: 1px solid ${props => props.theme.token.colorText};
  color: ${props => props.theme.token.colorText};
  background: transparent;
  align-items: center;
  justify-content: center;
  display: flex;
`;

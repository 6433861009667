import React, {useState} from 'react';
import {
  TextInput,
  RoundedButton,
  ErrorText,
  PlainTextButton,
} from '../../../common/styles';
import AuthService from '../../../services/auth.service';
import Container from '../../../common/Container';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {StyledCardHeading, StyledMessage} from '../styles';
import {Row} from 'antd';

interface ForgotPasswordFormProps {
  onSuccess: (email: string) => void;
  onBack: () => void;
}

const ForgotPasswordForm = ({onSuccess, onBack}: ForgotPasswordFormProps) => {
  const [email, setEmail] = useState<string>('');
  const [busy, setBusy] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!email.includes('@') || !email.includes('.') || email.length < 5) {
      setErrorMessage('Please enter a valid email address');
      return;
    }
    setErrorMessage(null);
    setBusy(true);
    AuthService.forgotPassword(email).then(success => {
      if (success) {
        onSuccess(email);
      } else {
        setErrorMessage('Failed to request password reset. Please try again.');
      }
      setBusy(false);
    });
  };

  return (
    <Container>
      <StyledCardHeading>Reset password.</StyledCardHeading>
      <StyledMessage>
        Enter the email adress associated with your account and we&apos;ll send
        you a link to reset your password.
      </StyledMessage>
      <TextInput
        style={{padding: '20px'}}
        onChange={e => setEmail(e.target.value)}
        value={email}
        placeholder='Email'
        autoCapitalize='none'
        autoComplete='email'
      />
      {busy ? (
        <div style={{padding: '20px 0'}}>
          <LoadingIndicator message='Working on it...' />
        </div>
      ) : (
        <Row justify='space-between' align='middle' style={{padding: '20px 0'}}>
          <PlainTextButton onClick={onBack}>Back to login</PlainTextButton>
          <RoundedButton disabled={busy} onClick={handleSubmit}>
            Reset password
          </RoundedButton>
        </Row>
      )}
      {errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
    </Container>
  );
};

export default ForgotPasswordForm;

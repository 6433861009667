import useSWR from 'swr';
import StreamService from '../services/streams.service';
import {Stream} from '../types/streams.types';

interface useStreamsReturn {
  isLoading: boolean;
  streams: Stream[];
  mutate: () => Promise<Stream[] | undefined>;
  error?: Error;
  streamsById: Map<string, Stream>;
}

export default function useStreams(): useStreamsReturn {
  const {
    data: streams,
    mutate,
    error,
  } = useSWR(process.env.REACT_APP_STREAMS_ENDPOINT!, StreamService.getStreams);
  const isLoading = !streams && !error;
  const streamsById = new Map();
  streams?.forEach(stream => {
    streamsById.set(stream._id, stream);
  });

  return {
    isLoading,
    streams: streams ?? [],
    mutate,
    error,
    streamsById,
  };
}

import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Form, Row, Col} from 'antd';
import {Organization} from '../../../types/orgs.types';
import FancyButton from '../../../common/FancyButton';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  FormTitle,
  Instructions,
  StyledTextInput,
  ErrorMessage,
  ButtonRow,
  InputLabel,
} from '../styles';
import AuthService from '../../../services/auth.service';

interface EmailCheckProps {
  onCompletion: (
    firstName: string,
    lastName: string,
    email: string,
    org?: Organization,
  ) => void;
}

const EmailCheck = ({onCompletion}: EmailCheckProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');

  const emailValid =
    email.includes('@') &&
    email.length > 5 &&
    email.split('@')[1].includes('.') &&
    email.split('.').slice(-1)[0].length > 1;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (!emailValid) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
    if (firstName.length === 0) {
      setErrorMessage('Please provide your first name.');
      return;
    }
    if (lastName.length === 0) {
      setErrorMessage('Please provide your last name.');
      return;
    }
    setIsLoading(true);
    setLoadingMessage('Checking email...');
    const result = await AuthService.checkEmail(email);
    if (result.success) {
      onCompletion(firstName, lastName, result.email, result.org);
    } else {
      setEmail('');
      setErrorMessage(result.message);
    }
    setIsLoading(false);
    setLoadingMessage('');
  };

  const toLogin = () => {
    navigate('/login', {state: location.state});
  };

  return (
    <Form>
      <FormTitle>Let&#39;s create your account</FormTitle>
      <Instructions>
        The first 14 days are on us. No credit card required, stops
        automatically. Enter your name and work email to get started.
      </Instructions>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      {isLoading ? (
        <LoadingIndicator message={loadingMessage} />
      ) : (
        <Col>
          <Row justify='space-between'>
            <Col xs={24} sm={11}>
              <InputLabel>First name</InputLabel>
              <StyledTextInput
                value={firstName}
                placeholder='Your first name'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFirstName(e.target.value)
                }
              />
            </Col>
            <Col xs={24} sm={11}>
              <InputLabel>Last name</InputLabel>
              <StyledTextInput
                value={lastName}
                placeholder='Your last name'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLastName(e.target.value)
                }
              />
            </Col>
          </Row>
          <Row>
            <InputLabel>Work email</InputLabel>
            <StyledTextInput
              value={email}
              type='email'
              placeholder='Your work email'
              onChange={handleEmailChange}
              contentEditable={!isLoading}
              onPressEnter={handleSubmit}
            />
          </Row>
        </Col>
      )}
      <ButtonRow justify='end'>
        <FancyButton disabled={isLoading} onClick={handleSubmit}>
          Continue
        </FancyButton>
      </ButtonRow>
      <Instructions style={{marginTop: 25}}>
        Already have an account? <a onClick={toLogin}>Log in</a>
      </Instructions>
    </Form>
  );
};

export default EmailCheck;

import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Form, Row} from 'antd';
import {User} from '../../../types/users.types';
import {FormTitle, Instructions, PYellow, ErrorMessage} from '../styles';
import AuthService from '../../../services/auth.service';
import LoadingIndicator from '../../LoadingIndicator';
import FancyButton from '../../../common/FancyButton';

interface VerifyAccountProps {
  user: User;
}

const VerifyAccount = ({user}: VerifyAccountProps) => {
  const [timer, setTimer] = React.useState(60);
  const [isResending, setIsResending] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const redirect: string | undefined = (location.state as any)?.redirect;

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);

  const sendEmail = async () => {
    setIsResending(true);
    setErrorMessage('');
    const result = await AuthService.requestVerifyEmail(user.email);
    if (!result) {
      setErrorMessage(
        'Something went wrong. Please try again and contact support@voam.io if the problem persists.',
      );
    } else {
      setTimer(60);
    }
    setTimeout(() => setIsResending(false), 3000);
  };

  return (
    <Form>
      <FormTitle>Verify your account</FormTitle>
      <Instructions>Final step, {user.firstName}!</Instructions>
      <Instructions>
        Click the button in the mail we&#39;ve just sent to
        <PYellow> {user.email}</PYellow> to confirm your account and you&#39;re
        all set!
      </Instructions>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Row justify='start' align='middle'>
        {isResending && <LoadingIndicator message='Sending email...' />}
        {!isResending && (
          <FancyButton onClick={sendEmail} disabled={timer > 0}>
            Send again {timer > 0 ? `(${timer}s)` : ''}
          </FancyButton>
        )}
      </Row>
      {redirect && redirect.startsWith('/connect-to-') ? (
        <>
          <Instructions style={{marginTop: 20}}>
            After verifying your account, come back here and follow this link to
            finish setting up your integration.
          </Instructions>
          <Row justify='start' align='middle'>
            <FancyButton
              onClick={() => navigate('/login', {state: {redirect: redirect}})}
            >
              Log in and connect
            </FancyButton>
          </Row>
        </>
      ) : (
        <Instructions style={{marginTop: 20}}>
          <span>
            Done verifying? <a href='/login'>Go to login</a>
          </span>
        </Instructions>
      )}
    </Form>
  );
};

export default VerifyAccount;

import React, {ChangeEvent} from 'react';
import {B} from '../../../common/styles';
import {TextAnswer, QuestionContainer} from '../styles';
import {QuestionViewInterface} from '../index';

const OpenQuestionView = ({
  question,
  questionResponse,
  onChange,
}: QuestionViewInterface) => {
  const onFieldChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    questionResponse.answer = e.target.value;
    onChange?.();
  };

  return (
    <QuestionContainer>
      <B>{question.prompt}</B>
      <TextAnswer
        autoSize={true}
        size='large'
        bordered={false}
        placeholder='Record or type your response here'
        value={questionResponse.answer as string}
        onChange={onFieldChange}
      />
    </QuestionContainer>
  );
};

export default OpenQuestionView;

import React, {useState} from 'react';
import {KeyedMutator} from 'swr';
import useLinkManager from '../hooks/use-link-manager';
import LoadingIndicator from '../components/LoadingIndicator';
import LinkedItem from '../components/LinkedItem';
import {LinkedObject} from '../types/integrations.types';
import {Voam} from '../types/voams.types';
import useActivity from './use-activity';

interface UseLinkedObjectProps {
  voam?: Voam;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: KeyedMutator<any> | ((voam: Voam) => void);
  onClick?: () => void;
}

const useLinkedObject = ({voam, onChange, onClick}: UseLinkedObjectProps) => {
  const [linkedObject, setLinkedObject] = useState<LinkedObject | null>(
    voam?.linkedObjects?.[0] || null,
  );

  const {setLink, removeLinks} = useLinkManager({
    voam,
    linkedObject,
    setLinkedObject,
    onChange,
  });

  const {
    activity,
    isLoading: isLoadingMeeting,
    error,
  } = useActivity({
    activityId: linkedObject?.id,
    activityType: linkedObject?.type,
    integrationId: linkedObject?.integrationId,
  });
  const headerComponent = linkedObject && (
    <>
      {isLoadingMeeting && <LoadingIndicator />}
      {(activity || error) && (
        <LinkedItem activity={activity} onClick={onClick} />
      )}
    </>
  );

  const handleLinkChange = (newLink: LinkedObject | null) => {
    if (newLink) {
      setLink(newLink);
    } else {
      removeLinks();
    }
  };

  return {
    activity,
    headerComponent,
    handleLinkChange,
  };
};

export default useLinkedObject;

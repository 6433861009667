import React, {useState} from 'react';
import {User} from '../../types/users.types';
import {Organization} from '../../types/orgs.types';
import EmailCheck from './EmailCheck';
import CreateOrg from './CreateOrg';
import VerifyAccount from './VerifyAccount';
import ChoosePassword from './ChoosePassword';
import {StyledSignupCard} from './styles';
import JoinOrg from './JoinOrg';
import AgreeToTerms from './AgreeToTerms';
import {VoamSolution} from '../../types/voams.types';

const SignupForm = () => {
  const [stage, setStage] = useState<
    'email' | 'join-org' | 'create-org' | 'agree' | 'password' | 'verify'
  >('email');
  const [email, setEmail] = useState<string | null>(null);
  const [org, setOrg] = useState<Organization | null>(null);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [solutions, setSolutions] = useState<VoamSolution[]>([]);
  const [user, setUser] = useState<User | null>(null);

  const reset = () => {
    setStage('email');
    setEmail(null);
    setOrg(null);
    setFirstName('');
    setLastName('');
    setSolutions([]);
    setUser(null);
    setAgreedToTerms(false);
  };

  const handleEmailCheck = (
    firstName: string,
    lastName: string,
    email: string,
    org?: Organization,
  ) => {
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    if (org) {
      setOrg(org);
      setStage('join-org');
    } else {
      setStage('create-org');
    }
  };

  const handleCreateOrg = (org: Organization) => {
    setOrg(org);
    setStage('agree');
  };

  const handleConfirmOrg = () => {
    setStage('agree');
  };

  const handleAgreeToTerms = () => {
    setAgreedToTerms(true);
    setStage('password');
  };

  const handleUser = (user: User) => {
    setStage('verify');
    setUser(user);
  };

  return (
    <StyledSignupCard>
      {stage === 'email' && <EmailCheck onCompletion={handleEmailCheck} />}
      {stage === 'create-org' && (
        <CreateOrg
          firstName={firstName}
          email={email!}
          onBack={reset}
          onCompletion={handleCreateOrg}
        />
      )}
      {stage === 'join-org' && (
        <JoinOrg
          firstName={firstName}
          org={org!}
          onBack={reset}
          onCompletion={handleConfirmOrg}
        />
      )}
      {stage === 'agree' && (
        <AgreeToTerms onBack={reset} onCompletion={handleAgreeToTerms} />
      )}
      {stage === 'password' && (
        <ChoosePassword
          email={email!}
          firstName={firstName}
          lastName={lastName}
          org={org!}
          agreedToTerms={agreedToTerms}
          solutions={solutions}
          onCompletion={handleUser}
        />
      )}
      {stage === 'verify' && <VerifyAccount user={user!} />}
    </StyledSignupCard>
  );
};

export default SignupForm;

import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import LoginForm from '../../features/login/LoginForm';
import ForgotPasswordForm from '../../features/login/ForgotPasswordForm';
import ResetPasswordMessage from '../../features/login/ResetPasswordMessage';
import {Row, Col} from 'antd';
import {StyledLoginCard, Background, StyledHeaderRow} from './styles';
import {SvgIcon} from '../../common/Image';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toSignup = () => {
    navigate('/signup', {state: location.state});
  };
  const [screen, setScreen] = useState<
    'login' | 'forgotPassword' | 'resetPassword'
  >('login');
  const [email, setEmail] = useState<string | null>(null);

  return (
    <Background>
      <StyledHeaderRow justify='space-between' align='middle'>
        <Col xs={10} sm={8} lg={6}>
          <SvgIcon src='svg/logo_color.svg' width='120px' height='auto' />
        </Col>
        <Col xs={10} sm={8} lg={6}>
          <span>
            No account? <a onClick={toSignup}>Get started for free</a>
          </span>
        </Col>
      </StyledHeaderRow>
      <Row justify='center' align='middle'>
        <Col span={24}>
          <StyledLoginCard>
            {screen === 'login' ? (
              <LoginForm onForgotPassword={() => setScreen('forgotPassword')} />
            ) : null}
            {screen === 'forgotPassword' ? (
              <ForgotPasswordForm
                onBack={() => {
                  setScreen('login');
                  setEmail(null);
                }}
                onSuccess={(newEmail: string) => {
                  setEmail(newEmail);
                  setScreen('resetPassword');
                }}
              />
            ) : null}
            {screen === 'resetPassword' ? (
              <ResetPasswordMessage
                email={email}
                onBack={() => setScreen('login')}
              />
            ) : null}
          </StyledLoginCard>
        </Col>
      </Row>
    </Background>
  );
};

export default Login;

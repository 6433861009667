import React from 'react';
import {Row} from 'antd';
import * as IconModule from 'ionicons/icons';
import {chevronForwardOutline, chevronDownOutline} from 'ionicons/icons';
import {FormHeaderMap, FormResponse} from '../../types/formless.types';
import {SearchPreview, KeyItem} from '../../types/search.types';
import FormResponseContentView, {
  FormResponseContentPreview,
} from '../FormResponseContentView';
import {
  ResponseTitle,
  ResponseTitle2,
  KeyItemContainer,
  Description,
  StyledChevron,
  KeyItemIcon,
  StyledSpace,
} from './styles';
import {PlainCard, P3, P} from '../../common/styles';
import {answerToString} from '../../data/utils';

interface FormResponseSearchResultProps {
  formResponse: FormResponse;
  preview: SearchPreview | null;
  headerMap: FormHeaderMap;
  objectTypeMap: {[key: string]: string};
}

const toCamelCase = (string: string) => {
  return string.replace(/-([a-z])/g, g => g[1].toUpperCase());
};

const IconList = IconModule as unknown as {[key: string]: string};

const getIcon = (icon: string) => {
  return IconList[toCamelCase(icon)];
};

const FormResponseSearchResult = ({
  formResponse,
  preview,
  headerMap,
  objectTypeMap,
}: FormResponseSearchResultProps) => {
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <PlainCard key={formResponse._id}>
      <Row onClick={() => setCollapsed(!collapsed)}>
        <StyledChevron
          icon={collapsed ? chevronForwardOutline : chevronDownOutline}
        />
        <ResponseTitle>
          {preview?.title ??
            answerToString(formResponse.topics[0].questions[0].answer) ?? (
              <ResponseTitle2>Untitled voam</ResponseTitle2>
            )}
        </ResponseTitle>
      </Row>
      {preview && (
        <>
          <StyledSpace wrap>
            {preview &&
              preview.keyItems.map((keyItem: KeyItem, index) => {
                return (
                  <KeyItemContainer key={'keyItem' + index}>
                    <KeyItemIcon icon={getIcon(keyItem.icon)} />
                    {keyItem.text ? <P>{keyItem.text}</P> : <P3>Unknown</P3>}
                  </KeyItemContainer>
                );
              })}
          </StyledSpace>
          <Description>{preview.description}</Description>
        </>
      )}
      {!collapsed && (
        <FormResponseContentView
          response={formResponse}
          headerMap={headerMap}
          objectTypeMap={objectTypeMap}
        />
      )}
      {collapsed && !preview && (
        <FormResponseContentPreview
          response={formResponse}
          headerMap={headerMap}
          objectTypeMap={objectTypeMap}
        />
      )}
    </PlainCard>
  );
};

export default FormResponseSearchResult;

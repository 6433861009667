import React from 'react';
import {
  FormResponse,
  FormHeaderMap,
  FormTopicResponse,
  QuestionResponse,
} from '../../types/formless.types';
import {TopicHeader, FormContentContainer, TopicContainer} from './styles';
import QuestionContentView from './QuestionContentView';

interface FormResponseContentViewInterface {
  headerMap: FormHeaderMap;
  response: FormResponse;
  objectTypeMap: {[key: string]: string};
}

const FormResponseContentView = ({
  headerMap,
  response,
  objectTypeMap,
}: FormResponseContentViewInterface) => {
  return (
    <FormContentContainer key={response._id}>
      {response.topics &&
        response.topics.map((topic: FormTopicResponse) => (
          <TopicContainer key={response._id + topic.name}>
            <TopicHeader>{headerMap.topics[topic.name]}</TopicHeader>
            {topic.questions.map((question: QuestionResponse) => (
              <QuestionContentView
                key={response._id + question.name}
                questionResponse={question}
                header={
                  topic.questions.length > 1
                    ? headerMap.questions[question.name]
                    : undefined
                }
                objectType={objectTypeMap[question.name]}
              />
            ))}
          </TopicContainer>
        ))}
    </FormContentContainer>
  );
};

const FormResponseContentPreview = ({
  headerMap,
  response,
  objectTypeMap,
}: FormResponseContentViewInterface) => {
  return (
    <FormContentContainer key={response._id}>
      {response.topics &&
        response.topics.slice(0, 1).map((topic: FormTopicResponse) => (
          <TopicContainer key={response._id + topic.name}>
            <TopicHeader>{headerMap.topics[topic.name]}</TopicHeader>
            {topic.questions.slice(0, 3).map((question: QuestionResponse) => (
              <QuestionContentView
                key={response._id + question.name}
                questionResponse={question}
                header={headerMap.questions[question.name]}
                objectType={objectTypeMap[question.name]}
                isPreview
              />
            ))}
          </TopicContainer>
        ))}
    </FormContentContainer>
  );
};

export default FormResponseContentView;
export {FormResponseContentPreview};

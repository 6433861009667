import API from './api';
import {Note, NoteEdit} from '../types/notes.types';
import {LinkedObject} from '../types/integrations.types';

async function getNotes(url: string): Promise<Array<Note>> {
  return await API.get(url).then(res => res.data);
}

async function getNote(url: string): Promise<Note> {
  return await API.get(url).then(res => res.data);
}

async function patchNote(
  url: string,
  data: NoteEdit,
): Promise<Note | undefined> {
  return await API.patch(url, data).then(res => res.data);
}

async function createNote(data: Note): Promise<Note | undefined> {
  try {
    return await API.post(process.env.REACT_APP_NOTES_ENDPOINT!, data).then(
      res => res.data,
    );
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function createNoteFromAudio(file: File): Promise<Note | undefined> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    return await API.post(
      process.env.REACT_APP_NOTES_ENDPOINT! + 'from-audio',
      formData,
    ).then(res => res.data);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function postNoteAudio(
  id: string,
  file: File,
): Promise<Note | undefined> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    return await API.post(
      process.env.REACT_APP_NOTES_ENDPOINT! + id + '/audio',
      formData,
    ).then(res => res.data);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function postAudio(id: string, file: File): Promise<Note | undefined> {
  if (id === 'tmp') {
    return await createNoteFromAudio(file);
  } else {
    return await postNoteAudio(id, file);
  }
}

async function deleteNote(id: string): Promise<boolean> {
  try {
    await API.delete(process.env.REACT_APP_NOTES_ENDPOINT! + id);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function setLinkedObjects(
  id: string,
  linkedObjects: Array<LinkedObject>,
): Promise<Note | undefined> {
  try {
    return await API.put(
      process.env.REACT_APP_NOTES_ENDPOINT! + id + '/links',
      linkedObjects,
    ).then(res => res.data);
  } catch (error) {
    return undefined;
  }
}

async function removeLinkedObjects(id: string): Promise<Note | undefined> {
  try {
    return await API.delete(
      process.env.REACT_APP_NOTES_ENDPOINT! + id + '/links',
    ).then(res => res.data);
  } catch (error) {
    return undefined;
  }
}

async function submit(id: string): Promise<[Note?, string?]> {
  return await API.post(
    process.env.REACT_APP_NOTES_ENDPOINT! + id + '/submit',
    {},
  )
    .then(res => [res.data, undefined] as [Note?, string?])
    .catch(err => {
      return [
        undefined,
        err.response.data.detail ?? 'Something went wrong.',
      ] as [Note?, string?];
    });
}

const NoteService = {
  getNotes,
  getNote,
  patchNote,
  deleteNote,
  createNote,
  postAudio,
  setLinkedObjects,
  removeLinkedObjects,
  submit,
};

export default NoteService;

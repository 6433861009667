import useSWR, {KeyedMutator} from 'swr';
import FormlessService from '../services/formless.service';
import {Form} from '../types/formless.types';

interface useFormReturn {
  isLoading: boolean;
  form?: Form;
  mutate: KeyedMutator<Form>;
  error?: Error;
}

export default function useForm(formId?: string): useFormReturn {
  const {
    data: form,
    mutate,
    error,
  } = useSWR(
    formId ? process.env.REACT_APP_FORMS_ENDPOINT! + formId : null,
    FormlessService.getForm,
  );
  const isLoading = !form && !error;
  return {
    form,
    isLoading,
    mutate,
    error,
  };
}

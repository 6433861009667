import useSWR from 'swr';
import FormlessService from '../services/formless.service';
import {FormResponse} from '../types/formless.types';
import {useDebounce} from '../hooks/use-debounced';

export default function useResponse(
  formResponse: FormResponse,
  onChange?: (response: FormResponse) => void,
  debounceTime = 2000,
) {
  const onServer = formResponse._id !== 'tmp';
  const {data, mutate, error} = useSWR<FormResponse>(
    onServer
      ? process.env.REACT_APP_RESPONSES_ENDPOINT! + formResponse._id
      : null,
    FormlessService.getResponse,
  );
  const isLoading = !data && !error && onServer;
  const loggedOut =
    error && error.message === 'Request failed with status code 401';

  async function _save(): Promise<void> {
    if (data && onServer) {
      await FormlessService.patchResponse(
        process.env.REACT_APP_RESPONSES_ENDPOINT! + formResponse._id,
        data!,
      );
      mutate(data);
      onChange?.(data);
    }
  }

  async function _saveNew(): Promise<void> {
    if (!onServer) {
      const response = await FormlessService.createResponse(formResponse);
      if (response) {
        onChange?.(response);
      }
    }
  }

  const save = onServer ? _save : _saveNew;
  const autoSave = useDebounce(save, debounceTime);

  const _mutateNew = (
    newData: FormResponse | undefined | Promise<FormResponse | undefined>,
  ) => {
    if (newData instanceof Promise) {
      newData.then(_mutateNew);
      return;
    }
    if (newData) {
      onChange?.(newData);
    }
  };

  return {
    isLoading,
    loggedOut,
    response: data ?? formResponse,
    mutate: onServer ? mutate : _mutateNew,
    error,
    save,
    autoSave,
  };
}

import React, {useState} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {
  Popconfirm,
  Tooltip,
  Space,
  theme,
  Col,
  Dropdown,
  Modal,
  Row,
  Radio,
} from 'antd';
import type {MenuProps} from 'antd';
import {
  AlertOutline,
  CheckmarkCircleOutline,
  Link,
  LockClosed,
  PaperPlaneOutline,
  ShareSocial,
  TrashOutline,
} from 'react-ionicons';
import {B} from '../../common/styles';
import {KeyedMutator} from 'swr';
import {mkConfig, generateCsv, download} from 'export-to-csv';
import Toggle from '../../common/Toggle';
import useUser from '../../data/use-user';
import {FixedWidthLabel, HeaderRow, ToolBarButton} from './styles';
import LinkDrawer from '../LinkDrawer';
import useLinkedObject from '../../data/use-linked-object';
import {Voam} from '../../types/voams.types';
import {LinkedObject} from '../../types/integrations.types';
import {P, P2, P3, RoundedButton} from '../../common/styles';

interface VoamHeaderToolBarProps {
  voam?: Voam;
  isSubmitting: boolean;
  isLoading: boolean;
  isProcessing: boolean;
  isEditing?: boolean;
  onViewChange?: (checked: boolean) => void;
  onDelete?: () => void;
  onSubmit?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: KeyedMutator<any> | ((voam: Voam) => void);
}

const VoamHeaderToolBar = ({
  voam,
  isSubmitting,
  isLoading,
  isProcessing,
  isEditing,
  onViewChange,
  onDelete,
  onSubmit,
  onChange,
}: VoamHeaderToolBarProps) => {
  const {token} = theme.useToken();
  const {user} = useUser();
  const linkingHint =
    voam && (voam.locked || voam.submitted)
      ? 'Links can no longer be edited'
      : 'Link to a meeting in your CRM';

  const allDisabled =
    !voam || voam._id == 'tmp' || isProcessing || isSubmitting || isLoading;
  const deleteDisabled = allDisabled;
  const linkDisabled = voam?.locked || voam?.submitted || allDisabled;
  const isForm = voam && 'formId' in voam;
  const isNote = voam && 'blocks' in voam;
  const noteNotSubmittable = isNote && !voam?.linkedObjects;
  const submitDisabled = voam?.submitted || allDisabled || noteNotSubmittable;

  const submitHint =
    voam && voam.submitted
      ? 'This voam is submitted'
      : noteNotSubmittable
      ? 'voams without templates can only be submitted if linked to a meeting'
      : 'Submit this voam';

  const [linkDrawerOpen, setLinkDrawerOpen] = useState<boolean>(false);
  const {headerComponent, handleLinkChange} = useLinkedObject({
    voam,
    onChange: onChange,
  });

  // Export menu.
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [separator, setSeparator] = useState<',' | ';'>(',');
  const [decimal, setDecimal] = useState<',' | '.'>('.');

  const handleDownload = () => {
    const csvConfig = mkConfig({
      useKeysAsHeaders: true,
      fieldSeparator: separator,
      decimalSeparator: decimal,
      filename: `voam-${voam?._id ?? 'export'}`,
    });
    const csvData =
      voam && isForm && voam.topics && voam.topics.length > 0
        ? voam.topics
            .map(topic => {
              return topic.questions.map(question => {
                return {
                  topic: topic.name,
                  question: question.name,
                  answer: question.answer ?? undefined,
                };
              });
            })
            .flat()
        : [];

    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleExportCSV = () => {
    setIsModalOpen(true);
  };
  const exportItems: MenuProps['items'] = [
    {
      key: 'download-csv',
      label: <a onClick={handleExportCSV}>Download as CSV</a>,
    },
  ];

  return (
    <HeaderRow justify='space-between'>
      <Col span={11}>
        <div onClick={() => setLinkDrawerOpen(true)}>{headerComponent}</div>
      </Col>
      <Col span={11} style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Space size={1} align='center' style={{height: '30px'}}>
          {voam && voam.locked && (
            <Tooltip title='Locked for editing'>
              <ToolBarButton disabled>
                <LockClosed color={token.colorTextTertiary} />
              </ToolBarButton>
            </Tooltip>
          )}
          {voam && onViewChange && isEditing !== undefined && !voam.locked && (
            <Tooltip
              title={isEditing ? 'Switch to read mode' : 'Switch to edit mode'}
            >
              <Toggle
                checked={isEditing}
                onChange={onViewChange}
                label='Edit'
              />
            </Tooltip>
          )}
          {isForm && (
            <>
              <Dropdown
                menu={{items: exportItems}}
                placement='bottomLeft'
                arrow={{pointAtCenter: true}}
                trigger={['click']}
                onOpenChange={() => setShowTooltip(false)}
              >
                <Tooltip
                  title='Export this voam'
                  open={showTooltip}
                  onOpenChange={setShowTooltip}
                >
                  <ToolBarButton disabled={deleteDisabled}>
                    <ShareSocial color={token.colorText} />
                  </ToolBarButton>
                </Tooltip>
              </Dropdown>
              <Modal
                title={<B>Export this voam as CSV</B>}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={
                  <Row justify='end' align='middle'>
                    <RoundedButton
                      key='cancel'
                      onClick={handleCancel}
                      color='transparent'
                      textcolor={token.colorTextSecondary}
                    >
                      Cancel
                    </RoundedButton>
                    <RoundedButton key='download' onClick={handleDownload}>
                      Download
                    </RoundedButton>
                  </Row>
                }
              >
                <Space size='large' direction='vertical'>
                  <P2>Configure the format of your CSV file.</P2>
                  <Space size='large'>
                    <FixedWidthLabel>
                      <P3>Separator</P3>
                    </FixedWidthLabel>
                    <Radio.Group
                      options={[
                        {label: ',', value: ','},
                        {label: ';', value: ';'},
                      ]}
                      onChange={event => setSeparator(event.target.value)}
                      value={separator}
                      optionType='button'
                    />
                  </Space>
                  <Space size='large'>
                    <FixedWidthLabel>
                      <P3>Decimal</P3>
                    </FixedWidthLabel>
                    <Radio.Group
                      options={[
                        {label: '.', value: '.'},
                        {label: ',', value: ','},
                      ]}
                      onChange={event => setDecimal(event.target.value)}
                      value={decimal}
                      optionType='button'
                    />
                  </Space>
                </Space>
              </Modal>
            </>
          )}
          <Popconfirm
            title={
              <div>
                <B>Delete this voam</B>
                <br />
                <P>
                  Are you sure you want to delete this voam?
                  <br />
                  Data sent to external systems will not be affected.
                </P>
              </div>
            }
            disabled={deleteDisabled}
            onConfirm={onDelete}
            okText='Delete'
            cancelText='Cancel'
            okButtonProps={{danger: true}}
            icon={<AlertOutline color={token.colorError} />}
          >
            <Tooltip title='Delete this voam'>
              <ToolBarButton disabled={deleteDisabled}>
                <TrashOutline
                  color={
                    deleteDisabled ? token.colorTextTertiary : token.colorText
                  }
                />
              </ToolBarButton>
            </Tooltip>
          </Popconfirm>
          {user && user.integrations.crm && (
            <Tooltip title={linkingHint}>
              <ToolBarButton
                disabled={linkDisabled}
                onClick={() => setLinkDrawerOpen(true)}
              >
                <Link
                  color={
                    linkDisabled ? token.colorTextTertiary : token.colorText
                  }
                />
              </ToolBarButton>
            </Tooltip>
          )}
          <Tooltip title={submitHint}>
            <ToolBarButton
              disabled={submitDisabled}
              onClick={!submitDisabled ? onSubmit : undefined}
            >
              {voam && voam.submitted && !isSubmitting && (
                <CheckmarkCircleOutline color={token.colorTextTertiary} />
              )}
              {(!voam || (!voam.submitted && !isSubmitting)) && (
                <PaperPlaneOutline
                  color={
                    submitDisabled
                      ? token.colorTextTertiary
                      : token.colorPrimary
                  }
                />
              )}
              {isSubmitting && <LoadingOutlined />}
            </ToolBarButton>
          </Tooltip>
        </Space>
      </Col>
      {voam && (
        <LinkDrawer
          open={linkDrawerOpen}
          voam={voam}
          onClose={() => setLinkDrawerOpen(false)}
          onLinkChange={(link: LinkedObject | null) => {
            handleLinkChange(link);
            setLinkDrawerOpen(false);
          }}
        />
      )}
    </HeaderRow>
  );
};

export default VoamHeaderToolBar;

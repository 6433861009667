import styled from 'styled-components';
import {P3} from '../../common/styles';

export const TimeIndication = styled(P3)`
  font-size: 9px;
`;

export const ChatBubbleMe = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 10px;
  background-color: ${props => props.theme.token.colorBgElevated};
  color: ${props => props.theme.token.colorText};
  text-align: right;
`;

export const ChatBubbleThem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  border-radius: 12px;
  padding: 10px;
  background-color: ${props => props.theme.token.colorBgContainer};
  color: ${props => props.theme.token.colorText};
  text-align: left;
`;

import React from 'react';
import useObject from '../../../data/use-object';
import {QuestionResponse} from '../../../types/formless.types';
import {answerToString} from '../../../data/utils';
import {P3} from '../../../common/styles';
import {
  QuestionContainer,
  QuestionHeader,
  QuestionAnswer,
  QuestionAnswerPreview,
} from './styles';

interface QuestionContentViewProps {
  questionResponse: QuestionResponse;
  header?: string;
  objectType?: string;
  isPreview?: boolean;
}

const QuestionContentView = ({
  questionResponse,
  header,
  objectType,
  isPreview,
}: QuestionContentViewProps) => {
  const {data, isLoading, error} = useObject({
    objectType,
    objectId: questionResponse.answer as string,
  });
  const answerText = !objectType
    ? answerToString(questionResponse.answer)
    : isLoading
    ? 'Loading...'
    : error
    ? 'Error loading answer'
    : data
    ? data.name + (data.detail ? ` (${data.detail})` : '')
    : 'No answer';
  return (
    <>
      <QuestionContainer>
        {header && <QuestionHeader>{header}</QuestionHeader>}
        {questionResponse.answer !== null && isPreview && (
          <QuestionAnswerPreview>{answerText}</QuestionAnswerPreview>
        )}
        {questionResponse.answer !== null && !isPreview && (
          <QuestionAnswer>{answerText}</QuestionAnswer>
        )}
        {(questionResponse.answer === null ||
          questionResponse.answer === '') && <P3>No answer</P3>}
      </QuestionContainer>
    </>
  );
};

export default QuestionContentView;

import React from 'react';
import {alertCircleOutline, checkmarkCircleOutline} from 'ionicons/icons';
import {theme} from 'antd';
import {CapsuleTag, TagIonIcon} from '../styles';

export interface StatusTagProps {
  status:
    | 'SUCCESS'
    | 'FAILED'
    | 'RUNNING'
    | 'PENDING'
    | 'CANCELLED'
    | 'SKIPPED'
    | 'NOT_STARTED';
}

const StatusTag = ({status}: StatusTagProps) => {
  const {token} = theme.useToken();
  const icon =
    status === 'SUCCESS' ? (
      <TagIonIcon icon={checkmarkCircleOutline} color='white' />
    ) : status === 'FAILED' ? (
      <TagIonIcon icon={alertCircleOutline} color='white' />
    ) : null;
  const color =
    status === 'SUCCESS'
      ? token.colorPrimary
      : status === 'FAILED'
      ? token.colorError
      : status === 'RUNNING'
      ? '#119DD0'
      : token.colorTextTertiary;
  return (
    <CapsuleTag bordered={false} icon={icon} color={color}>
      {status.toLowerCase().replace('_', ' ')}
    </CapsuleTag>
  );
};

export default StatusTag;

import styled from 'styled-components';
import {Input} from 'antd';
import {H4, P3} from '../../common/styles';

export const StyledSearch = styled(Input)<{margin?: string}>`
  margin: ${props => props.margin || '0 0 15px 0'};
  background-color: ${props => props.theme.token.colorFillAlter};
  border-radius: 25px;
  border: none;
  color: ${props => props.theme.token.colorText};
`;

export const StyledH4 = styled(H4)`
  color: gray;
`;

export const SearchSectionHeader = styled(P3)`
  font-size: 0.8rem;
  margin: 6px 0 4px 0;
  padding: 0;
`;

import React from 'react';
import {PlainTextButton, B} from '../../../common/styles';
import Container from '../../../common/Container';
import {StyledCardHeading, StyledMessage} from '../styles';
import {Row, theme} from 'antd';
import {CheckmarkCircleOutline} from 'react-ionicons';

interface ResetPasswordMessageProps {
  email: string | null;
  onBack: () => void;
}

const ResetPasswordMessage = ({email, onBack}: ResetPasswordMessageProps) => {
  const {token} = theme.useToken();
  return (
    <Container>
      <CheckmarkCircleOutline
        color={token.colorPrimary}
        height='80px'
        width='80px'
        style={{marginBottom: 20}}
      />
      <StyledCardHeading>Email sent.</StyledCardHeading>
      <StyledMessage>
        We&apos;ve sent an email to {email ? <B>{email}</B> : null} with a link
        to reset your password.
      </StyledMessage>
      <Row justify='space-around' style={{padding: '20px 0'}}>
        <PlainTextButton onClick={onBack}>Back to login</PlainTextButton>
      </Row>
      <StyledMessage>
        Having trouble recovering your account? Contact{' '}
        <a href='mailto:support@voam.io'>support@voam.io</a>.
      </StyledMessage>
    </Container>
  );
};

export default ResetPasswordMessage;

import styled from 'styled-components';
import {TextInput} from '../../common/styles';
import {InputNumber} from 'antd';

export const CreateActivityCard = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.token.colorBgElevated};
  margin: 8px 0;
`;

export const StyledInput = styled(TextInput)<{height?: string}>`
  padding: 8px;
  border-radius: 8px;
  background: white;
  border: 1px solid ${props => props.theme.token.colorBorder};
  margin-bottom: 12px;
  height: ${({height}) => (height ? height : 'auto')};
`;

export const StyledNumberInput = styled(InputNumber)`
  background: white;
  max-width: 120px;
`;

import API from './api';
import {WeeklyKPIs} from '../types/stats.types';

async function getWeeklyKPIs(url: string): Promise<WeeklyKPIs> {
  return await API.get(url).then(res => res.data);
}

const StatsService = {
  getWeeklyKPIs,
};

export default StatsService;

import React from 'react';
import {Form} from '../../types/formless.types';
import {SectionHeader} from './styles';
import FormCard from '../FormCard';
import LoadingIndicator from '../LoadingIndicator';
import {Alert} from 'antd';
import {Stream} from '../../types/streams.types';

interface FormatListSectionProps<T> {
  title: string;
  icon?: React.ReactNode;
  formats: T[];
  isLoading: boolean;
  error?: Error;
  onClick: (format: T) => void;
  selectedId?: string;
}

const FormatListSection = <T extends Form | Stream>({
  title,
  formats,
  isLoading,
  error,
  onClick,
  selectedId: selectedFormId,
}: FormatListSectionProps<T>) => {
  return (
    <>
      <SectionHeader>{title}</SectionHeader>
      {isLoading && <LoadingIndicator />}
      {error && <Alert type='error'>Failed to load.</Alert>}
      {formats &&
        formats.map(format => (
          <FormCard
            name={format.name}
            description={format.description}
            selected={format._id === selectedFormId}
            onClick={() => onClick(format)}
            key={format._id}
          />
        ))}
    </>
  );
};

export default FormatListSection;

import React from 'react';
import {StyledContainer, StyledContainerWrapper} from './styles';
import {ContainerProps} from '../types';

const Container = ({border, bgColor, color, children}: ContainerProps) => (
  <StyledContainerWrapper bgColor={bgColor} color={color}>
    <StyledContainer border={border}>{children}</StyledContainer>
  </StyledContainerWrapper>
);

export default Container;

import API from './api';
import {
  Integration,
  Meeting,
  Activity,
  ActivityCreate,
} from '../types/integrations.types';
import {User} from '../types/users.types';

async function getIntegrations(): Promise<Integration[]> {
  return await API.get(process.env.REACT_APP_INTEGRATIONS_ENDPOINT!).then(
    res => res.data,
  );
}

async function setConnection(
  integrationId: string,
  connectionId: string,
): Promise<User | null> {
  return await API.post(
    process.env.REACT_APP_INTEGRATIONS_ENDPOINT! +
      `${integrationId}/connections/${connectionId}`,
  )
    .then(res => res.data)
    .catch(() => {
      return null;
    });
}

async function removeIntegration(integrationId: string): Promise<boolean> {
  return await API.delete(
    process.env.REACT_APP_INTEGRATIONS_ENDPOINT! + integrationId,
  )
    .then(() => true)
    .catch(() => {
      return false;
    });
}

async function getMeetings(url: string): Promise<Meeting[]> {
  return await API.get(url).then(res => res.data);
}

async function getMeeting(url: string): Promise<Meeting> {
  return await API.get(url).then(res => res.data);
}

async function getActivity(url: string): Promise<Activity> {
  return await API.get(url).then(res => res.data);
}

async function createActivity(
  activityCreate: ActivityCreate,
): Promise<[Activity?, string?]> {
  return await API.post(
    process.env.REACT_APP_INTEGRATIONS_ENDPOINT! + 'crm/activities/',
    activityCreate,
  )
    .then(res => [res.data, undefined] as [Activity, undefined])
    .catch(
      () => [undefined, 'Failed to create activity.'] as [undefined, string],
    );
}

const IntegrationService = {
  getIntegrations,
  setConnection,
  removeIntegration,
  getMeetings,
  getMeeting,
  getActivity,
  createActivity,
};

export default IntegrationService;

import styled from 'styled-components';
import {P, P3} from '../../common/styles';

export const ActiveFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.token.colorFillAlter};
  margin-bottom: 4px;
`;

export const QuestionText = styled(P)`
  font-size: 0.9rem;
  margin: 0 0 4px 0;
  font-family: 'LibreFranklin Medium';
  font-weight: 500;
`;

export const OperatorText = styled(P3)`
  font-size: 0.9rem;
  margin: 0 0 4px 0;
  font-family: 'LibreFranklin Italic';
`;

export const AnswerText = styled(P)`
  font-size: 0.9rem;
  margin: 0 0 4px 0;
`;

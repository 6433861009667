import React, {useEffect, useState} from 'react';
import {SvgIcon} from '../../common/Image';
import useRecorder from '../../hooks/use-recorder';
import {StyledRecorderContainer, StyledRecorderButton, Message} from './styles';
import {Row} from 'antd';
import Voamination from '../../common/Voamination';

interface RecorderInterface {
  disabled: boolean;
  processing: boolean;
  onRecordingStart?: () => void;
  onAudioFile?: (file: File) => void;
  message?: string;
  hidden?: boolean;
}

export default function Recorder({
  processing,
  onRecordingStart,
  onAudioFile,
  hidden,
}: RecorderInterface): JSX.Element {
  const [currentAudioProcessed, setCurrentAudioProcessed] = useState(false);
  const {startRecording, stopRecording, isRecording, audioFile} = useRecorder();

  useEffect(() => {
    if (audioFile && onAudioFile && !currentAudioProcessed) {
      onAudioFile(audioFile);
      setCurrentAudioProcessed(true);
    }
  }, [audioFile, onAudioFile, currentAudioProcessed]);

  useEffect(() => {
    if (isRecording && onRecordingStart) {
      onRecordingStart();
    }
    if (isRecording) {
      setCurrentAudioProcessed(false);
    }
  }, [isRecording, onRecordingStart]);

  return (
    <>
      <StyledRecorderContainer $hidden={hidden}>
        <Row justify='center' align='middle'>
          {processing ? (
            <Voamination size={50} />
          ) : (
            <StyledRecorderButton
              shape='circle'
              onClick={isRecording ? stopRecording : startRecording}
              disabled={processing}
              type='primary'
              danger={isRecording}
              $recording={isRecording}
            >
              <SvgIcon
                src={
                  isRecording
                    ? 'svg/voam-mic-isrecording.svg'
                    : 'svg/voam-mic.svg'
                }
                width={isRecording ? '60%' : '70%'}
                height={isRecording ? '60%' : '70%'}
              />
            </StyledRecorderButton>
          )}
        </Row>
        <Row justify='center' align='middle'>
          <Message busy={isRecording || processing}>
            {isRecording
              ? 'Listening...'
              : processing
              ? 'Processing...'
              : 'Tap to record'}
          </Message>
        </Row>
      </StyledRecorderContainer>
      <div></div>
    </>
  );
}

import styled from 'styled-components';
import {Alert} from 'antd';

export const GuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;

  p {
    line-height: 1.5;
    font-size: 15px;
  }
  h4,
  h5,
  h6 {
    margin-top: 10px;
  }
  ul {
    margin-top: 0px;
  }
  li {
    font-size: 15px;
  }
  li::marker {
    line-height: 26px;
  }
  i {
    color: inherit;
  }
`;

export const StyledAlert = styled(Alert)`
  margin: 10px 0;
  borderradius: 12px;
  border: none;
  color: ${props => props.theme.token.colorInfo};
`;

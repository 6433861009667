import React from 'react';
import {H5, P2, P3} from '../../common/styles';
import FancyButton from '../../common/FancyButton';
import useChargebee from '../../hooks/use-chargebee';
import {StyledCard, StyledRow} from './styles';

const PaidFeatureCard = () => {
  const {contextHolder, checkoutUpgrade} = useChargebee();
  return (
    <StyledCard $notClickable>
      {contextHolder}
      <H5>This is a paid feature</H5>
      <P2>
        Upgrade to the Standard Plan to use our database features.
        <ul>
          <li>Fill databases using AI</li>
          <li>Unlimited voams</li>
          <li>Easily search and filter your databases</li>
          <li>And much more to come...</li>
        </ul>
      </P2>
      <StyledRow justify='end'>
        <FancyButton onClick={() => checkoutUpgrade()}>Upgrade now</FancyButton>
      </StyledRow>
      <P3>
        Or manage your subscription from <a href='/settings'>Settings</a>.
      </P3>
    </StyledCard>
  );
};

export default PaidFeatureCard;

import React from 'react';
import {SvgIconProps} from '../types';
import {StyledImg} from './styles';

export const SvgIcon = ({src, width, height}: SvgIconProps) => (
  <img src={`/img/${src}`} alt={src} width={width} height={height} />
);

export const LargeImage = ({src, width, height}: SvgIconProps) => (
  <StyledImg src={`/img/${src}`} alt={src} width={width} height={height} />
);

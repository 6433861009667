import API from './api';
import {AxiosError} from 'axios';
import {
  FormResponseSearchQuery,
  FormResponseWithPreview,
  ObjectSearchQuery,
} from '../types/search.types';
import {DataObject} from '../types/objects.types';

const searchFormResponses = async (
  searchQuery: FormResponseSearchQuery,
): Promise<{data: FormResponseWithPreview[]; error: string | null}> => {
  return await API.post(
    process.env.REACT_APP_SEARCH_ENDPOINT!,
    searchQuery,
  ).then(
    response => {
      if (response.status === 200) {
        return {
          data: response.data as FormResponseWithPreview[],
          error: null,
        };
      } else {
        console.log('expected 200 status code, got', response.status);
        return {
          data: response.data as FormResponseWithPreview[],
          error: response.statusText,
        };
      }
    },
    (error: AxiosError) => {
      return {
        data: [],
        error: error.message,
      };
    },
  );
};

const searchObjects = async (
  url: string,
  searchQuery: ObjectSearchQuery,
): Promise<DataObject[]> => {
  return await API.post(url, searchQuery).then(
    response => response.data as DataObject[],
  );
};

const SearchService = {
  searchFormResponses,
  searchObjects,
};

export default SearchService;

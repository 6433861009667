import React, {useEffect, useState} from 'react';
import {Select, UploadProps, UploadFile, Space, Descriptions} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import {RcFile} from 'antd/lib/upload';
import {TaskInput} from '../../types/runs.types';
import {P, P3, B} from '../../common/styles';
import {AudioUploadSpace} from './styles';

export interface UploadAudioProps {
  onChange?: (params: Record<string, string | File | undefined>) => void;
  inputs?: TaskInput[];
}

const UploadAudio = ({onChange, inputs}: UploadAudioProps) => {
  const [language, setLanguage] = useState<string | undefined>('none');
  const [file, setFile] = useState<UploadFile | undefined>(undefined);

  useEffect(() => {
    if (onChange) {
      onChange({
        language: language === 'none' ? undefined : language,
        file: file ? (file as RcFile) : undefined,
      });
    }
  }, [language, file]);

  const uploadProps: UploadProps = {
    onRemove: () => {
      setFile(undefined);
    },
    beforeUpload: (file: UploadFile) => {
      setFile(file);
      return false;
    },
    fileList: file ? [file] : [],
    accept: '.mp3, .mp4, .mpeg, .mpga, .m4a, .ogg, .wav, .webm, .flac',
  };

  const languages = [
    {value: 'none', label: <P3>Select language</P3>},
    {value: 'en', label: 'English'},
    {value: 'de', label: 'German'},
    {value: 'nl', label: 'Dutch'},
    {value: 'fr', label: 'French'},
    {value: 'es', label: 'Spanish'},
    {value: 'it', label: 'Italian'},
  ];

  return (
    <AudioUploadSpace direction='vertical' size={15}>
      <P>Upload an audio recording and select the spoken language.</P>
      {inputs ? (
        <Descriptions column={1} title={<B>Inputs</B>}>
          {inputs &&
            inputs
              .filter(
                input =>
                  input.name === 'language' || input.name === 'audio_file',
              )
              .map(input => {
                return (
                  <Descriptions.Item label={input.name} key={input.name}>
                    {input.value}
                  </Descriptions.Item>
                );
              })}
        </Descriptions>
      ) : (
        <>
          <Space size='large'>
            <P>Language:</P>
            <Select
              defaultValue='none'
              style={{width: 300}}
              onChange={setLanguage}
              options={languages}
            />
          </Space>
          <Dragger {...uploadProps} height={150}>
            <P>Click or drag file to this area to upload</P>
          </Dragger>
          <P3>
            Supported file types: .mp3, .mp4, .mpeg, .mpga, .m4a, .ogg, .wav,
            .webm, .flac
          </P3>
        </>
      )}
    </AudioUploadSpace>
  );
};

export default UploadAudio;

import React from 'react';
import {Checkbox, Form} from 'antd';
import {FormTitle, Instructions, ErrorMessage, ButtonRow} from '../styles';
import FancyButton from '../../../common/FancyButton';

interface AgreeToTermsProps {
  onBack: () => void;
  onCompletion: () => void;
}

const AgreeToTerms = ({onCompletion}: AgreeToTermsProps) => {
  const [checked, setChecked] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleConfirm = () => {
    if (checked) {
      onCompletion();
    } else {
      setErrorMessage(
        'You have to agree to the Terms and Conditions and Privacy Policy to use voam.',
      );
    }
  };

  return (
    <Form>
      <FormTitle>Before we continue</FormTitle>
      <Instructions>
        We care about making your experience with voam as valuable and safe as
        possible. We promise to never sell, share, or use your data for any
        other purpose than to help you automate your work. Read more in our{' '}
        <a href='https://voam.io/terms'>Terms and Conditions</a> and
        <a href='https://voam.io/privacy'> Privacy Policy</a>.
      </Instructions>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <Checkbox onChange={() => setChecked(!checked)}>
        I have read, understand, and agree to the Terms and Conditions and the
        Privacy Policy of voam.
      </Checkbox>
      <ButtonRow justify='end' align='middle'>
        <FancyButton onClick={handleConfirm}>Confirm</FancyButton>
      </ButtonRow>
    </Form>
  );
};

export default AgreeToTerms;

import React, {useEffect, useState} from 'react';
import {Spin, Alert} from 'antd';
import {FormResponse, Form} from '../../types/formless.types';
import FormlessService from '../../services/formless.service';
import FormlessView from '../FormlessView';
import FormResponseContentView from '../FormResponseContentView';
import FormResponseHeader from '../../common/FormHeader';
import useResponse from '../../data/use-response';
import useSubmit from '../../hooks/use-submit';
import ViewerContainer from '../../common/ViewerContainer';
import Recorder from '../Recorder';
import VoamHeaderToolBar from '../VoamHeaderToolBar';
import {getFormHeaders, getObjectTypeMap} from '../../data/utils';
import {Voam} from '../../types/voams.types';

export interface FormResponseViewerInterface {
  formResponse: FormResponse;
  form: Form;
  onDelete?: () => void;
  onChange?: (response: FormResponse) => void;
}

const FormlessResponseViewer = ({
  formResponse,
  form,
  onDelete,
  onChange,
}: FormResponseViewerInterface) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const {response, save, autoSave, isLoading, error, mutate} = useResponse(
    formResponse,
    onChange,
  );
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    if (response && hasChanges) {
      autoSave();
      setHasChanges(false);
    }
  }, [response, autoSave, hasChanges, isProcessing]);

  useEffect(() => {
    if (onChange && response) {
      onChange(response);
    }
  }, [response, onChange]);

  const handleAnswerChange = () => {
    setHasChanges(true);
  };

  const {
    submit,
    isSubmitting,
    errorMessage: submitErrorMessage,
  } = useSubmit({
    voam: response,
    save,
    setIsProcessing,
    mutate,
  });

  const processAudio = async (audio: File) => {
    setIsProcessing(true);
    const updatedResponse = await FormlessService.postAudio(
      formResponse,
      audio,
    );
    mutate(updatedResponse);
    setIsProcessing(false);
  };

  const [view, setView] = useState<string>(
    formResponse._id === 'tmp' ? 'edit' : 'text',
  );

  useEffect(() => {
    if (view === 'edit' && formResponse.locked) {
      setView('text');
    }
  }, [formResponse, view]);

  const onViewChange = (checked: boolean) => {
    if (checked && !formResponse.locked) {
      setView('edit');
    } else {
      setView('text');
    }
  };

  const formHeaderMap = getFormHeaders(form);
  const objectTypeMap = form ? getObjectTypeMap(form) : {};

  const handleSubmit = () => {
    submit();
    setView('text');
  };

  const handleChange = (voam: Voam) => {
    mutate(voam as FormResponse);
    onChange?.(voam as FormResponse);
  };

  return (
    <>
      <ViewerContainer>
        <VoamHeaderToolBar
          voam={response}
          isSubmitting={isSubmitting}
          isLoading={isLoading}
          isProcessing={isProcessing}
          isEditing={view === 'edit'}
          onViewChange={onViewChange}
          onDelete={onDelete}
          onSubmit={handleSubmit}
          onChange={handleChange}
        />
        {error ? <Alert message={error.message} type='error' closable /> : null}
        {submitErrorMessage && (
          <Alert message={submitErrorMessage} type='error' closable />
        )}
        <FormResponseHeader
          name={
            response?.name ? response.name : form ? form.name : 'Untitled voam'
          }
          hasMenu={true}
          editable={view === 'edit'}
          onChange={(name: string) => {
            response.name = name;
            handleAnswerChange();
          }}
        />
        {isLoading ? <Spin /> : null}
        {response !== undefined ? (
          view === 'text' ? (
            <FormResponseContentView
              response={response}
              headerMap={formHeaderMap}
              objectTypeMap={objectTypeMap}
            />
          ) : (
            <FormlessView
              formResponse={response!}
              form={form}
              onChange={handleAnswerChange}
            />
          )
        ) : null}
        <Recorder
          disabled={isLoading}
          processing={isProcessing}
          onAudioFile={processAudio}
          hidden={formResponse.locked}
        />
      </ViewerContainer>
    </>
  );
};

export default FormlessResponseViewer;

import styled from 'styled-components';
import {P, P3} from '../../common/styles';

export const AddFilterFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.token.colorFillAlter};
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 4px;
`;

export const Header = styled(P)`
  font-size: 0.9rem;
  margin: 0 0 4px 0;
  font-family: 'LibreFranklin Medium';
  font-weight: 500;
`;

export const SubHeader = styled(P3)`
  font-size: 0.8rem;
  margin: 4px 0 0 0;
`;

import React from 'react';
import {Button, Space} from 'antd';
import {B} from '../../../common/styles';
import {QuestionContainer, StyledAnswerRow} from '../styles';
import {QuestionViewInterface} from '../index';

const SelectMultipleQuestionView = ({
  question,
  questionResponse,
  onChange,
}: QuestionViewInterface) => {
  const answerArray = (questionResponse.answer as string[]) ?? [];
  const onClick = (value: string) => {
    if (answerArray.includes(value)) {
      questionResponse.answer = answerArray.filter(item => item !== value);
    } else {
      questionResponse.answer = [...answerArray, value];
    }
    onChange?.();
  };
  const clearAll = () => {
    questionResponse.answer = [];
    onChange?.();
  };

  return (
    <QuestionContainer>
      <B>{question.prompt}</B>
      <StyledAnswerRow>
        <Space wrap>
          {question.options?.map(option => (
            <Button
              key={option}
              onClick={() => onClick(option)}
              type={answerArray.includes(option) ? 'primary' : 'default'}
              shape='round'
            >
              {option}
            </Button>
          ))}
        </Space>
      </StyledAnswerRow>
      <StyledAnswerRow justify='start'>
        <Button
          onClick={() => clearAll()}
          type='text'
          disabled={answerArray.length === 0}
          danger
        >
          Clear all
        </Button>
      </StyledAnswerRow>
    </QuestionContainer>
  );
};

export default SelectMultipleQuestionView;

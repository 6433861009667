import React from 'react';
import SubmitStatusTag from '../../common/SubmitStatusTag';
import LastUpdatedTag from '../../common/LastUpdatedTag';
import LinkedTag from '../../common/LinkedTag';
import {FormResponse, Form} from '../../types/formless.types';
import {Space} from 'antd';
import {
  StyledCardTitle,
  StyledTextPreviewContainer,
  StyledCard,
  VoamTypeLabel,
} from './styles';

export interface FormResponseCardInterface {
  formResponse: FormResponse;
  form: Form;
  selected?: boolean;
}

const FormResponseCard = ({
  formResponse,
  form,
  selected,
}: FormResponseCardInterface) => {
  const hasLinks =
    formResponse &&
    formResponse.linkedObjects &&
    formResponse.linkedObjects.length > 0;
  return (
    <StyledCard selected={selected}>
      <VoamTypeLabel>{form ? form.name : 'Template'}</VoamTypeLabel>
      <StyledCardTitle>
        {formResponse && formResponse.name
          ? formResponse.name
          : form
          ? form.name
          : 'Untitled voam'}
      </StyledCardTitle>
      <Space size={[0, 3]} style={{padding: '0.1rem 0rem'}} wrap>
        {formResponse && formResponse.lastUpdated && (
          <LastUpdatedTag lastUpdated={formResponse.lastUpdated} />
        )}
        {hasLinks && <LinkedTag type={formResponse.linkedObjects[0].type} />}
        {formResponse && (
          <SubmitStatusTag isSubmitted={formResponse.submitted} />
        )}
      </Space>
      <StyledTextPreviewContainer>
        {formResponse &&
          formResponse.transcripts &&
          formResponse.transcripts.length > 0 &&
          formResponse.transcripts[0]}
      </StyledTextPreviewContainer>
    </StyledCard>
  );
};

export default FormResponseCard;

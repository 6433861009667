import React from 'react';
import {Switch} from 'antd';
import {ToggleContainer, Label} from './styles';

interface ToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  size?: 'small' | 'default';
}

const Toggle = ({checked, onChange, label, size}: ToggleProps) => {
  return (
    <ToggleContainer>
      {label && <Label>{label}</Label>}
      <Switch checked={checked} onChange={onChange} size={size || 'small'} />
    </ToggleContainer>
  );
};

export default Toggle;

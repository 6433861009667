import React, {useEffect, useState} from 'react';
import {QuestionFilter} from '../../types/search.types';
import {Question} from '../../types/formless.types';
import {
  QuestionText,
  OperatorText,
  ActiveFilterContainer,
  SelectWrapper,
} from './styles';
import {IonIcon} from '@ionic/react';
import {locationOutline} from 'ionicons/icons';
import {Button, InputNumber, Row, Space} from 'antd';
import ObjectSearchSelect from '../ObjectSearchSelect';

export interface DistanceFilterProps {
  question: Question;
  distanceFilters: QuestionFilter[];
  setDistanceFilters: (filters: QuestionFilter[]) => void;
}

const DistanceFilter = ({
  question,
  distanceFilters,
  setDistanceFilters,
}: DistanceFilterProps) => {
  const [reference, setReference] = useState<string | undefined>(undefined);
  const [distance, setDistance] = useState<number | null>(25);

  useEffect(() => {
    if (reference && distance) {
      const newFilter = {
        questionName: question.name,
        operator: 'distance-lte',
        value: distance,
        reference: reference,
      } as QuestionFilter;
      const newFilters = distanceFilters
        .filter(filter => filter.questionName !== question.name)
        .concat(newFilter);
      setDistanceFilters(newFilters);
    }
  }, [reference, distance]);

  const handleReset = () => {
    setReference(undefined);
    setDistance(25);
    const newFilters = distanceFilters.filter(
      filter => filter.questionName !== question.name,
    );
    setDistanceFilters(newFilters);
  };

  useEffect(() => {
    if (distanceFilters.length === 0) {
      setReference(undefined);
      setDistance(25);
    }
  }, [distanceFilters]);

  return (
    <ActiveFilterContainer>
      <Row justify='space-between' align='middle' wrap={false}>
        <Space>
          <IonIcon icon={locationOutline} />
          <QuestionText>{question.header ?? question.prompt}</QuestionText>
        </Space>
        <Button type='text' onClick={handleReset} disabled={!reference}>
          Reset
        </Button>
      </Row>
      <OperatorText>distance from</OperatorText>
      <SelectWrapper>
        <ObjectSearchSelect
          objectType={question.objectType as string}
          selectedId={reference ?? null}
          onChange={setReference}
        />
      </SelectWrapper>
      <OperatorText>is within</OperatorText>
      <Space>
        <InputNumber
          value={distance}
          onChange={setDistance}
          min={0}
          max={10000}
          step={10}
          style={{width: 110}}
          addonAfter='km'
        />
      </Space>
    </ActiveFilterContainer>
  );
};

export default DistanceFilter;

import styled from 'styled-components';
import {H3} from '../../common/styles';

export const TopicHeader = styled(H3)`
  font-size: 1.2rem;
  margin: 0;
`;

export const FormContentContainer = styled('div')`
  margin: 0 0 12px 0;
`;

export const TopicContainer = styled('div')`
  margin: 12px 0 24px;
`;

import React, {Suspense, useState, useEffect} from 'react';
import NavBar from '../components/NavBar';
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import {Styles} from '../styles/styles';
import useUser from '../data/use-user';
import {StyledLayout, StyledContent} from './styles';
import {Layout, theme} from 'antd';
import {ThemeProvider} from 'styled-components';

import Home from '../pages/Home';
import Create from '../pages/Create';
import Settings from '../pages/Settings';
import Login from '../pages/Login';
import Search from '../pages/Search';
import Signup from '../pages/Signup';
import ConnectToPage from '../pages/ConnectToPage';
import Admin from '../pages/Admin';

const Router = () => {
  const loggedOutRoutes = ['/login', '/signup', '/forgot-password'];
  const location = useLocation();
  const loggedOutRoute = loggedOutRoutes.some(item =>
    location.pathname.includes(item),
  );
  const hasSidebar = !loggedOutRoute;
  const {token} = theme.useToken();
  const [collapsed, setCollapsed] = useState(true);
  const {loggedOut} = useUser(loggedOutRoute);
  const navigate = useNavigate();

  useEffect(() => {
    if (location && loggedOut && !loggedOutRoute) {
      const next = location.pathname.startsWith('/connect-to-')
        ? '/signup'
        : '/login';
      navigate(next, {replace: true, state: {redirect: location.pathname}});
    }
  }, [location, loggedOut, loggedOutRoute]);

  return (
    <ThemeProvider theme={{token: token}}>
      <Suspense fallback={null}>
        <Styles />
        <Layout hasSider={hasSidebar}>
          <NavBar
            hidden={!hasSidebar}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <StyledLayout
            className='site-layout'
            sidebarwidth={hasSidebar ? (collapsed ? 80 : 200) : 0}
          >
            <StyledContent $sidebar={hasSidebar}>
              <Routes>
                <Route path='/signup' element={<Signup />} />
                <Route path='/login' element={<Login />} />
                {/* <Route path='/forgot-password' element={<Signup />} /> */}
                <Route path='/' element={<Home />} />
                <Route path='/home' element={<Home />} />
                <Route path='/voams' element={<Home />} />
                <Route path='/create' element={<Create />} />
                <Route path='/settings' element={<Settings />} />
                <Route path='/search' element={<Search />} />
                <Route path='/admin' element={<Admin />} />
                <Route
                  path='/connect-to-:integrationId'
                  element={<ConnectToPage />}
                />
              </Routes>
            </StyledContent>
          </StyledLayout>
        </Layout>
      </Suspense>
    </ThemeProvider>
  );
};

export default Router;

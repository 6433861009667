import styled from 'styled-components';
import {ElevatedCard} from '../../common/styles';
import {Row} from 'antd';

export const StyledCard = styled(ElevatedCard)`
  margin: 30px 0;
  max-width: 500px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const StyledRow = styled(Row)`
  margin: 20px 0;
`;

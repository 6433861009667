import {createGlobalStyle} from 'styled-components';

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "LibreFranklin Light";
        src: url("/fonts/LibreFranklin-Light.ttf") format("truetype");
        font-style: normal;
        font-weight: 300;
    }

    @font-face {
        font-family: "LibreFranklin Regular";
        src: url("/fonts/LibreFranklin-Regular.ttf") format("truetype");
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: "LibreFranklin Italic";
        src: url("/fonts/LibreFranklin-Italic.ttf") format("truetype");
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: "LibreFranklin Medium";
        src: url("/fonts/LibreFranklin-Medium.ttf") format("truetype");
        font-style: normal;
        font-weight: 500 !important;
    }

    @font-face {
        font-family: "LibreFranklin SemiBold";
        src: url("/fonts/LibreFranklin-SemiBold.ttf") format("truetype");
        font-style: normal;
        font-weight: 600;
    }

    @font-face {
        font-family: "LibreFranklin Bold";
        src: url("/fonts/LibreFranklin-Bold.ttf") format("truetype");
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: "LibreFranklin ExtraBold";
        src: url("/fonts/LibreFranklin-ExtraBold.ttf") format("truetype");
        font-style: normal;
        font-weight: 800;
    }

    @font-face {
        font-family: "LibreFranklin Black";
        src: url("/fonts/LibreFranklin-Black.ttf") format("truetype");
        font-style: normal;
        font-weight: 900;
    }

    body,
    html,
    a {
        font-family: 'LibreFranklin Regular', sans-serif;
        font-weight: 400;
        overscroll-behavior: none;
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    button {
        font-family: 'LibreFranklin Medium', sans-serif;
        font-weight: 500;
    }

    input:not(.ant-select-selection-search-input .create-password-input),
    textarea {
        padding: 0.4rem 1rem;
    }

    .create-password-input {
        border-radius: 25px;
        input {
            background: #141123 !important;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #141123;
        line-height: 1.18;
    }
    
    h1 {
        font-family: 'LibreFranklin Black', serif;
        font-size: 56px;
        font-weight: 900;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    h2 {
        font-size: 48px;
        margin-bottom: 0.5rem;
        font-family: 'LibreFranklin ExtraBold', serif;
        font-weight: 800;

        @media only screen and (max-width: 890px) {
            font-size: 40px;
        }

        @media only screen and (max-width: 414px) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 40px;
        margin-bottom: 0.5rem;
        font-family: 'LibreFranklin ExtraBold', serif;
        font-weight: 800;

        @media only screen and (max-width: 890px) {
            font-size: 32px;
        }

        @media only screen and (max-width: 414px) {
            font-size: 24px;
        }
    }

    h4 {
        font-size: 32px;
        margin-bottom: 0.5rem;
        font-family: 'LibreFranklin ExtraBold', serif;
        font-weight: 800;

        @media only screen and (max-width: 890px) {
            font-size: 24px;
        }

        @media only screen and (max-width: 414px) {
            font-size: 20px;
        }
    }

    h5 {
        font-size: 24px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-family: 'LibreFranklin Bold', serif;
        font-weight: 700;

        @media only screen and (max-width: 890px) {
            font-size: 20px;
        }
        
        @media only screen and (max-width: 414px) {
            font-size: 18px;
        }
    }

    h6 {
        font-size: 20px;
        margin-bottom: 0.5rem;
        font-family: 'LibreFranklin SemiBold', serif;
        font-weight: 600;

        @media only screen and (max-width: 890px) {
            font-size: 18px;
        }

        @media only screen and (max-width: 414px) {
            font-size: 16px;
        }
    }

    p {
        color: #141123;
        font-size: 1rem;        
        line-height: 1.2;
        margin: 0;
        font-weight: 400;
    }

    b {
        font-family: 'LibreFranklin SemiBold', sans-serif;
        font-weight: 600;
        color: #141123;
        font-size: 1rem;        
        line-height: 1.2;
    }

    i {
        font-family: 'LibreFranklin Italic', sans-serif;
        font-weight: 400;
        color: #141123;
        font-size: 1rem;
        line-height: 1.41;
    }

    li::marker {
        font-size: 28px;
    }

    ul {
        list-style-image: url("img/svg/bullet.svg");
        list-style-position: outside;
        ::marker {
            font-weight: 600;
            font-size: 40px;
        }
    }

    a {
        text-decoration: none;
        outline: none;
        color: #09C8A9;

        :hover {
            color: #09C8A9;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-alert-success {
        background-color: #09C8A91A;
        border: none;
        color: #09C8A9;
    }

    .ant-alert-error {
        background-color: #E07A5F1A;
        border: none;
        color: #E07A5F;
    }

    img {
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .blockEditor {
        color: #141123;
        font-size: 1rem !important;
        p {
            all: unset !important;
            font-family: 'LibreFranklin Regular', sans-serif !important;
            font-size: 1rem !important;
            font-weight: normal !important;
        }
        h1 {
            font-family: 'LibreFranklin Black', serif;
            font-weight: 900 !important;
            font-size: 40px !important;
            margin: 10px 0 !important;
            margin-bottom: 0rem !important;
        }
        h2 {
            font-family: 'LibreFranklin ExtraBold', serif;
            font-weight: 800 !important;
            font-size: 21px !important;
            margin-bottom: 0rem !important;
        }
        h3 {
            font-family: 'LibreFranklin Bold' !important;
            font-weight: 700 !important;
            font-size: 16px !important;
            margin-bottom: 0rem !important;
        }
        b, strong {
            font-family: 'LibreFranklin SemiBold', sans-serif !important;
            font-weight: 600 !important;
        }
        [data-content-type="numberedListItem"],
        [data-content-type="bulletedListItem"],
        [data-content-type="paragraph"] {
            font-family: 'LibreFranklin Regular' !important;
            font-size: 1rem !important;
            font-weight: normal !important;
        }
    }

`;

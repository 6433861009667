import styled from 'styled-components';

export const SectionHeader = styled.h4`
  font-size: 0.8rem;
  font-family: 'LibreFranklin Medium';
  font-weight: 500;
  color: ${props => props.theme.token.colorTextTertiary};
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

import API from './api';
import {Form, FormResponse} from '../types/formless.types';
import {LinkedObject} from '../types/integrations.types';

async function getForms(url: string): Promise<Array<Form>> {
  return await API.get(url).then(res => res.data);
}

async function getForm(url: string): Promise<Form> {
  return await API.get(url).then(res => res.data);
}

async function getResponses(url: string): Promise<Array<FormResponse>> {
  return await API.get(url).then(res => res.data);
}

async function getResponse(url: string): Promise<FormResponse> {
  return await API.get(url).then(res => res.data);
}

async function putResponse(
  url: string,
  data: FormResponse,
): Promise<FormResponse> {
  return await API.put(url, data).then(res => res.data);
}

async function patchResponse(
  url: string,
  data: FormResponse,
): Promise<FormResponse> {
  const editData = {
    datetime: new Date(),
    topics: data.topics,
    name: data.name,
  };
  return await API.patch(url, editData).then(res => res.data);
}

async function createResponse(
  data: FormResponse,
): Promise<FormResponse | undefined> {
  try {
    return await API.post(process.env.REACT_APP_RESPONSES_ENDPOINT!, data).then(
      res => res.data,
    );
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function createResponseFromAudio(
  formId: string,
  file: File,
): Promise<FormResponse | undefined> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    return await API.post(
      process.env.REACT_APP_FORMS_ENDPOINT! + formId + '/responses/from-audio',
      formData,
    ).then(res => res.data);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function postResponseAudio(
  id: string,
  file: File,
): Promise<FormResponse | undefined> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    return await API.post(
      process.env.REACT_APP_RESPONSES_ENDPOINT! + id + '/audio',
      formData,
    ).then(res => res.data);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function postAudio(
  formResponse: FormResponse,
  file: File,
): Promise<FormResponse | undefined> {
  if (formResponse._id === 'tmp') {
    return await createResponseFromAudio(formResponse.formId, file);
  } else {
    return await postResponseAudio(formResponse._id, file);
  }
}

async function deleteResponse(id: string): Promise<boolean> {
  try {
    await API.delete(process.env.REACT_APP_RESPONSES_ENDPOINT! + id);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function setLinkedObjects(
  id: string,
  linkedObjects: Array<LinkedObject>,
): Promise<FormResponse | undefined> {
  try {
    return await API.put(
      process.env.REACT_APP_RESPONSES_ENDPOINT! + id + '/links',
      linkedObjects,
    ).then(res => res.data);
  } catch (error) {
    return undefined;
  }
}

async function removeLinkedObjects(
  id: string,
): Promise<FormResponse | undefined> {
  try {
    return await API.delete(
      process.env.REACT_APP_RESPONSES_ENDPOINT! + id + '/links',
    ).then(res => res.data);
  } catch (error) {
    return undefined;
  }
}

async function submit(id: string): Promise<[FormResponse?, string?]> {
  return await API.post(
    process.env.REACT_APP_RESPONSES_ENDPOINT! + id + '/submit',
    {},
  )
    .then(res => [res.data, undefined] as [FormResponse?, string?])
    .catch(err => {
      return [
        undefined,
        err.response.data.detail ?? 'Something went wrong.',
      ] as [FormResponse?, string?];
    });
}

const FormlessService = {
  getForms,
  getForm,
  getResponses,
  getResponse,
  putResponse,
  patchResponse,
  createResponse,
  postAudio,
  deleteResponse,
  setLinkedObjects,
  removeLinkedObjects,
  submit,
};

export default FormlessService;

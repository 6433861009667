import React from 'react';
import LastUpdatedTag from '../../common/LastUpdatedTag';
import StatusTag from '../../common/StatusTag';
import {StreamRun} from '../../types/runs.types';
import {Stream} from '../../types/streams.types';
import {Space} from 'antd';
import {StyledCardTitle, StyledCard, VoamTypeLabel} from './styles';

export interface StreamRunCardInterface {
  stream?: Stream;
  streamRun: StreamRun;
  selected?: boolean;
}

const StreamRunCard = ({
  stream,
  streamRun,
  selected,
}: StreamRunCardInterface) => {
  const title = stream && stream.name ? stream.name : 'Untitled';
  return (
    <StyledCard selected={selected}>
      <VoamTypeLabel>Stream</VoamTypeLabel>
      <StyledCardTitle>{title}</StyledCardTitle>
      <Space size={[0, 4]} style={{padding: '0.2rem 0rem'}} wrap>
        {streamRun && streamRun.startDatetime && (
          <LastUpdatedTag lastUpdated={streamRun.startDatetime} />
        )}
        <StatusTag status={streamRun && streamRun.status} />
      </Space>
    </StyledCard>
  );
};

export default StreamRunCard;

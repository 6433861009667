import {LinkedObject} from './integrations.types';

export enum QuestionType {
  OPEN = 'OPEN',
  BOOL = 'BOOL',
  NUMBER = 'NUMBER',
  INTEGER = 'INTEGER',
  SELECT_SINGLE = 'SELECT_SINGLE',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
  SCALE = 'SCALE',
  DATE = 'DATE',
  OBJECT = 'OBJECT',
}

export type Question = {
  prompt: string;
  name: string;
  header?: string;
  type: QuestionType;
  options?: Array<string>;
  allowedRange?: number[];
  objectType?: string;
};

export type FormTopic = {
  prompt: string;
  name: string;
  header?: string;
  questions: Array<Question>;
};

export enum ExporterType {
  TEXT = 'TEXT',
  HTML = 'HTML',
}

export type Exporter = {
  type: ExporterType;
  template: string;
};

export type Form = {
  _id: string;
  name: string;
  description: string;
  orgId: string;
  topics: Array<FormTopic>;
  customPrompt: string;
  exporters: Array<Exporter>;
};

export type Answer = string | number | boolean | Array<string> | Date | null;

export type QuestionResponse = {
  name: string;
  answer: Answer;
};

export type FormTopicResponse = {
  name: string;
  questions: Array<QuestionResponse>;
};

export type FormResponse = {
  _id: string;
  name?: string;
  created: Date;
  lastUpdated: Date;
  formId: string;
  userId: string;
  topics: Array<FormTopicResponse>;
  submitted: boolean;
  locked: boolean;
  transcripts: Array<string>;
  linkedObjects: LinkedObject[];
};

export type FormHeaderMap = {
  topics: {[name: string]: string};
  questions: {[name: string]: string};
};

import React, {useEffect, useState} from 'react';
import useForms from '../../data/use-forms';
import useVoams from '../../data/use-voams';
import {FormResponse} from '../../types/formless.types';
import {Voam} from '../../types/voams.types';
import {StreamRun} from '../../types/runs.types';
import {Note} from '../../types/notes.types';
import useOnboardingStep from '../../hooks/use-onboarding-steps';
import {Row, Col, Alert, FloatButton} from 'antd';
import {StyledSearch} from './styles';
import FormlessResponseViewer from '../../components/FormlessResponseViewer';
import StreamRunViewer from '../../components/StreamRunViewer';
import FormResponseCard from '../../components/FormResponseCard';
import NoteCard from '../../components/NoteCard';
import StreamRunCard from '../../components/StreamRunCard';
import FormlessService from '../../services/formless.service';
import NoteService from '../../services/note.service';
import {ScrollableCol, FixedListHeader, Divider, H5} from '../../common/styles';
import LoadingIndicator from '../../components/LoadingIndicator';
import NoteViewer from '../../components/NoteViewer';
import GettingStarted from '../../components/GettingStarted';
import {QuestionCircleOutlined} from '@ant-design/icons';
import WelcomeBack from '../../components/WelcomeBack';
import useStreams from '../../data/use-streams';
import {useLocation} from 'react-router-dom';

function getVoamType(selectedVoam: Voam | StreamRun | null) {
  if (selectedVoam) {
    if ('streamId' in selectedVoam) {
      return 'streamRun';
    } else if ('blocks' in selectedVoam) {
      return 'note';
    } else if ('formId' in selectedVoam) {
      return 'formResponse';
    } else {
      return null;
    }
  }
  return null;
}

type HomePageLocationState = {
  voamId?: string;
};

const Home = () => {
  const location = useLocation();
  const locState = location && (location.state as HomePageLocationState);
  const voamId = locState && locState.voamId;
  const {formsById} = useForms(false, false);
  const {streamsById} = useStreams();
  const {voams, mutate, error, isLoading} = useVoams();
  const [selectedVoam, setSelectedVoam] = useState<Voam | StreamRun | null>(
    voams
      ? voamId
        ? voams.find(voam => voam._id === voamId) ?? null
        : voams[0]
      : null,
  );

  useEffect(() => {
    if (voamId && selectedVoam?._id !== voamId) {
      const voam = voams?.find(voam => voam._id === voamId) || null;
      setSelectedVoam(voam);
      location.state = undefined;
    }
  }, [voamId]);

  const voamType = getVoamType(selectedVoam);
  const [searchQuery, setSearchQuery] = useState('');
  const {onboardingStep, onboardingFinished, setOnboardingStep} =
    useOnboardingStep({
      onFinish: () => {
        setSelectedVoam(voams ? voams[0] : null);
      },
    });

  const filteredVoams = voams
    ? voams.filter(voam => {
        return (
          // Any match in voam.
          JSON.stringify(voam).toLowerCase().includes(searchQuery.toLowerCase())
        );
      })
    : null;

  const handleDelete = async () => {
    if (selectedVoam) {
      let result: boolean;
      if (voamType === 'note') {
        result = await NoteService.deleteNote(selectedVoam._id);
      } else if (voamType === 'formResponse') {
        result = await FormlessService.deleteResponse(selectedVoam._id);
      } else {
        result = false;
      }
      if (result) {
        await mutate();
        setSelectedVoam(
          voams
            ? voams[0]._id === selectedVoam._id
              ? voams[1]
              : voams[0]
            : null,
        );
      } else {
        console.log('error deleting response');
      }
    }
  };

  return (
    <>
      <FloatButton
        icon={<QuestionCircleOutlined />}
        style={{top: 12}}
        tooltip={<div>Show getting started guide</div>}
        badge={
          selectedVoam ? {count: Math.max(7 - onboardingStep, 0)} : undefined
        }
        onClick={() => {
          if (onboardingFinished) {
            setOnboardingStep(7);
          }
          setSelectedVoam(null);
        }}
      />
      <Row justify='space-evenly' style={{height: '100%'}}>
        <Col xs={10} sm={8} md={6} lg={5} xl={4} style={{height: '100%'}}>
          <FixedListHeader>
            <H5>Your voams</H5>
            <StyledSearch
              placeholder='Search voams'
              onChange={e => setSearchQuery(e.target.value)}
            />
            <Divider />
          </FixedListHeader>
          <Row>
            {error && <Alert>An error has occurred.</Alert>}
            {isLoading && <LoadingIndicator />}
            <ScrollableCol height='calc(100vh - 150px)' width='100%'>
              {filteredVoams &&
                formsById &&
                filteredVoams.map((voam: Voam | StreamRun, index: number) => (
                  <div onClick={() => setSelectedVoam(voam)} key={voam._id}>
                    {getVoamType(voam) === 'note' ? (
                      <NoteCard
                        note={voam as Note}
                        key={voam._id}
                        selected={selectedVoam?._id === voam._id}
                      />
                    ) : getVoamType(voam) === 'formResponse' ? (
                      <FormResponseCard
                        formResponse={voam as FormResponse}
                        form={formsById.get((voam as FormResponse).formId)!}
                        key={voam._id}
                        selected={selectedVoam?._id === voam._id}
                      />
                    ) : getVoamType(voam) === 'streamRun' ? (
                      <StreamRunCard
                        stream={streamsById.get((voam as StreamRun).streamId)}
                        streamRun={voam as StreamRun}
                        key={voam._id}
                        selected={selectedVoam?._id === voam._id}
                      />
                    ) : null}
                    <Divider
                      hidden={
                        selectedVoam?._id === voam._id ||
                        filteredVoams?.at(index + 1)?._id === selectedVoam?._id
                      }
                    />
                  </div>
                ))}
            </ScrollableCol>
          </Row>
        </Col>
        <ScrollableCol
          xs={{span: 13}}
          sm={{span: 15}}
          md={{span: 17}}
          lg={{span: 18}}
          xl={{span: 19}}
        >
          {!selectedVoam ? (
            !onboardingFinished ? (
              <GettingStarted
                step={onboardingStep}
                setStep={setOnboardingStep}
              />
            ) : (
              <WelcomeBack />
            )
          ) : voamType === 'note' ? (
            <NoteViewer id={selectedVoam._id} onDelete={handleDelete} />
          ) : voamType === 'formResponse' ? (
            <FormlessResponseViewer
              formResponse={selectedVoam as FormResponse}
              form={formsById.get((selectedVoam as FormResponse).formId)!}
              onDelete={handleDelete}
              onChange={mutate}
            />
          ) : (
            <StreamRunViewer
              streamRunId={selectedVoam._id}
              streamId={(selectedVoam as StreamRun).streamId}
            />
          )}
        </ScrollableCol>
      </Row>
    </>
  );
};

export default Home;

import React from 'react';
import {theme} from 'antd';
import {link} from 'ionicons/icons';
import {CapsuleTag, TagIonIcon} from '../styles';

export interface LinkedTagProps {
  type: string;
}

const LinkedTag = ({type}: LinkedTagProps) => {
  const {token} = theme.useToken();
  return (
    <CapsuleTag
      bordered={false}
      color={token.colorInfo}
      icon={<TagIonIcon color='white' icon={link} />}
    >
      {type}
    </CapsuleTag>
  );
};

export default LinkedTag;

import React, {useState} from 'react';
import dayjs from 'dayjs';
import {DatePicker, Row} from 'antd';
import type {DatePickerProps} from 'antd';
import {
  P3,
  H6,
  PlainTextButton,
  RoundedButton,
  ErrorText,
} from '../../common/styles';
import {StyledInput, CreateActivityCard, StyledNumberInput} from './styles';
import LoadingIndicator from '../LoadingIndicator';
import IntegrationService from '../../services/integrations.service';
import {LinkedObject} from '../../types/integrations.types';
import ObjectSearchSelect from '../ObjectSearchSelect';

interface CreateCRMActivityFormProps {
  onCancel: () => void;
  onSuccess: (linkedObject: LinkedObject) => void;
}

const CreateCRMActivityForm = ({
  onCancel,
  onSuccess,
}: CreateCRMActivityFormProps) => {
  const activityTypes = ['Call', 'Meeting'];
  const [activityType, setActivityType] = useState(activityTypes[0]);
  const [title, setTitle] = useState(activityType);
  const [contact, setContact] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<Date>(
    new Date(Math.ceil(Date.now() / 900000) * 900000 - 900000),
  );
  const [duration, setDuration] = useState<number>(15);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleActivityTypeChange = (itemValue: string) => {
    setActivityType(itemValue);
    if (title === '' || activityTypes.includes(title)) {
      setTitle(itemValue);
    }
    if (itemValue === 'Meeting' && duration === 15) {
      setStartTime(new Date(Math.ceil(Date.now() / 900000) * 900000 - 1800000));
      setDuration(30);
    }
    if (itemValue === 'Call' && duration === 30) {
      setStartTime(new Date(Math.ceil(Date.now() / 900000) * 900000 - 900000));
      setDuration(15);
    }
  };

  const handleSubmit = () => {
    setIsProcessing(true);
    IntegrationService.createActivity({
      activityType,
      title,
      contactId: contact ? contact : undefined,
      startTime,
      endTime: new Date(startTime.getTime() + duration * 60000),
    })
      .then(([activity, message]) => {
        setIsProcessing(false);
        if (activity) {
          onSuccess({
            id: activity.id,
            integrationId: activity.integrationId,
            type: activity.activityType,
          } as LinkedObject);
          return;
        }
        if (message) {
          setError(message);
          setTimeout(() => {
            setError(null);
          }, 8000);
          return;
        }
      })
      .catch(() => {
        setIsProcessing(false);
        setError('Failed to create activity.');
      });
  };

  return (
    <CreateActivityCard>
      <H6 style={{marginTop: 0}}>Log a new activity</H6>
      <P3>Activity type</P3>
      <Row justify='space-evenly'>
        {activityTypes.map(option => (
          <PlainTextButton
            onClick={() => handleActivityTypeChange(option)}
            key={option}
          >
            {activityType === option ? option : <P3>{option}</P3>}
          </PlainTextButton>
        ))}
      </Row>
      <P3>Title</P3>
      <StyledInput
        placeholder='Activity title'
        value={title}
        onChange={e => setTitle(e.target.value)}
        autoComplete='off'
        autoFocus={true}
        height='30px'
      />
      <P3>Contact</P3>
      <ObjectSearchSelect
        objectType={'crm-contact'}
        selectedId={contact}
        onChange={setContact}
      />
      <Row justify='space-between' style={{marginTop: 12}}>
        <P3>Start time</P3>
        <P3>Duration</P3>
      </Row>
      <Row justify='space-between' style={{marginBottom: 12}}>
        <DatePicker
          format='YYYY-MM-DD HH:mm'
          value={dayjs(startTime)}
          size='small'
          style={{width: 'auto'}}
          showTime
          onChange={(value: DatePickerProps['value']) => {
            if (value) {
              setStartTime(value.toDate());
            }
          }}
        />
        <StyledNumberInput
          value={duration}
          step={5}
          max={1440}
          min={5}
          onChange={value => {
            if (value) {
              setDuration(Number(value));
            }
          }}
          size='large'
          addonAfter='min'
          style={{width: 'auto'}}
        />
      </Row>
      {isProcessing ? (
        <LoadingIndicator />
      ) : (
        <Row justify='space-between'>
          <PlainTextButton onClick={onCancel}>Cancel</PlainTextButton>
          <RoundedButton onClick={handleSubmit}>Create and link</RoundedButton>
        </Row>
      )}
      {error && <ErrorText style={{marginTop: 8}}>{error}</ErrorText>}
    </CreateActivityCard>
  );
};

export default CreateCRMActivityForm;

import React from 'react';
import {StyledButton} from './styles';

interface FancyButtonProps {
  color?: string;
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

const FancyButton = ({
  color,
  children,
  onClick,
  disabled,
}: FancyButtonProps) => (
  <StyledButton color={color} onClick={onClick} disabled={disabled}>
    {children}
  </StyledButton>
);

export default FancyButton;

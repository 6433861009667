import React, {useEffect, useState} from 'react';
import {Row, Col, Steps, Alert, Space, StepsProps} from 'antd';
import useStream from '../../data/use-stream';
import useStreamRun from '../../hooks/use-stream-run';
import TaskRunViewer from '../TaskRunViewer';
import {P, P3, H5, B, RoundedButton} from '../../common/styles';
import {TaskDescriptionPreview, StepName, StepLabel} from './styles';
import UploadAudio from '../UploadAudio';
import ViewerContainer from '../../common/ViewerContainer';
import LoadingIndicator from '../LoadingIndicator';
import Voamination from '../../common/Voamination';
import useVoams from '../../data/use-voams';

export interface StreamRunViewerInterface {
  streamId: string;
  streamRunId?: string;
  setStreamRunId?: (streamRunId: string) => void;
}

const runStatusMap = new Map([
  ['RUNNING', 'process'],
  ['SUCCESS', 'finish'],
  ['FAILED', 'error'],
  ['PENDING', 'wait'],
]);

const StreamRunViewer = ({
  streamId,
  streamRunId,
  setStreamRunId,
}: StreamRunViewerInterface) => {
  const {stream} = useStream(streamId);
  const [current, setCurrent] = useState(0);
  const [params, setParams] = useState<
    Record<string, string | File | undefined>
  >({});

  const {mutate: mutateVoams} = useVoams();
  const {streamRunRead, isLoading, start, retry, isProcessing, errorMessage} =
    useStreamRun({
      streamId,
      streamRunId,
      params,
    });
  const taskStatuses = streamRunRead?.taskRuns.map(taskRun => taskRun.status);

  useEffect(() => {
    if (streamRunRead && streamRunRead.streamRun && !streamRunId) {
      setStreamRunId && setStreamRunId(streamRunRead.streamRun._id);
    }
  }, [streamRunRead]);

  const inputStep = {
    title: current === 0 ? <B>Upload audio</B> : <P>Upload audio</P>,
    description: (
      <TaskDescriptionPreview>Upload your recording.</TaskDescriptionPreview>
    ),
    component: (
      <UploadAudio
        onChange={setParams}
        inputs={
          streamRunRead &&
          streamRunRead.taskRuns &&
          streamRunRead.taskRuns.length > 0
            ? streamRunRead?.taskRuns[0].inputs
            : undefined
        }
      />
    ),
    status: (streamRunId || streamRunRead
      ? 'finish'
      : 'process') as StepsProps['status'],
  };
  const steps =
    stream &&
    stream.tasks &&
    [inputStep].concat(
      stream.tasks.map((task, index: number) => {
        return {
          title:
            current === index + 1 ? <B>{task.name}</B> : <P>{task.name}</P>,
          description: (
            <TaskDescriptionPreview>{task.description}</TaskDescriptionPreview>
          ),
          component: <P>{task.description}</P>,
          status:
            taskStatuses && taskStatuses[index]
              ? (runStatusMap.get(taskStatuses[index]) as StepsProps['status'])
              : 'wait',
        };
      }),
    );

  const taskRun =
    streamRunRead &&
    streamRunRead.taskRuns &&
    streamRunRead.taskRuns[current - 1]
      ? streamRunRead.taskRuns[current - 1]
      : undefined;
  const task =
    stream && stream.tasks && stream.tasks[current - 1]
      ? stream.tasks[current - 1]
      : undefined;
  const currentTaskStatus =
    streamRunRead &&
    current > 0 &&
    streamRunRead.taskRuns &&
    streamRunRead.taskRuns[current - 1] &&
    streamRunRead.taskRuns[current - 1].status
      ? streamRunRead.taskRuns[current - 1].status
      : undefined;
  const isLastTask = steps && current === steps.length - 1;

  useEffect(() => {
    if (
      (currentTaskStatus === 'SUCCESS' || currentTaskStatus === 'SKIPPED') &&
      !isLastTask
    ) {
      setCurrent(current + 1);
    }
  }, [streamRunRead]);

  const onStart = () => {
    start().then(runId => {
      if (runId) {
        setStreamRunId && setStreamRunId(runId);
        setCurrent(1);
        mutateVoams();
      }
    });
  };

  useEffect(() => {
    const lastUnfinishedTaskIndex = streamRunRead?.taskRuns.findIndex(
      taskRun => taskRun.status !== 'SUCCESS' && taskRun.status !== 'SKIPPED',
    );
    if (lastUnfinishedTaskIndex !== undefined && lastUnfinishedTaskIndex > 0) {
      setCurrent(lastUnfinishedTaskIndex + 1);
    }
  }, [streamRunRead]);

  const failedTaskIsTranscription =
    streamRunRead &&
    streamRunRead.taskRuns &&
    streamRunRead.taskRuns.length > 0 &&
    streamRunRead.taskRuns[0].status === 'FAILED' &&
    streamRunRead.taskRuns[0].taskType === 'TRANSCRIPTION';

  return (
    <Row style={{paddingTop: '45px'}}>
      <Col
        xs={{span: 12}}
        sm={{span: 14}}
        md={{span: 16}}
        lg={{span: 17}}
        xl={{span: 18}}
      >
        <ViewerContainer>
          <Space size={15} direction='vertical'>
            <div style={{marginBottom: 20}}>
              <Space>
                {(streamRunRead?.streamRun.status === 'RUNNING' ||
                  isProcessing) && <Voamination size={30} />}
                <H5>{stream && stream.name}</H5>
              </Space>
              <P3>{stream && stream.description}</P3>
              <div style={{marginTop: 10}}>
                {isLoading && (
                  <LoadingIndicator message='Fetching run details' />
                )}
                {!isLoading &&
                  !isProcessing &&
                  streamRunRead &&
                  streamRunRead.streamRun &&
                  streamRunRead.streamRun.status === 'FAILED' && (
                    <Alert
                      message={
                        failedTaskIsTranscription
                          ? 'Transcription failed. Retry not available. Create a new Run to try again.'
                          : 'Stream Run failed. Try running it again.'
                      }
                      type='error'
                      action={
                        failedTaskIsTranscription ? undefined : (
                          <RoundedButton onClick={retry}>Retry</RoundedButton>
                        )
                      }
                    />
                  )}
              </div>
            </div>
            <Space>
              <StepLabel>Step {current + 1}</StepLabel>
              <StepName>
                {current === 0
                  ? 'Upload audio'
                  : task && task.name
                  ? task.name
                  : ''}
              </StepName>
            </Space>
            {stream &&
              stream.tasks &&
              steps &&
              steps[current] &&
              steps[current].component}
            {errorMessage && (
              <Alert message={errorMessage} type='error' closable />
            )}
            {current === 0 && !streamRunId && (
              <Row justify='end'>
                <RoundedButton onClick={onStart}>Run Stream</RoundedButton>
              </Row>
            )}
            {current > 0 && <TaskRunViewer taskRun={taskRun} task={task} />}
          </Space>
        </ViewerContainer>
      </Col>
      <Col
        xs={{span: 12}}
        sm={{span: 10}}
        md={{span: 8}}
        lg={{span: 7}}
        xl={{span: 6}}
      >
        <H5>Stream</H5>
        <Steps
          current={current}
          items={steps}
          direction='vertical'
          size='small'
          onChange={setCurrent}
        />
      </Col>
    </Row>
  );
};

export default StreamRunViewer;

import React, {useState} from 'react';
import {Meeting, LinkedObject, Activity} from '../../types/integrations.types';
import useUser from '../../data/use-user';
import useMeetings from '../../data/use-meetings';
import {SectionHeader} from './styles';
import LoadingIndicator from '../LoadingIndicator';
import ActivityItem from '../MeetingItem';
import {toRelativeDate} from '../../data/utils';
import {P3, ErrorText, PlainTextButton} from '../../common/styles';
import CreateCRMActivityForm from '../CreateCRMActivityForm';

interface MeetingListProps {
  onClick: (linkedObject: LinkedObject) => void;
}

const MeetingList = ({onClick}: MeetingListProps) => {
  const {meetings, isLoading, error} = useMeetings();
  const {user} = useUser();
  const dates = new Set(
    meetings?.map(meeting => meeting.startTime.toLocaleDateString()),
  );
  const [isCreating, setIsCreating] = useState(false);
  const meetingsData = Array.from(dates).map(date => {
    const meetingsForDate =
      meetings?.filter(
        meeting => (meeting.startTime as Date).toLocaleDateString() === date,
      ) ?? ([] as Meeting[]);
    return {
      sectionHeader: toRelativeDate(meetingsForDate![0].startTime),
      meetings: meetingsForDate,
    };
  });

  const handleCreatedActivity = (linkedObject: LinkedObject) => {
    setIsCreating(false);
    onClick(linkedObject);
  };

  return (
    <>
      {error && <ErrorText>Something went wrong. {error.message}</ErrorText>}
      {isCreating ? (
        <CreateCRMActivityForm
          onCancel={() => setIsCreating(false)}
          onSuccess={handleCreatedActivity}
        />
      ) : (
        <PlainTextButton
          onClick={() => setIsCreating(true)}
          style={{margin: 12}}
        >
          + Log a new activity
        </PlainTextButton>
      )}
      {meetingsData.map((data, index) => (
        <div key={index}>
          <SectionHeader>{data.sectionHeader}</SectionHeader>
          {data.meetings.map(meeting => (
            <ActivityItem
              key={meeting.id}
              activity={meeting as Activity}
              onClick={() =>
                onClick({
                  id: meeting.id,
                  integrationId: user?.integrations.crm,
                  type: 'meeting',
                } as LinkedObject)
              }
            />
          ))}
        </div>
      ))}
      {meetingsData.length === 0 && <EmptyList isLoading={isLoading} />}
    </>
  );
};

const EmptyList = ({isLoading}: {isLoading: boolean}) => {
  if (isLoading) {
    return <LoadingIndicator message='Fetching meetings...' />;
  } else {
    return (
      <>
        <SectionHeader>No meetings to document.</SectionHeader>
        <P3>
          We found no meetings in your CRM for today and the last few days. Note
          that your calendar must be synced with your CRM for meetings to show
          up here.
        </P3>
      </>
    );
  }
};

export default MeetingList;

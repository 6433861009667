import styled from 'styled-components';
import {P, B} from '../../../common/styles';

export const QuestionHeader = styled(B)`
  font-size: 1rem;
  line-height: 1.5;
`;

export const QuestionAnswer = styled(P)`
  font-size: 1rem;
  line-height: 1.5;
  white-space: pre-line;
  margin: 0;
`;

export const QuestionAnswerPreview = styled(QuestionAnswer)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const QuestionContainer = styled('div')`
  margin: 8px 0;
`;

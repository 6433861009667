import styled from 'styled-components';
import {Form} from 'antd';
import {H2, P, P3} from '../../common/styles';

export const StyledForm = styled(Form)`
  width: 100%;
  margin-top: 2rem;

  .ant-input {
    background: #f2f2f3;
    border: none;
    height: 40px;
    max-width: none;
    border-radius: 25px;
  }
  .ant-input-affix-wrapper {
    background: #f2f2f3;
    border: none;
    max-width: none;
    border-radius: 25px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  padding: 0.3rem 0;
`;

export const Span = styled('span')<{props: object}>`
  display: block;
  font-weight: 600;
  color: rgb(255, 130, 92);
  height: 0.775rem;
  padding: 0 0.675rem;
`;

export const ButtonContainer = styled('div')`
  text-align: end;
  position: relative;

  @media only screen and (max-width: 414px) {
    padding-top: 0.75rem;
  }
`;

export const StyledCardHeading = styled(H2)`
  font-size: 2rem;
  padding: 0;
  margin: 0;
`;

export const StyledMessage = styled(P3)`
  padding: 1rem 0;
  margin: 0;
`;

export const ErrorMessage = styled(P)`
  color: #e07a5f;
  font-size: 1rem;
  padding: 0;
  margin: 0;
`;

import styled from 'styled-components';
import {Layout} from 'antd';
const {Content} = Layout;

export const StyledContent = styled(Content)<{$sidebar: boolean}>`
  max-height: 100vh;
  padding: ${props => (props.$sidebar ? '5px 5px 0px 5px' : '0px')};
  overflow: hidden;
`;

export const StyledLayout = styled(Layout)<{sidebarwidth: number}>`
  min-height: 100vh;
  margin-left: ${p => p.sidebarwidth ?? 0}px;
  transition: margin-left 0.2s ease-in-out;
`;

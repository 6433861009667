import {useEffect, useState} from 'react';
import {KeyedMutator} from 'swr';
import {Voam} from '../types/voams.types';
import NoteService from '../services/note.service';
import FormlessService from '../services/formless.service';

interface UseSubmitProps {
  voam: Voam | undefined;
  save: () => Promise<void>;
  setIsProcessing: (isProcessing: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutate: KeyedMutator<any> | any;
}

const useSubmit = ({voam, save, setIsProcessing, mutate}: UseSubmitProps) => {
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const VoamService =
    voam && Object.hasOwnProperty.call(voam, 'formId')
      ? FormlessService
      : NoteService;

  const submit = () => {
    setIsProcessing(true);
    setIsSubmitting(true);
    save().then(() => {
      setShouldSubmit(true);
    });
  };

  useEffect(() => {
    if (voam && shouldSubmit) {
      setShouldSubmit(false);
      VoamService.submit(voam._id).then(result => {
        const [updatedVoam, errorMsg] = result;
        if (updatedVoam) {
          mutate(updatedVoam);
        }
        if (errorMsg) {
          setErrorMessage('Failed to submit. ' + errorMsg);
          setTimeout(() => {
            setErrorMessage(undefined);
          }, 15000);
        }
        setIsProcessing(false);
        setIsSubmitting(false);
      });
    }
  }, [shouldSubmit]);

  return {submit, isSubmitting, errorMessage};
};

export default useSubmit;

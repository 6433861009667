import useSWR from 'swr';
import {htmlToText} from 'html-to-text';
import IntegrationService from '../services/integrations.service';
import {Activity} from '../types/integrations.types';

interface UseActivityProps {
  activityId?: string | null;
  activityType?: string;
  integrationId?: string;
}

export default function useActivity({
  activityId,
  activityType,
  integrationId,
}: UseActivityProps) {
  const complete = activityId && activityType && integrationId;
  const {data, mutate, error} = useSWR<Activity | undefined>(
    complete
      ? process.env.REACT_APP_INTEGRATIONS_ENDPOINT! +
          integrationId +
          '/' +
          activityType +
          's/' +
          activityId
      : null,
    url => IntegrationService.getActivity(url),
  );
  const activityNotFound = error?.response?.status === 404;
  const isLoading = !data && !error;

  if (data) {
    data.title = data.title
      ? htmlToText(data.title)
      : `Untitled ${activityType}`;
    data.description = data.description
      ? htmlToText(data.description)
      : 'No data description';
    data.startTime = data.startTime ? new Date(data.startTime) : undefined;
    data.endTime = data.endTime ? new Date(data.endTime) : undefined;
  }

  return {
    isLoading,
    activity: activityNotFound ? undefined : data,
    mutate,
    error,
  };
}

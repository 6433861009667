import React from 'react';
import {timeOutline} from 'ionicons/icons';
import {theme} from 'antd';
import {CapsuleTag, TagIonIcon} from '../styles';
import {toRelativeDate} from '../../data/utils';

export interface LastUpdatedTagProps {
  lastUpdated: Date;
}

const LastUpdatedTag = ({lastUpdated}: LastUpdatedTagProps) => {
  const {token} = theme.useToken();
  return (
    <CapsuleTag
      bordered={false}
      icon={<TagIonIcon icon={timeOutline} />}
      color={token.colorFillAlter}
      style={{color: token.colorTextTertiary}}
    >
      {toRelativeDate(new Date(lastUpdated))}
    </CapsuleTag>
  );
};

export default LastUpdatedTag;

import React, {useEffect, useState} from 'react';
import {Row, Col, Alert, Select, Space} from 'antd';
import {Form} from '../../types/formless.types';
import {StyledSearch, SearchSectionHeader} from './styles';
import {PlainCard, H5} from '../../common/styles';
import FormResponseSearchResult from '../../components/FormResponseSearchResult';
import PaidFeatureCard from '../../components/PaidFeatureCard';
import {
  ScrollableCol,
  FixedListHeader,
  PlainTextButton,
  P3,
} from '../../common/styles';
import LoadingIndicator from '../../components/LoadingIndicator';
import EditableFilter from '../../components/EditableFilter';
import DistanceFilter from '../../components/DistanceFilter';
import DeletableTag from '../../components/DeletableTag';
import useFormResponseSearch from '../../data/use-form-response-search';
import useForms from '../../data/use-forms';
import useUser from '../../data/use-user';
import {
  getFormHeaders,
  getObjectTypeMap,
  getLocationQuestions,
} from '../../data/utils';
import {
  FilterOperator,
  FormResponseWithPreview,
  QuestionFilter,
} from '../../types/search.types';
import FilterForm from '../../components/FilterForm';

const Search = () => {
  const {org} = useUser();
  const {orgForms, defaultForms, formsById} = useForms(true, true);
  const [selectedFormId, setSelectedFormId] = useState<string | null>(
    orgForms && orgForms.length > 0 ? orgForms[0]._id : null,
  );
  const selectedForm = formsById.get(selectedFormId ?? '');
  const [keywords, setKeywords] = useState<string[]>([]);
  const [keywordField, setKeywordField] = useState<string>('');
  const [filters, setFilters] = useState<QuestionFilter[]>([]);
  const locationQuestions = selectedForm
    ? getLocationQuestions(selectedForm)
    : [];
  const [distanceFilters, setDistanceFilters] = useState<QuestionFilter[]>([]);
  const [showingFilterForm, setShowingFilterForm] = useState<boolean>(false);
  const searchQuery = {
    formId: selectedFormId ?? '',
    keywords: keywords,
    filters: filters.concat(distanceFilters),
  };
  const {responses, isLoading, search, next, error} = useFormResponseSearch({
    ...searchQuery,
    limit: 10,
  });

  const formSelectOptions = [
    {
      label: org?.name ?? 'Your organization',
      options:
        orgForms &&
        orgForms.map((form: Form) => ({
          value: form._id,
          label: form.name,
        })),
    },
    {
      label: 'Standard templates',
      options:
        defaultForms &&
        defaultForms.map((form: Form) => ({
          value: form._id,
          label: form.name,
        })),
    },
  ];

  const formHeaderMap = getFormHeaders(selectedForm);
  const objectTypeMap = selectedForm ? getObjectTypeMap(selectedForm) : {};

  const removeKeyword = (keyword: string) => {
    setKeywords(keywords.filter((kw: string) => kw !== keyword));
  };

  const addFilter = (filter: QuestionFilter) => {
    setFilters(filters.concat(filter));
    setShowingFilterForm(false);
  };

  const removeFilter = (filter: QuestionFilter) => {
    setFilters(filters.filter((f: QuestionFilter) => f !== filter));
  };

  useEffect(() => {
    if (selectedFormId) {
      search();
    }
  }, [selectedFormId, keywords, filters, distanceFilters]);

  const resetAll = () => {
    setKeywords([]);
    setFilters([]);
    setDistanceFilters([]);
    setShowingFilterForm(false);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const bottomDistance =
      e.currentTarget.scrollHeight -
      Math.ceil(e.currentTarget.scrollTop) -
      e.currentTarget.clientHeight;
    if (bottomDistance <= 1 && !isLoading) {
      next();
    }
  };

  const shouldUpgrade = org && !org.entitlements?.databases;
  return (
    <>
      <Row justify='space-evenly' style={{height: '100%'}}>
        <Col xs={12} sm={10} md={8} lg={7} xl={6} style={{height: '100%'}}>
          <FixedListHeader>
            <H5>Search</H5>
            <PlainCard>
              <SearchSectionHeader>Search in</SearchSectionHeader>
              <Select
                bordered={false}
                defaultValue={
                  (formSelectOptions[0].options.length > 0 &&
                    formSelectOptions[0].options[0].value) ||
                  null
                }
                placeholder='Select a template'
                onChange={setSelectedFormId}
                options={formSelectOptions}
              />
              <SearchSectionHeader>Keywords</SearchSectionHeader>
              <StyledSearch
                margin='0 0 5px'
                placeholder='Add a keyword'
                onChange={e => setKeywordField(e.target.value)}
                value={keywordField}
                onPressEnter={() => {
                  setKeywords(keywords.concat(keywordField));
                  setKeywordField('');
                }}
              />
              {keywords.length > 0 && (
                <Space wrap size={3}>
                  {keywords.map((keyword: string) => (
                    <DeletableTag
                      key={keyword}
                      label={keyword}
                      onDelete={removeKeyword}
                    />
                  ))}
                </Space>
              )}
              <SearchSectionHeader>Filters</SearchSectionHeader>
              {locationQuestions &&
                locationQuestions.length > 0 &&
                locationQuestions.map(question => (
                  <DistanceFilter
                    key={question.name + '-location'}
                    question={question}
                    distanceFilters={distanceFilters}
                    setDistanceFilters={setDistanceFilters}
                  />
                ))}
              {selectedForm &&
                filters &&
                filters.length > 0 &&
                filters.map((filter: QuestionFilter, index: number) =>
                  filter.operator !== FilterOperator.DISTANCE_LTE ? (
                    <EditableFilter
                      key={filter.questionName + index}
                      filter={filter}
                      headerMap={formHeaderMap}
                      form={selectedForm}
                      onDelete={removeFilter}
                      onUpdate={search}
                    />
                  ) : null,
                )}
              {selectedForm && showingFilterForm && (
                <FilterForm
                  form={selectedForm}
                  onSubmit={addFilter}
                  onCancel={() => setShowingFilterForm(false)}
                />
              )}
              {selectedForm && !showingFilterForm && (
                <PlainTextButton onClick={() => setShowingFilterForm(true)}>
                  + Add filter
                </PlainTextButton>
              )}
              <Row justify='end' style={{marginTop: 25}}>
                <PlainTextButton
                  danger
                  onClick={resetAll}
                  disabled={
                    keywords.length +
                      filters.length +
                      distanceFilters.length ===
                    0
                  }
                >
                  Clear all
                </PlainTextButton>
              </Row>
            </PlainCard>
          </FixedListHeader>
        </Col>
        <ScrollableCol
          xs={{span: 10, offset: 1}}
          sm={{span: 12, offset: 1}}
          md={{span: 14, offset: 1}}
          lg={{span: 15, offset: 1}}
          xl={{span: 16, offset: 1}}
          onScroll={handleScroll}
        >
          <FixedListHeader>
            <H5>Results</H5>
          </FixedListHeader>
          {error && (
            <Alert
              type='error'
              message='Something went wrong, please try again.'
            />
          )}
          {shouldUpgrade ? (
            <PaidFeatureCard />
          ) : (
            responses &&
            responses.map((response: FormResponseWithPreview) => (
              <FormResponseSearchResult
                key={response.formResponse._id}
                formResponse={response.formResponse}
                preview={response.preview}
                headerMap={formHeaderMap}
                objectTypeMap={objectTypeMap}
              />
            ))
          )}
          {isLoading && <LoadingIndicator message='Searching...' />}
          {!selectedForm && !isLoading && !shouldUpgrade && (
            <P3>Select a template to start searching.</P3>
          )}
          {selectedForm &&
            responses &&
            responses.length === 0 &&
            !isLoading &&
            !shouldUpgrade && (
              <P3>No voams found. Try adjusting your search criteria.</P3>
            )}
        </ScrollableCol>
      </Row>
    </>
  );
};

export default Search;

import React from 'react';
import {theme} from 'antd';
import {
  ColoredCardTitle,
  ColoredCardPreviewContainer,
  ElevatedCard,
} from '../../common/styles';
import {ChevronForward} from 'react-ionicons';

export interface FormCardInterface {
  name: string;
  description: string;
  selected?: boolean;
  onClick?: () => void;
}

const FormCard = ({
  name,
  description,
  selected,
  onClick,
}: FormCardInterface) => {
  const {token} = theme.useToken();
  return (
    <div onClick={onClick}>
      <ElevatedCard $selected={selected}>
        <ColoredCardTitle color={token.colorText}>{name}</ColoredCardTitle>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ColoredCardPreviewContainer>
            {description}
          </ColoredCardPreviewContainer>
          <ChevronForward
            color={token.colorTextSecondary}
            height='20px'
            width='20px'
          />
        </div>
      </ElevatedCard>
    </div>
  );
};

export default FormCard;

import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {LoadingWrapper, LoadingMessage} from './styles';
import {theme} from 'antd';

const LoadingIndicator = ({message}: {message?: string}) => {
  const {token} = theme.useToken();
  return (
    <LoadingWrapper>
      <LoadingOutlined style={{fontSize: 24, color: token.colorPrimary}} />
      {message && <LoadingMessage>{message}</LoadingMessage>}
    </LoadingWrapper>
  );
};

export default LoadingIndicator;

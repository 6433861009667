import {StreamRun} from '../types/runs.types';
import {Voam} from '../types/voams.types';
import useNotes from './use-notes';
import useResponses from './use-responses';
import useStreamRuns from './use-stream-runs';

export default function useVoams() {
  const {
    notes,
    mutate: mutateNotes,
    error: errorNotes,
    isLoading: isLoadingNotes,
  } = useNotes();
  const {
    responses,
    mutate: mutateResponses,
    error: errorResponses,
    isLoading: isLoadingResponses,
  } = useResponses();
  const {
    streamRuns,
    mutate: mutateStreamRuns,
    error: errorStreamRuns,
    isLoading: isLoadingStreamRuns,
  } = useStreamRuns({});

  const isLoading = isLoadingNotes || isLoadingResponses || isLoadingStreamRuns;
  const voams =
    notes && responses && streamRuns
      ? [...notes, ...responses, ...streamRuns]
      : null;
  const error = errorNotes || errorResponses || errorStreamRuns;
  if (voams) {
    voams.sort((a: Voam | StreamRun, b: Voam | StreamRun) => {
      const aDate =
        'lastUpdated' in a ? a.lastUpdated : a.startDatetime ?? new Date();
      const bDate =
        'lastUpdated' in b ? b.lastUpdated : b.startDatetime ?? new Date();
      return aDate.valueOf() > bDate.valueOf() ? -1 : 1;
    });
  }

  const mutateAll = async () => {
    mutateNotes();
    mutateResponses();
    mutateStreamRuns();
  };

  return {
    isLoading,
    voams,
    mutate: mutateAll,
    error,
  };
}

import styled from 'styled-components';
import {Input} from 'antd';

export const StyledSearch = styled(Input)`
  margin-bottom: 15px;
  background-color: ${props => props.theme.token.colorFillAlter};
  border-radius: 25px;
  border: none;
  color: ${props => props.theme.token.colorText};
`;

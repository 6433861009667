import {FormResponse, Answer} from './formless.types';

export enum FilterOperator {
  EQUALS = 'equals',
  NOT_EQUALS = 'not-equals',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'not-contains',
  CONTAINS_ANY = 'contains-any',
  CONTAINS_ALL = 'contains-all',
  ONE_OF = 'one-of',
  TEXT_CONTAINS = 'text-contains',
  TEXT_NOT_CONTAINS = 'text-not-contains',
  GREATER_THAN = 'greater-than',
  LESS_THAN = 'less-than',
  GREATER_THAN_OR_EQUAL_TO = 'greater-than-or-equal-to',
  LESS_THAN_OR_EQUAL_TO = 'less-than-or-equal-to',
  EMPTY = 'empty',
  NOT_EMPTY = 'not-empty',
  DISTANCE_LTE = 'distance-lte',
}

export type QuestionFilter = {
  questionName: string;
  operator: FilterOperator;
  value?: Answer;
  reference?: string;
};

export type FormResponseSearchQuery = {
  formId: string;
  keywords: string[];
  filters: QuestionFilter[];
  skip: number;
  limit: number;
};

export type KeyItem = {
  icon: string;
  text: string;
};

export type SearchPreview = {
  title: string;
  keyItems: KeyItem[];
  description?: string;
};

export type FormResponseWithPreview = {
  formResponse: FormResponse;
  preview: SearchPreview | null;
};

export type ObjectSearchQuery = {
  keyword: string;
  skip: number;
  limit: number;
};

import styled from 'styled-components';
import {IonIcon} from '@ionic/react';
import {Space} from 'antd';
import {P, H4} from '../../common/styles';

export const ResponseTitle = styled(H4)`
  margin: 0 0 4px 0;
  font-size: 28px;
`;

export const ResponseTitle2 = styled.h4`
  margin: 0 0 4px 0;
  color: ${props => props.theme.token.colorTextTertiary};
  font-size: 28px;
`;

export const KeyItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  height: 22px;
  line-height: 22px;
  white-space: nowrap;
`;

export const Description = styled(P)`
  font-size: 1rem;
  margin: 10px 0 0 0;
`;

export const KeyItemIcon = styled(IonIcon)`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  color: ${props => props.theme.token.colorText};
`;

export const StyledChevron = styled(IonIcon)`
  margin-right: 8px;
  width: 30px;
  height: 30px;
  color: ${props => props.theme.token.colorText};
`;

export const StyledSpace = styled(Space)`
  margin: 5px 0;
`;

import React from 'react';
import {
  FormTopicResponse,
  FormTopic,
  QuestionResponse,
} from '../../types/formless.types';
import QuestionView from '../QuestionView';

export interface FormlessTopicViewInterface {
  topic: FormTopic;
  topicResponse: FormTopicResponse;
  onChange?: () => void;
}

const FormlessTopicView = ({
  topic,
  topicResponse,
  onChange,
}: FormlessTopicViewInterface) => {
  const questionResponseMap = new Map<string, QuestionResponse>();
  topicResponse.questions.forEach(question => {
    questionResponseMap.set(question.name, question);
  });
  const questions = topic.questions.map(question => {
    return (
      <QuestionView
        key={question.name}
        question={question}
        questionResponse={questionResponseMap.get(question.name)!}
        onChange={onChange}
      />
    );
  });
  return <>{questions}</>;
};

export default FormlessTopicView;

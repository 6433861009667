import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {
  LogoContainer,
  StyledMenu,
  LogoutButton,
  MenuContainer,
  StyledLogoutLabel,
  ButtonWrapper,
} from './styles';
import {Tooltip, Layout, MenuProps, theme} from 'antd';
import {
  HomeOutline,
  MicOutline,
  SettingsOutline,
  LogOutOutline,
  SearchOutline,
  BarChartOutline,
} from 'react-ionicons';
import {SvgIcon} from '../../common/Image';
import AuthService from '../../services/auth.service';
import useUser from '../../data/use-user';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

interface NavBarProps {
  hidden?: boolean;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
}

const NavBar = ({hidden, collapsed, setCollapsed}: NavBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedKey =
    location.pathname === '/' || location.pathname === '/home'
      ? '/voams'
      : location.pathname;
  const {org, user} = useUser(hidden);
  const {token} = theme.useToken();
  if (hidden === true) return null;

  const orgName = collapsed ? '' : org?.name ?? 'Your organization';
  const items = [
    getItem('Your voams', '/voams', <HomeOutline color='inherit' />),
    getItem('Create', '/create', <MicOutline color='inherit' />),
    getItem('Settings', '/settings', <SettingsOutline color='inherit' />),
    getItem(
      orgName,
      'g1',
      null,
      [getItem('Search', '/search', <SearchOutline color='inherit' />)],
      'group',
    ),
  ];
  if (user?.isSuperuser) {
    items.push(
      getItem(
        collapsed ? '' : 'Admin',
        'g2',
        null,
        [getItem('KPIs', '/admin', <BarChartOutline color='inherit' />)],
        'group',
      ),
    );
  }

  const onClick: MenuProps['onClick'] = e => {
    navigate(`${e.key}`);
  };

  const handleLogout = () => {
    AuthService.logOut().then(success => {
      if (success) {
        navigate('/login');
        window.location.reload();
      } else {
        console.log('Logout failed');
      }
    });
  };

  const logoutButton = (
    <LogoutButton danger block type='text' onClick={handleLogout}>
      <LogOutOutline
        color={token.colorError}
        style={{verticalAlign: 'middle'}}
      />
      {!collapsed && <StyledLogoutLabel>Log out</StyledLogoutLabel>}
    </LogoutButton>
  );
  return (
    <>
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MenuContainer>
          <div>
            <LogoContainer to='/'>
              <SvgIcon
                src={
                  collapsed
                    ? 'svg/mark_white_bg_rounded.svg'
                    : 'svg/logo_color_white.svg'
                }
                width='auto'
                height='32px'
              />
            </LogoContainer>
            <StyledMenu
              mode='inline'
              selectedKeys={[selectedKey]}
              items={items}
              onClick={onClick}
            />
          </div>
          <ButtonWrapper>
            {collapsed ? (
              <Tooltip placement='right' title='Log out'>
                {logoutButton}
              </Tooltip>
            ) : (
              logoutButton
            )}
          </ButtonWrapper>
        </MenuContainer>
      </Layout.Sider>
    </>
  );
};

export default withTranslation()(NavBar);

import BillingService from '../services/billing.service';
import useSWR from 'swr';

export default function useBillingData() {
  const {data, isLoading, error, mutate} = useSWR(
    process.env.REACT_APP_BILLING_ENDPOINT + 'data',
    BillingService.getBillingData,
  );
  const customer = data && data.customer ? data.customer : undefined;
  const subscription =
    data && data.subscription ? data.subscription : undefined;
  const plan =
    subscription && subscription.subscriptionItems
      ? subscription.subscriptionItems.find(item => item.itemType === 'plan')
      : undefined;
  const billingAddress =
    customer && customer.billingAddress ? customer.billingAddress : undefined;
  const paymentMethod =
    customer && customer.paymentMethod ? customer.paymentMethod : undefined;

  return {
    subscription,
    customer,
    plan,
    billingAddress,
    paymentMethod,
    isLoading,
    error,
    mutate,
  };
}

import styled from 'styled-components';
import {Col, Input, Button, Card, Tag, Typography} from 'antd';
import {IonIcon} from '@ionic/react';

export const ScrollableCol = styled(Col)<{height?: string; width?: string}>`
  height: ${props => props.height ?? '100%'};
  overflow-y: scroll;
  overscroll-behavior: none;
  width: ${props => props.width ?? 'auto'};
  padding-bottom: 200px;
  padding-right: 12px;
`;

export const FixedListHeader = styled('div')`
  position: sticky;
  padding-top: 45px;
  padding-right: 12px;
  top: 0;
  background-color: white;
  z-index: 1;
`;

export const Divider = styled('div')<{hidden?: boolean}>`
  display: block;
  height: 1px;
  background-color: ${props => (props.hidden ? 'transparent' : '#f2f2f3')};
  margin: 0 10px;
`;

export const H1 = styled.h1`
  color: ${props => props.theme.token.colorText};
`;

export const H2 = styled.h2`
  color: ${props => props.theme.token.colorText};
`;

export const H3 = styled.h3`
  color: ${props => props.theme.token.colorText};
`;

export const H4 = styled.h4`
  color: ${props => props.theme.token.colorText};
`;

export const H5 = styled.h5`
  color: ${props => props.theme.token.colorText};
`;

export const H6 = styled.h6`
  color: ${props => props.theme.token.colorText};
`;

export const P = styled.p`
  color: ${props => props.theme.token.colorText};
`;

export const P2 = styled.p`
  color: ${props => props.theme.token.colorTextSecondary};
`;

export const P3 = styled.p`
  color: ${props => props.theme.token.colorTextTertiary};
`;

export const B = styled.b`
  color: ${props => props.theme.token.colorText};
`;

export const SecondaryAccentText = styled.p`
  color: ${props => props.theme.token.colorInfo};
`;

export const ErrorText = styled.p`
  color: ${props => props.theme.token.colorError};
`;

export const TextInput = styled(Input)`
  border-radius: 25px;
  border: none;
  background-color: ${props => props.theme.token.colorFillAlter};
  color: ${props => props.theme.token.colorTextPrimary};
  padding: 0 15px;
  height: 30px;
  max-width: 600px;
`;

export const RoundedButton = styled(Button)<{
  color?: string;
  textcolor?: string;
  margin?: string;
  height?: string;
}>`
  margin: ${props => props.margin || '0'};
  border-radius: 8px;
  border: ${props =>
    props.textcolor !== 'white' ? '1px solid ' + props.textcolor : 'none'};
  background-color: ${props => props.color || props.theme.token.colorText};
  color: ${props => props.textcolor || 'white'};
  font-family: 'LibreFranklin Medium';
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  height: ${props => props.height || '40px'};

  &:hover {
    background-color: ${props => props.color || '#141123'};
    color: ${props => props.textcolor || 'white'};
    transform: scale(1.02);
  }

  span {
    color: ${props => props.textcolor || 'white'};
  }
`;

export const PlainTextButton = styled(Button)`
  color: ${({theme}) => theme.token.colorPrimary};
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  box-shadow: none;
  &:hover {
    color: ${({theme}) => theme.token.colorPrimary};
    background-color: transparent;
    border: none;
  }
  &:disabled {
    color: ${({theme}) => theme.token.colorTextSecondary} !important;
    background-color: transparent !important;
    border: none !important;
  }
`;

export const PlainCard = styled(Card)`
  padding: 5px 0 10px;
  margin: 0 0 10px 0;
  border-radius: 8px;
  color: ${props => props.theme.token.colorText};
  .ant-card-body {
    padding: 10px;
  }
  overflow: hidden;
`;

export const ColoredCard = styled(Card)<{
  color: string;
  $selected?: boolean;
  $notClickable?: boolean;
}>`
  width: 100%;
  margin-bottom: 0.3rem;
  padding: 0;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  border: none;
  background-color: ${props => props.color + (props.$selected ? '33' : '1A')};

  &:hover {
    background-color: ${props =>
      props.color + props.$notClickable ? '1A' : '33'};
    cursor: ${props => (props.$notClickable ? 'default' : 'pointer')};
  }

  .ant-card-body {
    padding: 0.5rem 0.8rem;
  }
`;

export const ElevatedCard = styled(Card)<{
  $selected?: boolean;
  $notClickable?: boolean;
}>`
  width: 100%;
  margin-bottom: 0.3rem;
  padding: 0;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  border: none;
  background-color: ${props => (props.$selected ? '#F2F2F3' : '#FAFAF9')};

  &:hover {
    background-color: ${props => (props.$notClickable ? '#FAFAF9' : '#F2F2F3')};
    cursor: ${props => (props.$notClickable ? 'default' : 'pointer')};
  }

  .ant-card-body {
    padding: 0.5rem 0.8rem;
  }
`;

export const ColoredCardTitle = styled(H6)<{color: string}>`
  font-family: 'LibreFranklin Medium', sans-serif;
  font-weight: 500;
  color: ${props => props.color || props.theme.token.colorText};
  font-size: 0.9rem;
  margin: 0;
`;

export const ColoredCardPreviewContainer = styled('div')<{numLines?: number}>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.numLines ?? 2};
  line-height: 1rem;
  max-height: ${props => props.numLines ?? 2}rem;
  color: ${props => props.theme.token.colorTextSecondary};
  font-size: 0.85rem;
  font-family: 'LibreFranklin Light', sans-serif;
  font-weight: 300;

  p {
    margin-bottom: 0;
  }
`;

export const TagIonIcon = styled(IonIcon)`
  vertical-align: middle;
  margin-right: 5px;
  font-size: 11px;
`;

export const CapsuleTag = styled(Tag)`
  border-radius: 25px;
  line-height: 1;
  padding: 2px 6px;
  vertical-align: middle;
  font-size: 0.6rem;
  height: 17px;
  span {
    vertical-align: middle;
  }
`;

export const FormHeader = styled(Typography.Paragraph)`
  font-size: 32px;
  font-family: 'LibreFranklin ExtraBold', serif;
  font-weight: 800;
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
  :focus-within {
    border: none;
  }
  textarea {
    font-size: 32px;
    font-family: 'LibreFranklin ExtraBold', serif;
    font-weight: 800;
    color: ${props => props.theme.token.colorTextSecondary};
    background: transparent;
    height: auto !important;
    border: none;
    padding: 0 12px;
  }
  .ant-typography-edit {
    color: ${props => props.theme.token.colorFillAlter};
  }
`;

import useSWR, {KeyedMutator} from 'swr';
import RunsService from '../services/runs.service';
import {TaskRun, StreamRun} from '../types/runs.types';

interface useStreamRunsReturns {
  isLoading: boolean;
  streamRuns?: StreamRun[];
  mutate: KeyedMutator<(StreamRun | TaskRun)[]>;
  error?: Error;
}

interface useStreamRunsProps {
  limit?: number;
  skip?: number;
}

export default function useStreamRuns({
  limit,
  skip,
}: useStreamRunsProps): useStreamRunsReturns {
  const limitParam = limit ? `&limit=${limit}` : '';
  const skipParam = skip ? `&skip=${skip}` : '';
  const {
    data: streamRuns,
    mutate,
    error,
  } = useSWR(
    process.env.REACT_APP_RUNS_ENDPOINT! +
      '?run_type=stream' +
      limitParam +
      skipParam,
    RunsService.getRuns,
  );

  const isLoading = !streamRuns && !error;

  return {
    streamRuns: streamRuns ? (streamRuns as StreamRun[]) : ([] as StreamRun[]),
    isLoading,
    mutate,
    error,
  };
}

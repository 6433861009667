import styled from 'styled-components';
import {Card, Row, Select} from 'antd';
import {TextInput, P, P3, H4} from '../../common/styles';

export const StyledSignupCard = styled(Card)`
  padding: 1rem 1rem;
  max-width: 600px;
  border-radius: 16px;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: ${props => props.theme.token.colorBgContainer};
  border: none;

  @media only screen and (max-width: 714px) {
    margin: 2rem 0.5rem;
  }
  span.anticon.anticon-eye-invisible.ant-input-password-icon {
    color: white;
  }
`;

export const FormTitle = styled(H4)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Instructions = styled(P3)`
  line-height: 1.3;
  margin-bottom: 1rem;
`;

export const StyledTextInput = styled(TextInput)`
  height: 40px;
  max-width: none;
  background-color: ${props => props.theme.token.colorFillAlter};
  border: none;
  color: ${props => props.theme.token.colorText};
`;

export const ErrorMessage = styled.p`
  color: ${props => props.theme.token.colorError};
  margin-bottom: 1rem;
`;

export const OrgCard = styled(Card)`
  margin-bottom: 1rem;
  background: #1d1a2a;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  .ant-card-body {
    padding: 1rem;
  }
`;

export const OrgName = styled.h6`
  color: white !important;
  margin-top: 0;
`;

export const OrgDescription = styled(P3)`
  line-height: 1.3;
`;

export const EmailDomainText = styled(P3)`
  line-height: 1.3;
`;

export const InputLabel = styled(P)`
  margin-bottom: 0.3rem;
  margin-top: 1rem;
`;

export const PYellow = styled.span`
  color: #f2b635;
`;

export const ButtonRow = styled(Row)`
  margin-top: 1rem;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

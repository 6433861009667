import React, {ChangeEvent, useState} from 'react';
import {Row, Button, Alert} from 'antd';
import {TextInput} from '../../common/styles';
import UserService from '../../services/users.service';

const ChangePasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);
  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) =>
    setConfirmPassword(e.target.value);

  const handleSubmit = () => {
    if (password === confirmPassword) {
      setError('');
      UserService.changePassword(password)
        .then(res => {
          setPassword('');
          setConfirmPassword('');
          if (res) {
            setSuccess('Password changed successfully.');
          } else {
            setError('Failed to change password.');
          }
        })
        .catch(err => console.log(err));
    } else {
      setError('Passwords do not match.');
    }
  };

  const disabled =
    password.length < 8 || confirmPassword.length < 8 || password.length > 64;
  return (
    <>
      {error && <Alert type='error' message={error} closable />}
      {success && <Alert type='success' message={success} closable />}
      <Row justify='end' align='middle' style={{height: 52}}>
        <TextInput
          type='password'
          placeholder='New password (8 - 64 characters)'
          value={password}
          onChange={handlePasswordChange}
        />
      </Row>
      <Row justify='end'>
        <TextInput
          type='password'
          placeholder='Confirm new password'
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
      </Row>
      <Row justify='end'>
        <Button
          type='primary'
          style={{marginTop: 10}}
          onClick={handleSubmit}
          disabled={disabled}
        >
          Save
        </Button>
      </Row>
    </>
  );
};

export default ChangePasswordForm;

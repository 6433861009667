import React, {useState, useRef} from 'react';
import useForms from '../../data/use-forms';
import useStreams from '../../data/use-streams';
import FormlessService from '../../services/formless.service';
import {Form, FormResponse} from '../../types/formless.types';
import {Stream} from '../../types/streams.types';
import {Row, Col} from 'antd';
import FormlessResponseViewer from '../../components/FormlessResponseViewer';
import {newFormResponse} from '../../data/utils';
import useUser from '../../data/use-user';
import FormatListSection from '../../components/FormatListSection';
import {ScrollableCol, FixedListHeader, P3, H5} from '../../common/styles';
import FormCard from '../../components/FormCard';
import NoteViewer from '../../components/NoteViewer';
import useResponses from '../../data/use-responses';
import StreamRunViewer from '../../components/StreamRunViewer';

const Create = () => {
  const {
    orgForms,
    defaultForms,
    isLoadingOrgForms,
    isLoadingDefaultForms,
    errorDefaultForms,
    errorOrgForms,
  } = useForms(true, true);
  const {
    streams,
    isLoading: isLoadingStreams,
    error: errorStreams,
  } = useStreams();
  const {user} = useUser();
  const [selectedForm, setSelectedForm] = useState<Form | null>(null);
  const [selectedStream, setSelectedStream] = useState<Stream | null>(null);
  const formResponseRef = useRef<FormResponse | null>(null);
  const [formResponseId, setFormResponseId] = useState<string>('tmp');
  const [streamRunId, setStreamRunId] = useState<string | null>(null);

  const handleFormClick = (form: Form) => {
    setSelectedForm(form);
    setSelectedStream(null);
    setFormResponseId('tmp');
    formResponseRef.current = newFormResponse(form, user!.id);
    setStreamRunId(null);
  };

  const handleStreamClick = (stream: Stream) => {
    setSelectedStream(stream);
    setSelectedForm(null);
    setStreamRunId(null);
  };

  const {mutate} = useResponses();

  const handleChange = (formResponse: FormResponse) => {
    formResponseRef.current = formResponse;
    if (formResponseId === 'tmp' && formResponse._id !== 'tmp') {
      setFormResponseId(formResponse._id);
    }
    mutate();
  };

  const handleResponseDelete = async () => {
    if (formResponseId !== 'tmp' && selectedForm) {
      const result = await FormlessService.deleteResponse(formResponseId);
      if (result) {
        await mutate();
        handleFormClick(selectedForm);
      }
    }
  };

  return (
    <>
      <Row justify='space-evenly' style={{height: '100%'}}>
        <Col xs={10} sm={8} md={6} lg={5} xl={4} style={{height: '100%'}}>
          <FixedListHeader>
            <H5>Create</H5>
            <P3 style={{marginBottom: 15}}>
              Use AI to create Notes, fill in Templates or run multi-step
              Streams.
            </P3>
          </FixedListHeader>
          <Row>
            <ScrollableCol height='calc(100vh - 160px)'>
              <FormCard
                name='Structured Note'
                description='Capture and structure your thoughts in a well-written note. Best for longer notes.'
                selected={selectedForm === null && selectedStream === null}
                onClick={() => {
                  setSelectedForm(null);
                  setSelectedStream(null);
                }}
                key={'Note'}
              />
              {streams && streams.length > 0 && (
                <FormatListSection<Stream>
                  title='Streams'
                  formats={streams}
                  isLoading={isLoadingStreams}
                  error={errorStreams}
                  onClick={handleStreamClick}
                  selectedId={selectedStream?._id}
                />
              )}
              <FormatListSection<Form>
                title={'Custom Templates'}
                formats={orgForms}
                isLoading={isLoadingOrgForms}
                error={errorOrgForms}
                onClick={handleFormClick}
                selectedId={selectedForm?._id}
              />
              <FormatListSection<Form>
                title='Standard Templates'
                formats={defaultForms}
                isLoading={isLoadingDefaultForms}
                error={errorDefaultForms}
                onClick={handleFormClick}
                selectedId={selectedForm?._id}
              />
            </ScrollableCol>
          </Row>
        </Col>
        <ScrollableCol
          xs={{span: 13}}
          sm={{span: 15}}
          md={{span: 17}}
          lg={{span: 18}}
          xl={{span: 19}}
        >
          {selectedStream ? (
            <StreamRunViewer
              streamId={selectedStream._id}
              streamRunId={streamRunId || undefined}
              setStreamRunId={(id: string) => {
                setStreamRunId(id);
              }}
            />
          ) : selectedForm && formResponseRef.current ? (
            <FormlessResponseViewer
              formResponse={formResponseRef.current}
              form={selectedForm}
              onChange={handleChange}
              onDelete={handleResponseDelete}
            />
          ) : (
            <NoteViewer id={null} />
          )}
        </ScrollableCol>
      </Row>
    </>
  );
};

export default Create;

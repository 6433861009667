import {useEffect, useState} from 'react';
import {Voam} from '../types/voams.types';
import {LinkedObject} from '../types/integrations.types';
import FormlessService from '../services/formless.service';
import NoteService from '../services/note.service';
import {getLinkedObject} from '../data/utils';

interface useLinkManagerProps {
  voam?: Voam;
  linkedObject?: LinkedObject | null;
  setLinkedObject: (linkedObject: LinkedObject | null) => void;
  onChange?: (voam: Voam) => void;
}

const useLinkManager = ({
  voam,
  linkedObject,
  setLinkedObject,
  onChange,
}: useLinkManagerProps) => {
  const onServer = voam && voam._id !== 'tmp';
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const VoamService =
    voam && Object.hasOwnProperty.call(voam, 'formId')
      ? FormlessService
      : NoteService;

  useEffect(() => {
    setLinkedObject(voam?.linkedObjects?.[0] || null);
  }, [voam?._id]);

  const setLink = (link: LinkedObject): void => {
    setLinkedObject(link);
    if (onServer) {
      VoamService.setLinkedObjects(voam._id, [link]).then(result => {
        if (result && onChange) {
          onChange(result);
        }
      });
    }
  };

  const removeLinks = () => {
    setIsRemoving(true);
    setLinkedObject(null);
    if (onServer) {
      VoamService.removeLinkedObjects(voam._id).then(result => {
        if (result && onChange) {
          setIsRemoving(false);
          onChange(result);
        }
      });
    } else {
      setIsRemoving(false);
    }
  };

  useEffect(() => {
    if (onServer) {
      const voamLink = getLinkedObject(voam);
      if (voamLink && !linkedObject && !isRemoving) {
        setLinkedObject(voamLink);
      }
    }
  }, [voam, linkedObject]);

  return {setLink, removeLinks};
};

export default useLinkManager;

import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import {I18nextProvider} from 'react-i18next';

import Router from './router';
import i18n from './translation';
import {ConfigProvider} from 'antd';

import {lightVoamTheme} from './styles/themes';

const App = () => (
  <ConfigProvider theme={lightVoamTheme}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  </ConfigProvider>
);

const root = createRoot(document.getElementById('root')!);
root.render(<App />);

import React from 'react';
import {Radio, Col, RadioChangeEvent, Button} from 'antd';
import {B} from '../../../common/styles';
import {CloseCircleFilled} from '@ant-design/icons';
import {QuestionContainer, StyledAnswerRow} from '../styles';
import {QuestionViewInterface} from '../index';

const SelectSingleQuestionView = ({
  question,
  questionResponse,
  onChange,
}: QuestionViewInterface) => {
  const onRadioChange = ({target: {value}}: RadioChangeEvent) => {
    updateValue(value);
  };

  const updateValue = (value: string | null) => {
    questionResponse.answer = value;
    onChange?.();
  };

  return (
    <QuestionContainer>
      <B>{question.prompt}</B>
      <StyledAnswerRow justify='start'>
        <Col span={20}>
          <Radio.Group
            onChange={onRadioChange}
            value={questionResponse.answer}
            optionType='button'
            buttonStyle='solid'
          >
            {question.options?.map(option => (
              <Radio.Button value={option} key={option}>
                {option}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Col>
        <Col span={2}>
          <Button
            icon={<CloseCircleFilled />}
            onClick={() => updateValue(null)}
            type='text'
            disabled={!questionResponse.answer}
          />
        </Col>
      </StyledAnswerRow>
    </QuestionContainer>
  );
};

export default SelectSingleQuestionView;

import React from 'react';
import {StyledVoaminationContainer, StyledPartialImg} from './styles';

interface VoaminationInterface {
  size?: number;
}

const Voamination = ({size}: VoaminationInterface) => {
  return (
    <StyledVoaminationContainer width={size} height={size}>
      <StyledPartialImg
        src='img/svg/voamination/mark_base_dark.svg'
        alt=''
        animate={false}
      />
      <StyledPartialImg
        src='img/svg/voamination/mark_key1_dark.svg'
        alt=''
        animate={true}
        delay={-0.4}
        upFirst={true}
      />
      <StyledPartialImg
        src='img/svg/voamination/mark_key2_dark.svg'
        alt=''
        animate={true}
        delay={-0.9}
        upFirst={false}
      />
      <StyledPartialImg
        src='img/svg/voamination/mark_key3_dark.svg'
        alt=''
        animate={true}
        delay={-1.1}
        upFirst={true}
      />
      <StyledPartialImg
        src='img/svg/voamination/mark_key4_dark.svg'
        alt=''
        animate={true}
        delay={-1.6}
        upFirst={false}
      />
      <StyledPartialImg
        src='img/svg/voamination/mark_key5_dark.svg'
        alt=''
        animate={true}
        delay={-0.2}
        upFirst={true}
      />
      <StyledPartialImg
        src='img/svg/voamination/mark_key6_dark.svg'
        alt=''
        animate={true}
        delay={-0.8}
        upFirst={false}
      />
      <StyledPartialImg
        src='img/svg/voamination/mark_key7_dark.svg'
        alt=''
        animate={true}
        delay={-1.3}
        upFirst={false}
      />
      <StyledPartialImg
        src='img/svg/voamination/mark_key8_dark.svg'
        alt=''
        animate={true}
        delay={-0.5}
        upFirst={true}
      />
    </StyledVoaminationContainer>
  );
};

export default Voamination;

import React from 'react';
import useUser from '../../data/use-user';
import {Alert, Popconfirm, theme} from 'antd';
import {P, B, H2} from '../../common/styles';
import {AlertOutline} from 'react-ionicons';
import {Container} from './styles';
import ConnectCRMForm from '../../components/ConnectCRMForm';
import SettingDisplay from '../../components/SettingDisplay';
import {useState} from 'react';
import IntegrationService from '../../services/integrations.service';
import {useParams} from 'react-router-dom';

const labelMap: {[key: string]: string} = {
  hubspot: 'HubSpot',
  salesforce: 'Salesforce',
  pipedrive: 'Pipedrive',
};

const ConnectToPage = () => {
  const {integrationId} = useParams();
  const integrationLabel = integrationId ? labelMap[integrationId] : 'CRM';
  const {user, error, mutate} = useUser();
  const [success, setSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const {token} = theme.useToken();

  const handleIntegrationSuccess = () => {
    setSuccess('Succesfully connected your CRM!');
    setTimeout(() => setSuccess(''), 5000);
  };

  const handleRemoveIntegration = () => {
    if (user && user.integrations.crm) {
      IntegrationService.removeIntegration(user?.integrations.crm).then(
        (success: boolean) => {
          if (success) {
            mutate({...user, integrations: {crm: undefined}});
            setSuccess('Successfully disconnected your CRM.');
            setTimeout(() => setSuccess(''), 5000);
          } else {
            setErrorMessage('Failed to disconnect your CRM.');
            setTimeout(() => setErrorMessage(''), 5000);
          }
        },
      );
    }
  };

  return (
    <Container>
      <div
        style={{
          maxWidth: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 100,
        }}
      >
        <H2>Connect to {integrationLabel}</H2>
        {user?.integrations.crm === integrationId ?? undefined ? (
          <P>
            You are currently connected to {integrationLabel}. You can
            disconnect here. Note that certain functionalities of voam will be
            unavailable without this integration. If you would like to connect
            to a different CRM, you must first disconnect from{' '}
            {integrationLabel}.
          </P>
        ) : (
          <P>
            Connect your {integrationLabel} account to voam to send your voams
            directly to your CRM and supercharge your CRM with an AI powered
            voice interface.
          </P>
        )}
        <div style={{padding: 30}}>
          {user &&
            (user.integrations.crm ? (
              <Popconfirm
                title={<B>Are you sure you want to disconnect your CRM?</B>}
                description='Certain functionalities of voam will be unavailable without this integration.'
                onConfirm={handleRemoveIntegration}
                okText='Remove'
                cancelText='Cancel'
                okButtonProps={{danger: true}}
                icon={<AlertOutline color={token.colorError} />}
              >
                <SettingDisplay
                  label='CRM'
                  value={user.integrations.crm}
                  buttonLabel='Remove'
                  danger
                />
              </Popconfirm>
            ) : null)}
          {user && !user.integrations.crm && (
            <ConnectCRMForm
              onCompletion={handleIntegrationSuccess}
              preselected={integrationId ?? undefined}
            />
          )}
        </div>
        {success && (
          <Alert
            type='success'
            message={success}
            closable
            style={{width: '100%'}}
          />
        )}
        {error && (
          <Alert
            type='error'
            message={errorMessage}
            closable
            style={{width: '100%'}}
          />
        )}
      </div>
    </Container>
  );
};

export default ConnectToPage;

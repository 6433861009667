import React from 'react';
import {Select, Space} from 'antd';
import {SelectProps} from 'antd/lib/select';
import useObjectSearch from '../../data/use-object-search';
import {P, P3} from '../../common/styles';

interface ObjectSearchSelectProps {
  objectType: string;
  selectedId: string | null;
  onChange: SelectProps<string>['onChange'];
}

const ObjectSearchSelect = ({
  objectType,
  selectedId,
  onChange,
}: ObjectSearchSelectProps) => {
  const {searchResults, setSearchTerm} = useObjectSearch({
    objectType: objectType as string,
    selectedObjectId: selectedId as string | null,
  });

  const options: SelectProps['options'] = searchResults?.map(option => ({
    label: (
      <Space>
        <P>{option.name}</P>
        <P3>{option.detail}</P3>
      </Space>
    ),
    value: option.id,
  }));

  return (
    <Select
      showSearch
      value={selectedId}
      style={{flex: 1, width: '100%'}}
      placeholder='Type 3 characters to search'
      defaultActiveFirstOption={true}
      onSearch={setSearchTerm}
      onChange={onChange}
      notFoundContent={null}
      filterOption={false}
      optionLabelProp='label'
      options={options}
    />
  );
};

export default ObjectSearchSelect;

import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Menu, Button} from 'antd';

export const LogoContainer = styled(Link)`
  display: flex;
  margin: 1.5rem;
  img {
    margin-right: auto;
    margin-left: 0;
  }
`;

export const StyledMenu = styled(Menu)`
  border-inline-end: none !important;
  background-color: transparent !important;
  .ant-menu-item {
    font-family: 'LibreFranklin Regular', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    padding: 0px 24px;
  }
  .ant-menu-item-group-title {
    font-family: 'LibreFranklin Regular', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    padding: 10px 24px 0;
    color: ${props => props.theme.token.colorTextTertiary};
    white-space: nowrap;
    text-transform: uppercase;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 4px;
`;

export const LogoutButton = styled(Button)`
  position: relative;
  bottom: 0;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 24px;
`;

export const StyledLogoutLabel = styled.span`
  font-size: 1rem;
  padding-left: 10px;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

import React, {useState} from 'react';
import {QuestionFilter} from '../../types/search.types';
import FilterForm, {operatorLabels} from '../FilterForm';
import {Form, FormHeaderMap} from '../../types/formless.types';
import {EllipsisVerticalOutline} from 'react-ionicons';
import {Row} from 'antd';
import {answerToString} from '../../data/utils';
import {
  ActiveFilterContainer,
  QuestionText,
  OperatorText,
  AnswerText,
} from './styles';

export interface EditableFilterProps {
  filter: QuestionFilter;
  form: Form;
  headerMap: FormHeaderMap;
  onDelete: (filter: QuestionFilter) => void;
  onUpdate: () => void;
}

const EditableFilter = ({
  filter,
  form,
  headerMap,
  onDelete,
  onUpdate,
}: EditableFilterProps) => {
  const [showingForm, setShowingForm] = useState<boolean>(false);

  const onSave = (updatedFilter: QuestionFilter) => {
    filter.questionName = updatedFilter.questionName;
    filter.operator = updatedFilter.operator;
    filter.value = updatedFilter.value;
    onUpdate();
    setShowingForm(false);
  };

  const onCancel = () => {
    setShowingForm(false);
  };

  const handleDelete = () => {
    onDelete(filter);
  };

  return (
    <>
      {showingForm ? (
        <FilterForm
          form={form}
          onSubmit={onSave}
          onCancel={onCancel}
          initialValues={filter}
          isDeletable={true}
          onDelete={handleDelete}
        />
      ) : (
        <ActiveFilterContainer>
          <Row justify='space-between' align='middle'>
            <QuestionText>
              {headerMap.questions[filter.questionName]}
            </QuestionText>
            <EllipsisVerticalOutline
              height='15px'
              width='15px'
              onClick={() => setShowingForm(true)}
            />
          </Row>
          <OperatorText>{operatorLabels[filter.operator]}</OperatorText>
          <AnswerText>{answerToString(filter.value ?? '')}</AnswerText>
        </ActiveFilterContainer>
      )}
    </>
  );
};

export default EditableFilter;

import React from 'react';
import {Form, Row, Col} from 'antd';
import {Organization, OrganizationCreate} from '../../../types/orgs.types';
import OrgService from '../../../services/orgs.service';
import {
  FormTitle,
  Instructions,
  ErrorMessage,
  InputLabel,
  StyledTextInput,
  ButtonRow,
  StyledSelect,
} from '../styles';
import FancyButton from '../../../common/FancyButton';
import {P3, PlainTextButton} from '../../../common/styles';
import useCountry from '../../../hooks/use-country';

interface CreateOrgProps {
  firstName: string;
  email: string;
  onBack: () => void;
  onCompletion: (org: Organization) => void;
}

const CreateOrg = ({
  firstName,
  email,
  onBack,
  onCompletion,
}: CreateOrgProps) => {
  const {code, countryList} = useCountry();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [orgName, setOrgName] = React.useState('');
  const [countryCode, setCountryCode] = React.useState<string | undefined>(
    code ? code : undefined,
  );
  const [description, setDescription] = React.useState('');

  const countries = countryList.getData().map(country => ({
    value: country.code,
    label: country.name,
  }));

  const handleSubmit = async () => {
    if (orgName.length === 0) {
      setErrorMessage('Please provide the name of your organization.');
      return;
    }
    if (!countryCode) {
      setErrorMessage('Please select the country you operate from.');
      return;
    }
    setErrorMessage('');
    setIsLoading(true);
    const org = await OrgService.createOrganization({
      name: orgName,
      description,
      emailDomain: email.split('@')[1],
      countryCode,
    } as OrganizationCreate);
    if (org) {
      onCompletion(org);
    } else {
      setErrorMessage('Something went wrong. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <Form>
      <FormTitle>Nice to meet you, {firstName}</FormTitle>
      <Instructions>
        Since you&#39;re the first person from your company to join voam,
        let&#39;s set up your company profile.
      </Instructions>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <InputLabel>Organization name</InputLabel>
      <StyledTextInput
        placeholder='e.g. Voam Technologies'
        value={orgName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setOrgName(e.target.value)
        }
      />
      <InputLabel>Tagline or description (optional)</InputLabel>
      <StyledTextInput
        placeholder='e.g. The Productive AI Platform'
        value={description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setDescription(e.target.value)
        }
      />
      <InputLabel>Country</InputLabel>
      <StyledSelect
        showSearch
        placeholder='Select country'
        options={countries}
        value={countryCode}
        onChange={(value: unknown) => setCountryCode(value as string)}
        optionFilterProp='label'
      />
      <InputLabel>Email domain</InputLabel>
      <P3>{email.split('@')[1]}</P3>
      <ButtonRow justify='space-between' align='middle' gutter={[12, 12]}>
        <Col xs={{span: 24, order: 2}} sm={{span: 11, order: 1}}>
          <Row justify='start'>
            <PlainTextButton danger onClick={onBack}>
              Change email address
            </PlainTextButton>
          </Row>
        </Col>
        <Col xs={{span: 24, order: 1}} sm={{span: 11, order: 2}}>
          <Row justify='end'>
            <FancyButton onClick={handleSubmit} disabled={isLoading}>
              Create organization
            </FancyButton>
          </Row>
        </Col>
      </ButtonRow>
    </Form>
  );
};

export default CreateOrg;

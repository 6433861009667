import useSWR from "swr";
import FormlessService from "../services/formless.service";
import { FormResponse } from "../types/formless.types";

export default function useResponses() {
    const { data, mutate, error } = useSWR(process.env.REACT_APP_RESPONSES_ENDPOINT!, FormlessService.getResponses);
    const isLoading = !data && !error;
    const loggedOut = error && error.message === "Request failed with status code 401";

    if (data) {
        data.sort((a: FormResponse, b: FormResponse) => {
            if (a.submitted && !b.submitted) {
                return 1;
            } else if (!a.submitted && b.submitted) {
                return -1;
            } else {
                return a.lastUpdated.valueOf() > b.lastUpdated.valueOf() ? -1 : 1;
            }
        });
    }

    return {
        isLoading,
        loggedOut,
        responses: data,
        mutate,
        error
    };
}

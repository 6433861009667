import React from 'react';
import {valueType} from 'antd/es/statistic/utils';
import {Col} from 'antd';
import {B} from '../../../common/styles';
import {QuestionType} from '../../../types/formless.types';
import {
  QuestionContainer,
  StyledAnswerRow,
  NumberAnswer,
  StyledRangeHint,
} from '../styles';
import {QuestionViewInterface} from '../index';

const NumberQuestionView = ({
  question,
  questionResponse,
  onChange,
}: QuestionViewInterface) => {
  const onFieldChange = (value: valueType | null) => {
    if (!value) {
      questionResponse.answer = null;
    } else if (typeof value === 'string') {
      questionResponse.answer = value;
    } else {
      questionResponse.answer = QuestionType.INTEGER
        ? Math.trunc(value)
        : value;
    }
    onChange?.();
  };

  const integerFormatter = (value: string | number | undefined) =>
    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const integerParser = (value: string | number | undefined) =>
    `${value}`.replace(/\$\s?|(,*)/g, '');

  return (
    <QuestionContainer>
      <B>{question.prompt}</B>
      <StyledAnswerRow justify='space-between' align='middle'>
        <Col span={12}>
          <NumberAnswer
            min={question.allowedRange ? question.allowedRange[0]! : 'none'}
            max={question.allowedRange ? question.allowedRange[1]! : 'none'}
            value={(questionResponse.answer as number) ?? undefined}
            onChange={onFieldChange}
            placeholder='Enter a number'
            formatter={
              question.type === QuestionType.INTEGER
                ? integerFormatter
                : undefined
            }
            parser={
              question.type === QuestionType.INTEGER ? integerParser : undefined
            }
          />
        </Col>
        <Col span={10}>
          {question.allowedRange ? (
            <StyledRangeHint>
              (min: {question.allowedRange[0]}, max: {question.allowedRange[1]})
            </StyledRangeHint>
          ) : (
            <></>
          )}
        </Col>
      </StyledAnswerRow>
    </QuestionContainer>
  );
};

export default NumberQuestionView;

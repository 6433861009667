import styled from 'styled-components';
import {Button} from 'antd';

export const StyledRecorderContainer = styled('div')<{$hidden?: boolean}>`
  display: ${props => (props.$hidden ? 'none' : 'block')};
  position: fixed;
  bottom: 0;
  right: 10px;
  width: 150px;
  padding: 10px 0;
  min-height: 50px;
  border-radius: 16px;
`;

export const StyledRecorderButton = styled(Button)<{$recording: boolean}>`
  height: 50px;
  width: 50px;
  border: none;
  -webkit-animation: ${props =>
    props.$recording ? 'breath 2s ease-in-out infinite' : 'none'};

  @-webkit-keyframes breath {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes breath {
    0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
`;

export const Message = styled('span')<{busy: boolean}>`
  font-size: 0.8rem;
  color: ${p => (p.busy ? 'gray' : '#09C8A9')};
  margin: 0.3rem;
`;
